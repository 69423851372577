import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { GatewaysActions } from '../actions';

export const featureKey = 'gatewayFeatureKey';

export type State = BaseState<Gateway, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Gateway> = createEntityAdapter<Gateway>({
  selectId: (entity: Gateway) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,

  on(
    GatewaysActions.setFilters,
    (state: State, { filters }): State => ({
      ...state,
      filters: [...filters]
    })
  ),
  on(
    GatewaysActions.loadGateways,
    (state: State, { request }): State => ({
      ...state,
      entity: null,
      filters: GetUtils.get(request, 'filters', [])
    })
  ),
  on(GatewaysActions.loadGatewaysSuccess, (state: State, { response }): State => adapter.setAll(response.data, { ...state, pagination: response.pagination })),

  on(GatewaysActions.loadGatewayById, (state: State, { id }): State => ({ ...state, selectedId: id, entity: null })),
  on(GatewaysActions.loadGatewayByIdSuccess, (state: State, { response }): State => ({ ...state, selectedId: response.id, entity: response })),
  on(GatewaysActions.loadGatewayByIdFailure, (state: State): State => ({ ...state, selectedId: null, entity: null })),

  on(
    GatewaysActions.loadGateways,
    GatewaysActions.loadGatewayById,
    (state: State): State => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),
  on(
    GatewaysActions.loadGatewaysSuccess,
    GatewaysActions.loadGatewayByIdSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    GatewaysActions.loadGatewaysFailure,
    GatewaysActions.loadGatewayByIdFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
