// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-variable-parameter {
  padding: 0 4px;
}

.asset-variable-parameter-placeholder {
  height: 40px;
  padding: 0 4px;
  background-color: #f8f8f8;
}

.first-empty-field {
  border: 2px dashed #4f79b7;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZhcmlhYmxlLXBhcmFtZXRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7QUFDRjs7QUFFQTtFQUNFLFlBQUE7RUFDQSxjQUFBO0VBQ0EseUJBQUE7QUFDRjs7QUFFQTtFQUNFLDBCQUFBO0FBQ0YiLCJmaWxlIjoidmFyaWFibGUtcGFyYW1ldGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFzc2V0LXZhcmlhYmxlLXBhcmFtZXRlciB7XHJcbiAgcGFkZGluZzogMCA0cHg7XHJcbn1cclxuXHJcbi5hc3NldC12YXJpYWJsZS1wYXJhbWV0ZXItcGxhY2Vob2xkZXIge1xyXG4gIGhlaWdodDogNDBweDtcclxuICBwYWRkaW5nOiAwIDRweDtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZjhmOGY4O1xyXG59XHJcblxyXG4uZmlyc3QtZW1wdHktZmllbGQge1xyXG4gIGJvcmRlcjogMnB4IGRhc2hlZCAjNGY3OWI3O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-asset/src/lib/formula-parameters-forms/variable-parameter/variable-parameter.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,YAAA;EACA,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,0BAAA;AACF;AACA,wrBAAwrB","sourcesContent":[".asset-variable-parameter {\r\n  padding: 0 4px;\r\n}\r\n\r\n.asset-variable-parameter-placeholder {\r\n  height: 40px;\r\n  padding: 0 4px;\r\n  background-color: #f8f8f8;\r\n}\r\n\r\n.first-empty-field {\r\n  border: 2px dashed #4f79b7;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
