import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProductsActions } from '../actions';

export const featureKey = 'productFeatureKey';

export type State = BaseState<Product, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: (entity: Product) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

const setFilters = (state: State, filters: Filter[]): State => ({
  ...state,
  filters: [...filters]
});

export const reducer = createReducer(
  initialState,

  on(ProductsActions.setFilters, (state: State, { filters }): State => setFilters(state, filters)),

  on(
    ProductsActions.loadProducts,
    (state: State, { request }): State => ({
      ...setFilters(state, GetUtils.get(request, 'filters', [])),
      entity: null
    })
  ),
  on(ProductsActions.loadProductsSuccess, (state: State, { response }): State => adapter.setAll(response.data, { ...state, pagination: response.pagination })),

  on(ProductsActions.loadProductById, (state: State, { id }): State => ({ ...state, selectedId: id, entity: null })),
  on(ProductsActions.loadProductByIdSuccess, (state: State, { response }): State => ({ ...state, selectedId: response.id, entity: response })),
  on(ProductsActions.loadProductByIdFailure, (state: State): State => ({ ...state, selectedId: null, entity: null })),

  on(
    ProductsActions.addProductSuccess,
    (state: State, { response }): State =>
      adapter.addOne(response, {
        ...state,
        entity: response
      })
  ),
  on(ProductsActions.updateProductSuccess, (state: State, { response }) =>
    adapter.updateOne({ id: response.id, changes: response }, { ...state, entity: response })
  ),

  on(ProductsActions.deleteProductSuccess, (state: State, { response }) => adapter.removeOne(response.id, state)),

  on(
    ProductsActions.loadProducts,
    ProductsActions.loadProductById,
    ProductsActions.addProduct,
    ProductsActions.updateProduct,
    ProductsActions.deleteProduct,
    (state: State): State => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(
    ProductsActions.loadProductsSuccess,
    ProductsActions.loadProductByIdSuccess,
    ProductsActions.addProductSuccess,
    ProductsActions.updateProductSuccess,
    ProductsActions.deleteProductSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    ProductsActions.loadProductsFailure,
    ProductsActions.loadProductByIdFailure,
    ProductsActions.addProductFailure,
    ProductsActions.updateProductFailure,
    ProductsActions.deleteProductFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
