// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-panel-info-header {
  padding: 10px;
  color: #fff;
  background-color: #4f78b7;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.map-panel-info-title {
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
}

.map-panel-info-content {
  padding: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hcC1wYW5lbC1pbmZvLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLFdBQUE7RUFDQSx5QkFBQTtFQUNBLDJDQUFBO0FBQ0Y7O0FBRUE7RUFDRSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSx5QkFBQTtBQUNGOztBQUVBO0VBQ0UsWUFBQTtBQUNGIiwiZmlsZSI6Im1hcC1wYW5lbC1pbmZvLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1hcC1wYW5lbC1pbmZvLWhlYWRlciB7XHJcbiAgcGFkZGluZzogMTBweDtcclxuICBjb2xvcjogI2ZmZjtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjNGY3OGI3O1xyXG4gIGJveC1zaGFkb3c6IDBweCA1cHggMTBweCByZ2JhKDAsIDAsIDAsIDAuMSk7XHJcbn1cclxuXHJcbi5tYXAtcGFuZWwtaW5mby10aXRsZSB7XHJcbiAgZm9udC1zaXplOiAxOHB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAxOHB4O1xyXG4gIHRleHQtdHJhbnNmb3JtOiB1cHBlcmNhc2U7XHJcbn1cclxuXHJcbi5tYXAtcGFuZWwtaW5mby1jb250ZW50IHtcclxuICBwYWRkaW5nOiA4cHg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-maps/src/lib/components/map-panel-info/map-panel-info.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,yBAAA;EACA,2CAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;AACF;AACA,4zBAA4zB","sourcesContent":[".map-panel-info-header {\r\n  padding: 10px;\r\n  color: #fff;\r\n  background-color: #4f78b7;\r\n  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.map-panel-info-title {\r\n  font-size: 18px;\r\n  line-height: 18px;\r\n  text-transform: uppercase;\r\n}\r\n\r\n.map-panel-info-content {\r\n  padding: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
