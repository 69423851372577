// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-category-editor {
  padding-bottom: 10px;
}

.tag-category-color-round {
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.h-70 {
  min-height: 70px;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  min-height: 20px;
  font-size: 12px;
  font-weight: normal;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRhZy1lZGl0b3IuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxvQkFBQTtBQUNGOztBQUVBO0VBQ0UsV0FBQTtFQUNBLFlBQUE7RUFDQSxrQkFBQTtBQUNGOztBQUVBO0VBQ0UsZ0JBQUE7QUFDRjs7QUFFQTtFQUNFLGdCQUFBO0VBQ0EsZUFBQTtFQUNBLG1CQUFBO0FBQ0YiLCJmaWxlIjoidGFnLWVkaXRvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi50YWctY2F0ZWdvcnktZWRpdG9yIHtcclxuICBwYWRkaW5nLWJvdHRvbTogMTBweDtcclxufVxyXG5cclxuLnRhZy1jYXRlZ29yeS1jb2xvci1yb3VuZCB7XHJcbiAgd2lkdGg6IDE2cHg7XHJcbiAgaGVpZ2h0OiAxNnB4O1xyXG4gIGJvcmRlci1yYWRpdXM6IDhweDtcclxufVxyXG5cclxuLmgtNzAge1xyXG4gIG1pbi1oZWlnaHQ6IDcwcHg7XHJcbn1cclxuXHJcbi5tYXQtbWRjLWNoaXAubWF0LW1kYy1zdGFuZGFyZC1jaGlwIHtcclxuICBtaW4taGVpZ2h0OiAyMHB4O1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBmb250LXdlaWdodDogbm9ybWFsO1xyXG59Il19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/tags/tag-editor/tag-editor.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AACF;AACA,4zBAA4zB","sourcesContent":[".tag-category-editor {\r\n  padding-bottom: 10px;\r\n}\r\n\r\n.tag-category-color-round {\r\n  width: 16px;\r\n  height: 16px;\r\n  border-radius: 8px;\r\n}\r\n\r\n.h-70 {\r\n  min-height: 70px;\r\n}\r\n\r\n.mat-mdc-chip.mat-mdc-standard-chip {\r\n  min-height: 20px;\r\n  font-size: 12px;\r\n  font-weight: normal;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
