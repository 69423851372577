import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailValidators, GetUtils } from '@iot-platform/iot-platform-utils';
import { UserAccount } from '@iot-platform/models/common';
import { User } from '@iot-platform/models/oyan';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { UsersService } from '../../services/users.service';

@Component({
    selector: 'oyan-ui-user-form-dialog',
    templateUrl: './user-form-dialog.component.html',
    styleUrls: ['./user-form-dialog.component.scss'],
    standalone: false
})
export class UserFormDialogComponent implements OnInit {
  usersForm: UntypedFormGroup;
  availableLanguages = this.translateService.langs;
  countries$: Observable<{ key: string; value: string }[]>;

  constructor(
    protected dialogRef: MatDialogRef<UserFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; externalEntityManager?: boolean },
    protected usersService: UsersService,
    private readonly translateService: TranslateService
  ) {
    this.countries$ = this.translateService.get('IOT_DICTIONARY.COUNTRIES').pipe(
      map((countries: { [key: string]: string }) =>
        Object.keys(countries)
          .map((key: string) => ({ key, value: countries[key] }))
          .sort(SortUtil.sortByProperty('value'))
      )
    );
  }

  get country(): AbstractControl {
    return this.usersForm.get('country');
  }

  get language(): AbstractControl {
    return this.usersForm.get('language');
  }

  get firstnameControl(): AbstractControl {
    return this.usersForm.get('firstname');
  }

  get lastnameControl(): AbstractControl {
    return this.usersForm.get('lastname');
  }

  get emailControl(): AbstractControl {
    return this.usersForm.get('email');
  }

  get isShared(): AbstractControl {
    return this.usersForm.get('isShared');
  }

  ngOnInit(): void {
    this.initForm();
    this.initUserCountry();
  }

  checkEmail(email: UntypedFormControl): Observable<{ used: boolean } | null | undefined> {
    if (this.data?.user && GetUtils.get(email, 'value', '').toLowerCase() === GetUtils.get(this.data, 'user.email', '').toLowerCase()) {
      return of(null);
    }

    if (!this.usersForm) {
      return of(null);
    }

    if (this.emailControl.value) {
      return this.usersService.getTotalUsersWithParams([{ key: 'exactEmail', value: email.value }]).pipe(
        map((usersWithThisEmail: number) => (usersWithThisEmail > 0 ? { used: true } : null)),
        take(1)
      );
    } else {
      return of(null);
    }
  }

  isUserFormDisabled(): boolean {
    if (this.isShared.value) {
      return !(this.lastnameControl.valid && this.emailControl.valid);
    } else {
      return !this.usersForm.valid;
    }
  }

  save(): void {
    const user: Partial<User> = {
      firstname: this.isShared.value ? '' : this.firstnameControl.value,
      lastname: this.lastnameControl.value,
      email: this.emailControl.value,
      isShared: this.isShared.value,
      preferences: {
        ...this.data?.user?.preferences,
        appLanguage: this.language.value
      },
      addressCountry: this.country.value
    };
    this.dialogRef.close(user);
  }

  close() {
    this.dialogRef.close();
  }

  /**
   TODO temporary workaround to init user country input.
   Should be removed once the users GET endpoint will be migrated to v2
   */
  private initUserCountry(): void {
    if (this.data?.user) {
      (async () => {
        const user: UserAccount = await this.usersService.getById(this.data.user.id).toPromise();
        this.country.setValue(GetUtils.get(user, 'addressCountry', ''));
      })();
    }
  }

  private initForm(): void {
    this.usersForm = new UntypedFormGroup({
      firstname: new UntypedFormControl(GetUtils.get(this.data, 'user.firstname', ''), [Validators.required, Validators.maxLength(50)]),
      lastname: new UntypedFormControl(GetUtils.get(this.data, 'user.lastname', ''), [Validators.required, Validators.maxLength(50)]),
      email: new UntypedFormControl(GetUtils.get(this.data, 'user.email', ''), [Validators.required, EmailValidators.isValid()], [this.checkEmail.bind(this)]),
      language: new UntypedFormControl(GetUtils.get(this.data, 'user.preferences.appLanguage', 'en'), []),
      country: new UntypedFormControl(GetUtils.get(this.data, 'user.addressCountry', ''), [Validators.required]),
      isShared: new UntypedFormControl(false, [])
    });
  }
}
