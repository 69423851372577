// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-list__item {
  min-height: 60px;
  padding-left: 5px;
  text-transform: capitalize;
}
.timeline-list__item .separator {
  min-height: 45px;
  margin: 0.5rem 0;
}
.timeline-list__item--bg-grey {
  background-color: rgba(211, 211, 211, 0.1450980392);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpbWVsaW5lLWxpc3QtaXRlbS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGdCQUFBO0VBQ0EsaUJBQUE7RUFDQSwwQkFBQTtBQUFKO0FBRUk7RUFDRSxnQkFBQTtFQUNBLGdCQUFBO0FBQU47QUFHSTtFQUNFLG1EQUFBO0FBRE4iLCJmaWxlIjoidGltZWxpbmUtbGlzdC1pdGVtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRpbWVsaW5lLWxpc3Qge1xyXG4gICZfX2l0ZW0ge1xyXG4gICAgbWluLWhlaWdodDogNjBweDtcclxuICAgIHBhZGRpbmctbGVmdDogNXB4O1xyXG4gICAgdGV4dC10cmFuc2Zvcm06IGNhcGl0YWxpemU7XHJcblxyXG4gICAgLnNlcGFyYXRvciB7XHJcbiAgICAgIG1pbi1oZWlnaHQ6IDQ1cHg7XHJcbiAgICAgIG1hcmdpbjogMC41cmVtIDA7XHJcbiAgICB9XHJcblxyXG4gICAgJi0tYmctZ3JleSB7XHJcbiAgICAgIGJhY2tncm91bmQtY29sb3I6ICNkM2QzZDMyNTtcclxuICAgIH1cclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/timeline-list/timeline-list-item/timeline-list-item.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,iBAAA;EACA,0BAAA;AAAJ;AAEI;EACE,gBAAA;EACA,gBAAA;AAAN;AAGI;EACE,mDAAA;AADN;AACA,gwBAAgwB","sourcesContent":[".timeline-list {\r\n  &__item {\r\n    min-height: 60px;\r\n    padding-left: 5px;\r\n    text-transform: capitalize;\r\n\r\n    .separator {\r\n      min-height: 45px;\r\n      margin: 0.5rem 0;\r\n    }\r\n\r\n    &--bg-grey {\r\n      background-color: #d3d3d325;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
