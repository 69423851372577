import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAuthModule } from '@iot-platform/auth';
import { ApiInterceptorModule, LocalStorageModule, provideDefaultProvidersToken } from '@iot-platform/core';
import { I18nModule } from '@iot-platform/i18n';
import { AppName } from '@iot-platform/models/common';
import { NotificationModule } from '@iot-platform/notification';
import { ConfigModule as OyanConfigModule } from '@iot-platform/oyan-ui';
import { PreferencesModule } from '@iot-platform/users';
import { EffectsModule } from '@ngrx/effects';
import { DEFAULT_ROUTER_FEATURENAME, FullRouterStateSerializer, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, provideStore, StoreModule } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app.router.module';

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    console.log('state', state);
    console.log('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<unknown>[] = [];

const storeSettings = {
  metaReducers,
  runtimeChecks: {
    strictStateImmutability: false,
    strictActionImmutability: false,
    strictStateSerializability: false,
    strictActionSerializability: false
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    LocalStorageModule.forRoot({ prefix: environment.storage.appPrefix }),
    AppRouterModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}, storeSettings),
    EffectsModule.forRoot([]),
    OyanConfigModule.forRoot({
      front: { version: '1.0.0' },
      back: { version: '1.0.0' }
    }),
    I18nModule.forRoot({ langs: ['fr', 'en', 'de', 'it'] }),
    NotificationModule,
    PreferencesModule,
    ApiInterceptorModule.forRoot({
      excludedApis: ['/admin-profiles', '/grids', '/favorite-views', '/directory/users', '/v2undefined', '/entities/undefined/admin-profiles']
    })
  ],
  providers: [
    provideDefaultProvidersToken({ environment }),
    provideStore(
      {
        [DEFAULT_ROUTER_FEATURENAME]: routerReducer
      },
      storeSettings
    ),
    provideStoreDevtools({ maxAge: 20, logOnly: environment.production }),
    provideRouterStore({ serializer: FullRouterStateSerializer }),
    provideAuthModule({
      appName: AppName.OYAN
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
