// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.variable-unit.warning ::ng-deep .mat-form-field-underline,
.variable-unit.warning ::ng-deep .mdc-line-ripple::before {
  background-color: #f58c2d;
}

.mat-mdc-option {
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZhcmlhYmxlLXVuaXRzLWF1dG9jb21wbGV0ZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHTTs7RUFFRSx5QkFBQTtBQUZSOztBQVFBO0VBQ0UsZUFBQTtBQUxGIiwiZmlsZSI6InZhcmlhYmxlLXVuaXRzLWF1dG9jb21wbGV0ZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi52YXJpYWJsZS11bml0IHtcclxuICAmLndhcm5pbmcge1xyXG4gICAgOjpuZy1kZWVwIHtcclxuICAgICAgLm1hdC1mb3JtLWZpZWxkLXVuZGVybGluZSxcclxuICAgICAgLm1kYy1saW5lLXJpcHBsZTo6YmVmb3JlIHtcclxuICAgICAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZjU4YzJkO1xyXG4gICAgICB9XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcblxyXG4ubWF0LW1kYy1vcHRpb24ge1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-asset/src/lib/variable-units-autocomplete/variable-units-autocomplete.component.scss"],"names":[],"mappings":"AAGM;;EAEE,yBAAA;AAFR;;AAQA;EACE,eAAA;AALF;AACA,4pBAA4pB","sourcesContent":[".variable-unit {\r\n  &.warning {\r\n    ::ng-deep {\r\n      .mat-form-field-underline,\r\n      .mdc-line-ripple::before {\r\n        background-color: #f58c2d;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.mat-mdc-option {\r\n  font-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
