import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule, inject, provideAppInitializer } from '@angular/core';
import { AppConfig } from './app.config';
import { ConfigService } from './config.service';

export const APP_CONFIG = new InjectionToken<unknown>('OYAN_APP_CONFIG');

@NgModule({ imports: [], providers: [
        ConfigService,
        provideAppInitializer(() => {
        const initializerFn = ((configService: ConfigService) => async () => await configService.init().toPromise())(inject(ConfigService));
        return initializerFn();
      }),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class ConfigModule {
  static forRoot(config: AppConfig): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        {
          provide: APP_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
