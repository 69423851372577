import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailValidators } from '@iot-platform/iot-platform-utils';
import { UserAccount } from '@iot-platform/models/common';

@Component({
    selector: 'oyan-ui-user-profile-form',
    templateUrl: './user-profile-form.component.html',
    standalone: false
})
export class UserProfileFormComponent implements OnInit {
  userProfileForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<UserProfileFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: UserAccount }
  ) {}

  get firstname(): AbstractControl {
    return this.userProfileForm.get('firstname');
  }

  get lastname(): AbstractControl {
    return this.userProfileForm.get('lastname');
  }

  get secondaryEmail(): AbstractControl {
    return this.userProfileForm.get('secondaryEmail');
  }

  get phoneNumber(): AbstractControl {
    return this.userProfileForm.get('phoneNumber');
  }

  ngOnInit() {
    this.userProfileForm = new UntypedFormGroup({
      firstname: new UntypedFormControl(this.data.user && this.data.user.firstname ? this.data.user.firstname.trim() : null, [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern('\\S.*')
      ]),
      lastname: new UntypedFormControl(this.data.user && this.data.user.lastname ? this.data.user.lastname.trim() : null, [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern('\\S.*')
      ]),
      email: new UntypedFormControl(this.data.user ? { value: this.data.user.email, disabled: true } : { value: null, disabled: true }, [
        EmailValidators.isValid(),
        Validators.maxLength(30)
      ]),
      secondaryEmail: new UntypedFormControl(this.data.user && this.data.user.secondaryEmail ? this.data.user.secondaryEmail : null, [
        EmailValidators.isValid(),
        Validators.maxLength(50)
      ]),
      phoneNumber: new UntypedFormControl(this.data.user && this.data.user.phoneNumber ? this.data.user.phoneNumber : null, [
        Validators.pattern(/^(\+|00)/),
        Validators.maxLength(20)
      ]),
      uuid: new UntypedFormControl(this.data.user ? { value: this.data.user.id, disabled: true } : { value: null, disabled: true })
    });
  }

  save(): void {
    const updated: UserAccount = {
      ...this.data.user,
      firstname: this.firstname.value,
      lastname: this.lastname.value,
      secondaryEmail: this.secondaryEmail.value && this.secondaryEmail.value?.length ? this.secondaryEmail.value : null,
      phoneNumber: this.phoneNumber.value ? this.phoneNumber.value : null
    };

    this.dialogRef.close(updated);
  }

  exit() {
    this.dialogRef.close();
  }
}
