// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-wrapper {
  flex: 1 1 auto;
  height: calc(100vh - 104px);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRyYW5zbGF0aW9uLXRhYmxlLXZpZXcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0VBQ0EsMkJBQUE7QUFDRiIsImZpbGUiOiJ0cmFuc2xhdGlvbi10YWJsZS12aWV3LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmdyaWQtd3JhcHBlciB7XHJcbiAgZmxleDogMSAxIGF1dG87XHJcbiAgaGVpZ2h0OiBjYWxjKDEwMHZoIC0gMTA0cHgpO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/i18n/src/lib/features/translation-management/components/translation-table-view/translation-table-view.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,2BAAA;AACF;AACA,4ZAA4Z","sourcesContent":[".grid-wrapper {\r\n  flex: 1 1 auto;\r\n  height: calc(100vh - 104px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
