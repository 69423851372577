import { Component, inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BaseCardComponent } from '@iot-platform/iot-platform-ui';
import { CardEventType } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import { ThresholdType } from '../../models/enums/threshold-type';
import { ThresholdCard } from '../../models/threshold-card.model';
import { Threshold } from '../../models/threshold.model';

/** *
 * Thresholds business rules :
 *
 *) check_in_threshold:

 Defines when the cylinder is considered assigned to a specific hospital.
 The threshold is set by the ALH administrator or installer during configuration for all the cylinders in the hospital.
 It must have a default value = 1h
 Min/Max values: 0h-3h

 *) check_out_threshold:

 Defines when the cylinder is considered not assigned to a specific hospital or filling center anymore.
 The threshold is set by the ALH administrator or installer during configuration for all the cylinders in the hospital.
 It must have a default value = 3h
 Min/Max values: 0h-12h

 *) time_before_not_detected:

 Time above which a cylinder is considered not detected but not yet lost.
 This data is set by the ALH administrator or installer during configuration for all the cylinders in the hospital.
 It must have a default value.
 "Ward = the ward is the area of the hospital where the cylinders are used or stored out of primary stock.
 The ward is associated to 1 or a set of receiver_ID and to a ward_name (name of the ward of the hospital).
 The association is done during the hospital infrastructure installation
 "
 Min/Max values : 5min-12h.
 - From 5min to 1h : step by 5min
 - From 1h to 12h : step by 15 min
 Base unit : Minutes

 *) threshold_full:

 Threshold defined by the users for the entire hospital during configuration.
 It is gas volume in Liters above which a cylinder is defined as full.
 It shall be possible for the user to modify this data through the “Configuration” menu.
 The default value and max value =  0.9 * cylinder_max_ pressure (AL parameter depending on Country) * cylinde_ size. "
 Min/Max values: 50% to 95%
 ***/
@Component({
    selector: 'oyan-ui-threshold-card',
    templateUrl: './threshold-card.component.html',
    styleUrls: ['./threshold-card.component.scss'],
    standalone: false
})
export class ThresholdCardComponent extends BaseCardComponent<ThresholdCard> implements OnInit {
  @Input() canUpdate: boolean;
  control: UntypedFormControl;
  thresholdDefinitions: { [key: string]: Threshold[] };
  private readonly translateService: TranslateService = inject(TranslateService);

  get minutesText(): string {
    return this.translateService.instant('SITES.CONFIGURATIONS.CARD.THRESHOLDS.UNITS.MINUTES');
  }

  get hoursText(): string {
    return this.translateService.instant('SITES.CONFIGURATIONS.CARD.THRESHOLDS.UNITS.HOURS');
  }

  get percentageText(): string {
    return this.translateService.instant('SITES.CONFIGURATIONS.CARD.THRESHOLDS.UNITS.PERCENTAGE');
  }

  ngOnInit(): void {
    this.thresholdDefinitions = {
      [ThresholdType.CHECK_IN_THRESHOLD]: this.getCheckInThresholds(),
      [ThresholdType.CHECK_OUT_THRESHOLD]: this.getCheckOutThresholds(),
      [ThresholdType.TIME_BEFORE_NOT_DETECTED]: this.getThresholdTimeBeforeNotDetectedValues(),
      [ThresholdType.THRESHOLD_FULL]: this.getFullThresholds()
    };
    const element = this.element();
    const initialValue = this.thresholdDefinitions[element.type].find((v) => v.value === element.defaultValue);
    this.control = new UntypedFormControl(
      {
        value: initialValue,
        disabled: !this.canUpdate
      },
      element.required ? [Validators.required] : []
    );
  }

  onSelectionChange({ value }): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.THRESHOLD_SELECTION_CHANGE,
      data: {
        thresholdType: this.element().type,
        value
      }
    });
  }

  private getCheckInThresholds(): Threshold[] {
    return this.generateValues(0, 3).map((value) => ({ value, displayValue: `${value} ${this.hoursText}` }));
  }

  /**
   * Add a new option called "Off" to the drop down list of checkout threshold.
   * When the user selects "Off", send a PUT request with the value 9999999.
   * Note: It's a large enough value to avoid checkout at any time.
   */
  private getCheckOutThresholds(): Threshold[] {
    const thresholds: Threshold[] = this.generateValues(0, 12).map((value) => ({
      value,
      displayValue: `${value} ${this.hoursText}`
    }));
    thresholds.push({
      value: 9999999,
      displayValue: 'Off'
    });
    return thresholds;
  }

  // For time before not detected threshold
  // We will display minutes and hours and a combination of both
  // Example :
  // 5 min(s)
  // 2 h
  // 3 h : 15 min(s)
  private getThresholdTimeBeforeNotDetectedValues(): Threshold[] {
    // From 5min to 1h : step by 5min
    const minutes = this.generateValues(5, 55, 5).map((value) => ({
      value,
      displayValue: `${value} ${this.minutesText}`
    }));
    // From 1h to 12h : step by 15 min
    const hours = this.generateValues(60, 12 * 60, 15).reduce((acc, value) => {
      // Minutes only
      let displayValue = `${value} ${this.minutesText}`;
      // In case we have hours and minutes
      const v = value >= 60 ? value / 60 : value;
      const decimalPart = Math.floor(v);
      const fractionalPart = (v - Math.floor(v)) * 60;
      if (value >= 60) {
        // Hours only
        displayValue = `${decimalPart} ${this.hoursText}`;
        if (fractionalPart > 0) {
          // hours and minutes
          displayValue += ` : ${fractionalPart} ${this.minutesText}`;
        }
      }
      return [
        ...acc,
        {
          value,
          displayValue
        }
      ];
    }, []);
    return [...minutes, ...hours];
  }

  private getFullThresholds(): Threshold[] {
    return this.generateValues(50, 95, 5).map((value) => ({ value, displayValue: `${value} ${this.percentageText}` }));
  }

  private generateValues(start: number, end: number, step?: number): number[] {
    const array = Array.from(Array.from({ length: end - start + 1 }), (val, index) => start + index);
    return step !== undefined ? array.filter((v) => !(v % step)) : array;
  }
}
