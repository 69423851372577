// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-grid-tile {
  border-radius: 50%;
  margin: 1px;
}
mat-grid-tile.color-selected {
  margin: 0;
  border: 1px solid #394c5a;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbG9yLXBpY2tlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGtCQUFBO0VBQ0EsV0FBQTtBQUNGO0FBQ0U7RUFDRSxTQUFBO0VBQ0EseUJBQUE7QUFDSiIsImZpbGUiOiJjb2xvci1waWNrZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJtYXQtZ3JpZC10aWxlIHtcclxuICBib3JkZXItcmFkaXVzOiA1MCU7XHJcbiAgbWFyZ2luOiAxcHg7XHJcblxyXG4gICYuY29sb3Itc2VsZWN0ZWQge1xyXG4gICAgbWFyZ2luOiAwO1xyXG4gICAgYm9yZGVyOiAxcHggc29saWQgIzM5NGM1YTtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/color-picker/color-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;AACE;EACE,SAAA;EACA,yBAAA;AACJ;AACA,4gBAA4gB","sourcesContent":["mat-grid-tile {\r\n  border-radius: 50%;\r\n  margin: 1px;\r\n\r\n  &.color-selected {\r\n    margin: 0;\r\n    border: 1px solid #394c5a;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
