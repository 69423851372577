// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-icon-content.icon-delay {
  height: 20px;
  width: 20px;
}

.delay-true {
  color: #bf5f69;
}

.delay-false {
  color: #cdcdcd;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR5bmFtaWMtaWNvbi1jZWxsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsWUFBQTtFQUNBLFdBQUE7QUFBSjs7QUFJQTtFQUNFLGNBQUE7QUFERjs7QUFJQTtFQUNFLGNBQUE7QUFERiIsImZpbGUiOiJkeW5hbWljLWljb24tY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi50YWJsZS1pY29uLWNvbnRlbnQge1xyXG4gICYuaWNvbi1kZWxheSB7XHJcbiAgICBoZWlnaHQ6IDIwcHg7XHJcbiAgICB3aWR0aDogMjBweDtcclxuICB9XHJcbn1cclxuXHJcbi5kZWxheS10cnVlIHtcclxuICBjb2xvcjogI2JmNWY2OTtcclxufVxyXG5cclxuLmRlbGF5LWZhbHNlIHtcclxuICBjb2xvcjogI2NkY2RjZDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/dynamic-icon-cell/dynamic-icon-cell.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,WAAA;AAAJ;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,cAAA;AADF;AACA,4lBAA4lB","sourcesContent":[".table-icon-content {\r\n  &.icon-delay {\r\n    height: 20px;\r\n    width: 20px;\r\n  }\r\n}\r\n\r\n.delay-true {\r\n  color: #bf5f69;\r\n}\r\n\r\n.delay-false {\r\n  color: #cdcdcd;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
