// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.year-picker-field input {
  font-size: 14px;
}

.year-picker-panel .mat-calendar-period-button {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInllYXItcGlja2VyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsZUFBQTtBQUFKOztBQUtFO0VBQ0UsYUFBQTtBQUZKIiwiZmlsZSI6InllYXItcGlja2VyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnllYXItcGlja2VyLWZpZWxkIHtcclxuICBpbnB1dCB7XHJcbiAgICBmb250LXNpemU6IDE0cHg7XHJcbiAgfVxyXG59XHJcblxyXG4ueWVhci1waWNrZXItcGFuZWwge1xyXG4gIC5tYXQtY2FsZW5kYXItcGVyaW9kLWJ1dHRvbiB7XHJcbiAgICBkaXNwbGF5OiBub25lO1xyXG4gIH1cclxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/year-picker/year-picker.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;;AAKE;EACE,aAAA;AAFJ;AACA,ggBAAggB","sourcesContent":[".year-picker-field {\r\n  input {\r\n    font-size: 14px;\r\n  }\r\n}\r\n\r\n.year-picker-panel {\r\n  .mat-calendar-period-button {\r\n    display: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
