// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.severity-cell {
  line-height: 20px;
  max-width: 70px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNldmVyaXR5LWNlbGwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxpQkFBQTtFQUNBLGVBQUE7QUFDRiIsImZpbGUiOiJzZXZlcml0eS1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNldmVyaXR5LWNlbGwge1xyXG4gIGxpbmUtaGVpZ2h0OiAyMHB4O1xyXG4gIG1heC13aWR0aDogNzBweFxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/severity-cell/severity-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;AACA,wXAAwX","sourcesContent":[".severity-cell {\r\n  line-height: 20px;\r\n  max-width: 70px\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
