import { Injectable } from '@angular/core';
import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/oyan';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { transformApiRequest, transformApiResponse } from '../../../../helpers/apis-helpers';
import { AssetsService } from '../../services/assets.service';
import { AssetsActions } from '../actions';

@Injectable()
export class AssetsEffects {
  loadAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssets),
      switchMap(({ request }) =>
        this.assetsService.getAll(transformApiRequest(request)).pipe(
          map((response: CommonApiResponse<Asset, CommonIndexedPagination>) =>
            AssetsActions.loadAssetsSuccess({ response: transformApiResponse<Asset>(response) })
          ),
          catchError((error) => of(AssetsActions.loadAssetsFailure({ error })))
        )
      )
    )
  );

  loadAssetById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssetById),
      switchMap((action) =>
        this.assetsService.getById(action.id).pipe(
          map((response: Asset) => AssetsActions.loadAssetByIdSuccess({ response })),
          catchError((error) => of(AssetsActions.loadAssetByIdFailure({ error })))
        )
      )
    )
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.loadAssetsFailure, AssetsActions.loadAssetByIdFailure),
        tap((action) => this.notificationService.displayError(action.type))
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.loadAssets, AssetsActions.loadAssetById),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.loadAssetsSuccess, AssetsActions.loadAssetsFailure, AssetsActions.loadAssetByIdSuccess, AssetsActions.loadAssetByIdFailure),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly assetsService: AssetsService
  ) {}
}
