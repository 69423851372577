// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-limit-warning {
  font-size: 12px;
  color: #bf5f68;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdyaWQtZXhwb3J0LWRpYWxvZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxjQUFBO0FBQ0YiLCJmaWxlIjoiZ3JpZC1leHBvcnQtZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmV4cG9ydC1saW1pdC13YXJuaW5nIHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbiAgY29sb3I6ICNiZjVmNjg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/grid-export/grid-export-dialog/grid-export-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;AACF;AACA,wYAAwY","sourcesContent":[".export-limit-warning {\r\n  font-size: 12px;\r\n  color: #bf5f68;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
