// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-header {
  height: 40px;
  margin-bottom: 10px;
}

.panel-title {
  font-size: 12px;
  line-height: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #b0b0b0;
  text-transform: uppercase;
  white-space: nowrap;
}

.panel-toolbar {
  margin-bottom: 15px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhbmVsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtFQUNBLG1CQUFBO0FBQ0Y7O0FBRUE7RUFDRSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxvQ0FBQTtFQUNBLGdCQUFBO0VBQ0EsY0FBQTtFQUNBLHlCQUFBO0VBQ0EsbUJBQUE7QUFDRjs7QUFFQTtFQUNFLG1CQUFBO0FBQ0YiLCJmaWxlIjoicGFuZWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucGFuZWwtaGVhZGVyIHtcclxuICBoZWlnaHQ6IDQwcHg7XHJcbiAgbWFyZ2luLWJvdHRvbTogMTBweDtcclxufVxyXG5cclxuLnBhbmVsLXRpdGxlIHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbiAgbGluZS1oZWlnaHQ6IDEycHg7XHJcbiAgZm9udC1mYW1pbHk6ICdPcGVuIFNhbnMnLCBzYW5zLXNlcmlmO1xyXG4gIGZvbnQtd2VpZ2h0OiA2MDA7XHJcbiAgY29sb3I6ICNiMGIwYjA7XHJcbiAgdGV4dC10cmFuc2Zvcm06IHVwcGVyY2FzZTtcclxuICB3aGl0ZS1zcGFjZTogbm93cmFwO1xyXG59XHJcblxyXG4ucGFuZWwtdG9vbGJhciB7XHJcbiAgbWFyZ2luLWJvdHRvbTogMTVweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/panel/panel.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;AACA,41BAA41B","sourcesContent":[".panel-header {\r\n  height: 40px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.panel-title {\r\n  font-size: 12px;\r\n  line-height: 12px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  font-weight: 600;\r\n  color: #b0b0b0;\r\n  text-transform: uppercase;\r\n  white-space: nowrap;\r\n}\r\n\r\n.panel-toolbar {\r\n  margin-bottom: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
