// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-card {
  padding: 10px;
  font-size: 12px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  color: #111b44;
  background-color: #e9eff6;
}

.detail-card-item {
  position: relative;
  padding-left: 70px;
  word-break: break-all;
  word-wrap: break-word;
}
.detail-card-item::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRldGFpbC1jYXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtFQUNBLG9DQUFBO0VBQ0EsY0FBQTtFQUNBLHlCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxrQkFBQTtFQUNBLGtCQUFBO0VBQ0EscUJBQUE7RUFDQSxxQkFBQTtBQUNGO0FBQUU7RUFDRSx5QkFBQTtFQUNBLGtCQUFBO0VBQ0EsT0FBQTtFQUNBLGdCQUFBO0FBRUoiLCJmaWxlIjoiZGV0YWlsLWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZGV0YWlsLWNhcmQge1xyXG4gIHBhZGRpbmc6IDEwcHg7XHJcbiAgZm9udC1zaXplOiAxMnB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAyMHB4O1xyXG4gIGZvbnQtZmFtaWx5OiAnT3BlbiBTYW5zJywgc2Fucy1zZXJpZjtcclxuICBjb2xvcjogIzExMWI0NDtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZTllZmY2O1xyXG59XHJcblxyXG4uZGV0YWlsLWNhcmQtaXRlbSB7XHJcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xyXG4gIHBhZGRpbmctbGVmdDogNzBweDtcclxuICB3b3JkLWJyZWFrOiBicmVhay1hbGw7XHJcbiAgd29yZC13cmFwOiBicmVhay13b3JkO1xyXG4gICY6OmJlZm9yZSB7XHJcbiAgICBjb250ZW50OiBhdHRyKGRhdGEtbGFiZWwpO1xyXG4gICAgcG9zaXRpb246IGFic29sdXRlO1xyXG4gICAgbGVmdDogMDtcclxuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/detail-popup-cards/detail-card/detail-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qBAAA;AACF;AAAE;EACE,yBAAA;EACA,kBAAA;EACA,OAAA;EACA,gBAAA;AAEJ;AACA,ojCAAojC","sourcesContent":[".detail-card {\r\n  padding: 10px;\r\n  font-size: 12px;\r\n  line-height: 20px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  color: #111b44;\r\n  background-color: #e9eff6;\r\n}\r\n\r\n.detail-card-item {\r\n  position: relative;\r\n  padding-left: 70px;\r\n  word-break: break-all;\r\n  word-wrap: break-word;\r\n  &::before {\r\n    content: attr(data-label);\r\n    position: absolute;\r\n    left: 0;\r\n    font-weight: 600;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
