import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, CommonCRUDService, CommonIndexedPagination } from '@iot-platform/models/common';
import { Gateway, Site } from '@iot-platform/models/oyan';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GatewaysService implements CommonCRUDService<Gateway, CommonIndexedPagination> {
  constructor(
    @Inject('environment') private readonly environment,
    private readonly httpClient: HttpClient
  ) {}

  getAll(request?: CommonApiRequest): Observable<CommonApiResponse<Gateway, CommonIndexedPagination>> {
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.gateways}`, { params: ApiHelpers.getHttpParams(request) })
      .pipe(map((response: CommonApiListResponse<Gateway>) => ApiHelpers.getHttpResponse<Gateway>(response)));
  }

  getById(id: string): Observable<Gateway> {
    return this.httpClient.get<Gateway>(`${this.environment.api.url}${this.environment.api.endpoints.gateways}/${id}`);
  }

  // TODO
  addOne(gateway: Gateway): Observable<Gateway> {
    return of(gateway);
  }

  // TODO
  updateOne(gateway: Gateway): Observable<Gateway> {
    return of(gateway);
  }

  // TODO
  deleteOne(gateway: Gateway): Observable<Gateway> {
    return of(gateway);
  }

  addGatewayToSite(gateway: Gateway, site: Site): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.environment.api.url}${this.environment.api.endpoints.gateways}/${gateway.id}/site/${site.id}`, {});
  }

  removeGatewayFromSite(gateway: Gateway, site: Site): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.environment.api.url}${this.environment.api.endpoints.gateways}/${gateway.id}/site/${site.id}`);
  }

  synchronize(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.environment.api.url}${this.environment.api.endpoints.synchronize}`);
  }
}
