import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { AbstractMasterViewComponent } from '@iot-platform/feature/master-view';
import {
  CLEAR_FILTERS_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { IotToolbarEvent, MasterViewEngineEvent, PlatformResponse } from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/oyan';
import { OyanUiRoutingConstants } from '../../../../oyan-ui.router.constants';
import { NavigationsFacade } from '../../../navigations/state/facades/navigations.facade';
import { AssetsFacade } from '../../state/facades/assets.facade';

/** *
 * Business rules

 * If Servitrax_ID is empty, there will be an alert displayed in red.
 * If the cylinder has a leak or if there is a battery problem this will be displayed in red.

 * If there is a mismatch between the Assigned_Shipto and the Servitrax_Shipto, both values will be displayed in red.
 * ==>
 * Case #1: the cylinder has left the hospital/ is that the filling center:
 * In this case, we should have "filling center" dislayed in the SVX ship-to column.
 * Or even better if you have the information from SVX the name of the fillng center.
 * As for the assigned ship-to it should be empty.
 * Case #2: the cylinder is detected in a hospital but no ship-to can be found in SVX:
 * In this case, the assigned ship-to is the detected ship-to and the SVX ship-to should be not found in red.
 * Case #3: the cylinder is detected in a different ship-to than SVX ship-to:
 * In this case, the assigned ship-to is the detected ship-to and both the SVX ship-to  the assigned ship-to  should be displayed in red.
 * Case #4:  both assigned ship-to and SVX ship-to are the same:
 * In this case, they are both display without alarms.
 */
@Component({
    selector: 'oyan-ui-assets-shell',
    templateUrl: './assets-shell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssetsShellComponent extends AbstractMasterViewComponent<Asset> {
  protected authFacade: AuthFacade = inject(AuthFacade);
  protected facade: AssetsFacade = inject(AssetsFacade);
  protected navigationsFacade: NavigationsFacade = inject(NavigationsFacade);

  constructor() {
    super();
    this.toolbarButtonList = signal([
      new IotToolbarDefaultButton(
        {
          ...CLEAR_FILTERS_BUTTON_CONFIG,
          tooltip: 'MV_TOOLBAR.TOOLTIP.CLEAR_APPLIED_FILTERS'
        },
        1
      ),
      new IotToolbarDefaultButton(
        {
          ...TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
          tooltip: 'MV_TOOLBAR.TOOLTIP.SHOW_FILTER_ENGINE'
        },
        2
      ),
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.REFRESH_MV' }, 3)
    ]);
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'onSiteClickEvent':
        this.navigationsFacade.goToSiteOverview(event.rawData.site, OyanUiRoutingConstants.ASSETS);
        break;
      case 'onLastMessageClickEvent':
        this.navigationsFacade.goToCommunicationViewFromAssets(event.rawData as Asset);
        break;
      default:
        break;
    }
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    super.onToolbarEvent(event);
    if (event.type === IotToolbarDispatchActionType.FILTER_TEXT_CHANGE.toString()) {
      this.onFilterTextChange(event.options);
    }
  }

  /**
   * Sorting rules
   * First display cylinders with SVX_ID not found
   * Next display cylinders with Assigned Shipto different than Servitrax Shipto (if Svx Shipto is not empty)
   * Then cylinders with alert (Leakage, Battery)
   */
  sortData(platformResponse: PlatformResponse): PlatformResponse {
    // Set Alert
    const { data } = platformResponse;
    // Get cylinders with empty SVX_ID. To be displayed first.
    const withEmptyBarcode = data.filter((e) => !e.barcode);
    // Get cylinders with SVX_ID
    const withDefinedBarcode = data.filter((e) => !!e.barcode);
    // Get cylinders with Servitrax Shipto
    const withDefinedSrvxShipTo = withDefinedBarcode.filter((e) => !!e.originShipToId);
    // Sort cylinders with Assigned Shipto and Servitrax Shipto (if Svx Shipto is not empty). To be displayed second.
    const diffShipTo = withDefinedSrvxShipTo.filter((e) => e.site.name !== e.originShipToId);
    // Sort cylinders with empty Servitrax Shipto. To be displayed third.
    const equalsShipTo = withDefinedSrvxShipTo.filter((e) => e.site.name === e.originShipToId);
    // Get cylinders with empty Servitrax Shipto. To be displayed last.
    const withEmptyOriginShipTo = withDefinedBarcode.filter((e) => !e.originShipToId);
    // Sort alerts
    const withAlerts = withEmptyOriginShipTo.filter((e) => e.alerts.battery || e.alerts.leakage || e.alerts.ventilator);
    const restOfItems = withEmptyOriginShipTo.filter((e) => !e.alerts.battery && !e.alerts.leakage && !e.alerts.ventilator);
    return {
      ...platformResponse,
      data: [
        ...this.sortByBarcode(withEmptyBarcode),
        ...this.sortByBarcode(diffShipTo),
        ...this.sortByBarcode(equalsShipTo),
        ...this.sortByBarcode(withAlerts),
        ...this.sortByBarcode(restOfItems)
      ]
    };
  }

  onFilterTextChange(searchString: string): void {
    const assets = this.facade.all();
    let filteredList: Asset[] = [...assets];
    if (searchString !== null) {
      filteredList = filteredList.filter(
        (asset: Asset) =>
          `${asset.lotNumber}`.toLowerCase().includes(searchString.toLowerCase()) ||
          `${asset.barcode}`.toLowerCase().includes(searchString.toLowerCase()) ||
          `${asset.detectionStatus}`.toLowerCase().includes(searchString.toLowerCase()) ||
          (asset.product && `${asset.product.name}`.toLowerCase().includes(searchString.toLowerCase())) ||
          (asset.site && `${asset.site.name}`.toLowerCase().includes(searchString.toLowerCase()))
      );
    }
    this.platformResponse.update((platformResponse) =>
      this.sortData({
        ...platformResponse,
        data: filteredList
      })
    );
    this.totalItems.set(filteredList.length);
  }

  private sortByBarcode(data: Asset[]): Asset[] {
    return data.sort(SortUtil.sortBy('barcode', false));
  }
}
