import { NgModule } from '@angular/core';
import { IotPlatformUiModule, PhoneNumberInputComponent } from '@iot-platform/iot-platform-ui';
import { Iot4bosBackofficeUiModule } from '@iot-platform/iot4bos/ui/backoffice';
import { InfoDisplayPipe } from '@iot-platform/pipes';

import { SharedModule } from '@iot-platform/shared';
import { MasterViewEngineModule } from '@iot-platform/table-engine';
import { ProfileModule, UserRelatedBusinessProfilesModule } from '@iot-platform/users';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AdminRouterModule } from './admin.router.module';
import { UserFormDialogComponent } from './components/user-form-dialog/user-form-dialog.component';
import { UserLinkBusinessProfileDialogComponent } from './components/user-link-business-profile-dialog/user-link-business-profile-dialog.component';
import { UserPreferencesFormDialogComponent } from './components/user-preferences-form-dialog/user-preferences-form-dialog.component';
import { UserProfileFormComponent } from './components/user-profile-form/user-profile-form.component';
import { UserProfileInfoComponent } from './components/user-profile-info/user-profile-info.component';
import { UserProfilePreferencesComponent } from './components/user-profile-preferences/user-profile-preferences.component';
import { AdminUsersComponent } from './containers/admin-users/admin-users.component';
import { UserProfileShellComponent } from './containers/user-profile-shell/user-profile-shell.component';
import { AdminBusinessProfilesEffects } from './state/effects/admin-business-profiles.effects';
import { AdminUsersEffects } from './state/effects/admin-users.effects';
import * as fromAdmin from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    AdminRouterModule,
    IotPlatformUiModule,
    MasterViewEngineModule,
    Iot4bosBackofficeUiModule,
    ProfileModule,
    StoreModule.forFeature(fromAdmin.featureKey, fromAdmin.reducers),
    EffectsModule.forFeature([AdminBusinessProfilesEffects, AdminUsersEffects]),
    UserRelatedBusinessProfilesModule,
    PhoneNumberInputComponent,
    InfoDisplayPipe
  ],
  declarations: [
    AdminUsersComponent,
    UserLinkBusinessProfileDialogComponent,
    UserFormDialogComponent,
    UserProfileShellComponent,
    UserPreferencesFormDialogComponent,
    UserProfileInfoComponent,
    UserProfilePreferencesComponent,
    UserProfileFormComponent
  ]
})
export class OyanUiAdminModule {}
