// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-platform-ui-chat-body__content {
  overflow: hidden;
}
.iot-platform-ui-chat-body__content_messages {
  padding: 10px 20px;
  height: 100%;
  overflow: auto;
  place-content: end;
}
.iot-platform-ui-chat-body__content_no_messages {
  font-size: 12px;
  place-content: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXQtYm9keS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGdCQUFBO0FBQUo7QUFFSTtFQUNFLGtCQUFBO0VBQ0EsWUFBQTtFQUNBLGNBQUE7RUFDQSxrQkFBQTtBQUFOO0FBR0k7RUFDRSxlQUFBO0VBQ0EscUJBQUE7QUFETiIsImZpbGUiOiJjaGF0LWJvZHkuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaW90LXBsYXRmb3JtLXVpLWNoYXQtYm9keSB7XHJcbiAgJl9fY29udGVudCB7XHJcbiAgICBvdmVyZmxvdzogaGlkZGVuO1xyXG5cclxuICAgICZfbWVzc2FnZXMge1xyXG4gICAgICBwYWRkaW5nOiAxMHB4IDIwcHg7XHJcbiAgICAgIGhlaWdodDogMTAwJTtcclxuICAgICAgb3ZlcmZsb3c6IGF1dG87XHJcbiAgICAgIHBsYWNlLWNvbnRlbnQ6IGVuZDtcclxuICAgIH1cclxuXHJcbiAgICAmX25vX21lc3NhZ2VzIHtcclxuICAgICAgZm9udC1zaXplOiAxMnB4O1xyXG4gICAgICBwbGFjZS1jb250ZW50OiBjZW50ZXI7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/chat/chat-body/chat-body.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AAAN;AAGI;EACE,eAAA;EACA,qBAAA;AADN;AACA,4xBAA4xB","sourcesContent":[".iot-platform-ui-chat-body {\r\n  &__content {\r\n    overflow: hidden;\r\n\r\n    &_messages {\r\n      padding: 10px 20px;\r\n      height: 100%;\r\n      overflow: auto;\r\n      place-content: end;\r\n    }\r\n\r\n    &_no_messages {\r\n      font-size: 12px;\r\n      place-content: center;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
