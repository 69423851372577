import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { UserAccount } from '@iot-platform/models/common';
import { UserProfileShellComponent as I4bUserProfileShellComponent } from '@iot-platform/users';
import { UserPreferencesFormDialogComponent } from '../../components/user-preferences-form-dialog/user-preferences-form-dialog.component';
import { UserProfileFormComponent } from '../../components/user-profile-form/user-profile-form.component';

@Component({
    selector: 'oyan-ui-user-profile-shell',
    templateUrl: './user-profile-shell.component.html',
    styleUrls: ['./user-profile-shell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserProfileShellComponent extends I4bUserProfileShellComponent {
  loading: Signal<boolean> = this.profileFacade.userLoading;

  editUserProfile() {
    this.dialog
      .open(UserProfileFormComponent, {
        width: '700px',
        disableClose: true,
        data: { user: this.user() }
      })
      .afterClosed()
      .subscribe((updatedUser: UserAccount) => {
        if (updatedUser) {
          this.profileFacade.saveUserProfile(updatedUser);
        }
      });
  }

  changeUserPreferences(): void {
    this.dialog
      .open(UserPreferencesFormDialogComponent, {
        width: '700px',
        disableClose: true,
        data: {
          user: this.user()
        }
      })
      .afterClosed()
      .subscribe((updatedUser: UserAccount) => {
        if (updatedUser) {
          this.profileFacade.saveUserPreferences(updatedUser);
        }
      });
  }
}
