import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SortUtil } from '@iot-platform/iot-platform-utils';

import { BusinessProfile } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AdminBusinessProfilesFacade } from '../../state/facades/admin-business-profiles.facade';

@Component({
    selector: 'oyan-ui-user-link-business-profile-dialog',
    templateUrl: './user-link-business-profile-dialog.component.html',
    styleUrls: ['./user-link-business-profile-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserLinkBusinessProfileDialogComponent implements OnInit, OnDestroy {
  filteredUnselectedBusinessProfiles$: BehaviorSubject<BusinessProfile[]> = new BehaviorSubject<BusinessProfile[]>([]);
  filteredCurrentBusinessProfiles$: BehaviorSubject<BusinessProfile[]> = new BehaviorSubject<BusinessProfile[]>([]);
  param = { user: '' };
  loader$: Observable<boolean> = toObservable(this.notificationService.loader);
  isDeleteActionClicked: boolean;
  canCreateBusinessProfile: boolean;
  canDeleteBusinessProfile: boolean;
  unselectedBusinessProfiles: BusinessProfile[] = [];
  currentBusinessProfiles: BusinessProfile[] = [];
  allBusinessProfiles: BusinessProfile[] = [];
  filterUnselected;
  filterSelected;

  subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<UserLinkBusinessProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly adminBusinessProfilesFacade: AdminBusinessProfilesFacade,
    private readonly notificationService: NotificationService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (this.data) {
      const { user, canCreateBusinessProfile, canDeleteBusinessProfile } = this.data;
      this.param.user = `${user.firstname} ${user.lastname}`;
      this.canCreateBusinessProfile = canCreateBusinessProfile;
      this.canDeleteBusinessProfile = canDeleteBusinessProfile;
    }
    this.initUserBusinessProfiles();
    this.initAllBusinessProfiles();
  }

  removeBusinessProfile(businessProfile: BusinessProfile): void {
    this.adminBusinessProfilesFacade.unlinkBusinessProfileFromMember(businessProfile.id, this.data.user);
  }

  addBusinessProfile(businessProfile: BusinessProfile): void {
    this.adminBusinessProfilesFacade.linkBusinessProfileToMember(businessProfile.id, this.data.user);
  }

  filterBusinessProfiles(event, listToFilter: BusinessProfile[], areBusinessProfilesSelected: boolean): void {
    if (areBusinessProfilesSelected) {
      this.filterSelected = event;
      this.filteredCurrentBusinessProfiles$.next(this.getFilteredBusinessProfiles(listToFilter, this.filterSelected));
    } else {
      this.filterUnselected = event;
      this.filteredUnselectedBusinessProfiles$.next(this.getFilteredBusinessProfiles(listToFilter, this.filterUnselected));
    }
  }

  exit(): void {
    this.dialogRef.close(true);
  }

  private getFilteredBusinessProfiles(listToFilter: BusinessProfile[], filterEvent): BusinessProfile[] {
    return listToFilter.filter(
      (businessProfile: BusinessProfile) =>
        businessProfile.name.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        businessProfile.entityName.toLowerCase().includes(filterEvent.target.value.toLowerCase())
    );
  }

  private initUserBusinessProfiles(): void {
    this.subscriptions.add(
      this.adminBusinessProfilesFacade.userBusinessProfiles$.subscribe((businessProfiles: BusinessProfile[]) => {
        this.currentBusinessProfiles = businessProfiles ? [...businessProfiles] : [];
        this.unselectedBusinessProfiles = this.checkAvailableUsers(this.allBusinessProfiles).sort(SortUtil.sortByProperty('name'));
        this.filteredUnselectedBusinessProfiles$.next(this.unselectedBusinessProfiles);

        if (this.filterSelected) {
          this.filterBusinessProfiles(this.filterSelected, this.currentBusinessProfiles, true);
        } else {
          this.filteredCurrentBusinessProfiles$.next([...this.currentBusinessProfiles]);
        }
      })
    );
  }

  private initAllBusinessProfiles(): void {
    this.subscriptions.add(
      this.adminBusinessProfilesFacade.allBusinessProfiles$.subscribe((businessProfiles: BusinessProfile[]) => {
        this.allBusinessProfiles = businessProfiles ? [...businessProfiles] : [];
        this.unselectedBusinessProfiles = [...this.checkAvailableUsers(this.allBusinessProfiles).sort(SortUtil.sortByProperty('name'))];
        this.filteredUnselectedBusinessProfiles$.next(this.unselectedBusinessProfiles);
      })
    );
  }

  private checkAvailableUsers(businessProfiles: BusinessProfile[]): BusinessProfile[] {
    const available: BusinessProfile[] = [];
    businessProfiles.forEach((businessProfile: BusinessProfile) => {
      if (this.currentBusinessProfiles.indexOf(this.currentBusinessProfiles.find((u) => businessProfile.id === u.id)) === -1) {
        available.push(businessProfile);
      }
    });
    return available;
  }
}
