import { BaseUser, BusinessProfile } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listBusinessProfiles = createAction('[Admin Business Profiles] Load Business Profiles');

export const listBusinessProfilesSuccess = createAction(
  '[Admin Business Profiles] Load Business Profiles Success',
  props<{ businessProfiles: BusinessProfile[] }>()
);

export const listBusinessProfilesFailure = createAction(
  '[Admin Business Profiles] Load Business Profiles Error',
  props<{
    error: unknown;
  }>()
);

export const addMemberToBusinessProfile = createAction(
  '[Admin Business Profiles Members] Add Member To Business Profile',
  props<{ businessProfileId: string; memberToAdd: BaseUser }>()
);

export const addMemberToBusinessProfileSuccess = createAction(
  '[Admin Business Profiles Members] Add Member To Business Profile Success',
  props<{ addedMember: BaseUser }>()
);
export const addMemberToBusinessProfileFailure = createAction(
  '[Admin Business Profiles Members] Add Member To Business Profile Failure',
  props<{ error: unknown }>()
);

export const removeMemberFromBusinessProfile = createAction(
  '[Admin Business Profiles Members] Remove Member From Business Profile',
  props<{ businessProfileId: string; memberToRemove: BaseUser }>()
);

export const removeMemberFromBusinessProfileSuccess = createAction(
  '[Admin Business Profiles Members] Remove Member From Business Profile Success',
  props<{ removedMember: BaseUser }>()
);
export const removeMemberFromBusinessProfileFailure = createAction(
  '[Admin Business Profiles Members] Remove Member From Business Profile Failure',
  props<{ error: unknown }>()
);

export const linkBusinessProfileToUserSuccess = createAction(
  '[Oyan Admin Business Profile] Link business profile to user Success',
  props<{ businessProfile: BusinessProfile; user: BaseUser }>()
);
export const linkBusinessProfileToUserFailure = createAction(
  '[Oyan Admin Business Profile] Link business profile to user Failure',
  props<{ error: unknown }>()
);

export const unlinkBusinessProfileFromUserSuccess = createAction(
  '[Oyan Admin Business Profile] Unlink business profile from user Success',
  props<{ businessProfile: BusinessProfile; user: BaseUser }>()
);

export const unlinkBusinessProfileFromUserFailure = createAction(
  '[Oyan Admin Business Profile] Unlink business profile from user Failure',
  props<{ error: unknown }>()
);

export const setUserBusinessProfiles = createAction(
  '[Oyan Admin Business Profile] Set User Business Profiles',
  props<{ businessProfiles: BusinessProfile[] }>()
);

export const setSelectedBusinessProfile = createAction(
  '[Oyan Admin Business Profile] Set selected business profile',
  props<{ businessProfile: BusinessProfile }>()
);
