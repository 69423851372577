// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-cell {
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
  word-break: break-all;
  color: #394c5a;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2R1Y3QtY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxvQ0FBQTtFQUNBLHFCQUFBO0VBQ0EsY0FBQTtBQUNGIiwiZmlsZSI6InByb2R1Y3QtY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5wcm9kdWN0LWNlbGwge1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBsaW5lLWhlaWdodDogMTZweDtcclxuICBmb250LWZhbWlseTogJ09wZW4gU2FucycsIHNhbnMtc2VyaWY7XHJcbiAgd29yZC1icmVhazogYnJlYWstYWxsO1xyXG4gIGNvbG9yOiAjMzk0YzVhO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/product-cell/product-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,qBAAA;EACA,cAAA;AACF;AACA,okBAAokB","sourcesContent":[".product-cell {\r\n  display: block;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  word-break: break-all;\r\n  color: #394c5a;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
