import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';

import { SharedModule } from '@iot-platform/shared';
import { MasterViewEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AssetsRoutingModule } from './assets-routing.module';
import { AssetsShellComponent } from './containers/assets-shell/assets-shell.component';
import { AssetsEffects } from './state/effects/assets.effects';
import * as fromAssets from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformUiModule,
    MasterViewEngineModule,
    AssetsRoutingModule,
    StoreModule.forFeature(fromAssets.featureKey, fromAssets.reducers),
    EffectsModule.forFeature([AssetsEffects])
  ],
  declarations: [AssetsShellComponent]
})
export class AssetsModule {}
