// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.last-command-status-cell {
  width: 100px;
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  display: block;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.last-command-status-cell-success {
  background-color: rgba(164, 255, 0, 0.1);
  padding: 0 9px;
}

.last-command-status-cell-failure {
  background-color: rgba(255, 0, 99, 0.1);
  padding: 0 9px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxhc3QtY29tbWFuZC1zdGF0dXMtY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxZQUFBO0VBQ0EsaUJBQUE7RUFDQSxlQUFBO0VBQ0EsY0FBQTtFQUNBLGdCQUFBO0VBQ0EscUJBQUE7RUFDQSx1QkFBQTtBQUNGOztBQUVBO0VBQ0Usd0NBQUE7RUFDQSxjQUFBO0FBQ0Y7O0FBRUE7RUFDRSx1Q0FBQTtFQUNBLGNBQUE7QUFDRiIsImZpbGUiOiJsYXN0LWNvbW1hbmQtc3RhdHVzLWNlbGwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubGFzdC1jb21tYW5kLXN0YXR1cy1jZWxsIHtcclxuICB3aWR0aDogMTAwcHg7XHJcbiAgaGVpZ2h0OiAzOHB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAzOHB4O1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBkaXNwbGF5OiBibG9jaztcclxuICBvdmVyZmxvdzogaGlkZGVuO1xyXG4gIHdvcmQtYnJlYWs6IGJyZWFrLWFsbDtcclxuICB0ZXh0LW92ZXJmbG93OiBlbGxpcHNpcztcclxufVxyXG5cclxuLmxhc3QtY29tbWFuZC1zdGF0dXMtY2VsbC1zdWNjZXNzIHtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKDE2NCwgMjU1LCAwLCAwLjEpO1xyXG4gIHBhZGRpbmc6IDAgOXB4O1xyXG59XHJcblxyXG4ubGFzdC1jb21tYW5kLXN0YXR1cy1jZWxsLWZhaWx1cmUge1xyXG4gIGJhY2tncm91bmQtY29sb3I6IHJnYmEoMjU1LCAwLCA5OSwgMC4xKTtcclxuICBwYWRkaW5nOiAwIDlweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/last-command-status-cell/last-command-status-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAEA;EACE,wCAAA;EACA,cAAA;AACF;;AAEA;EACE,uCAAA;EACA,cAAA;AACF;AACA,4jCAA4jC","sourcesContent":[".last-command-status-cell {\r\n  width: 100px;\r\n  height: 38px;\r\n  line-height: 38px;\r\n  font-size: 12px;\r\n  display: block;\r\n  overflow: hidden;\r\n  word-break: break-all;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.last-command-status-cell-success {\r\n  background-color: rgba(164, 255, 0, 0.1);\r\n  padding: 0 9px;\r\n}\r\n\r\n.last-command-status-cell-failure {\r\n  background-color: rgba(255, 0, 99, 0.1);\r\n  padding: 0 9px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
