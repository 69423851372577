import { Action, combineReducers } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as fromMessages from './messages.reducer';

export const featureKey = 'oyanMessages';

export interface ReceiversState {
  [fromMessages.featureKey]: fromMessages.State;
}

export interface State {
  [featureKey]: ReceiversState;
}

export function reducers(state: ReceiversState, action: Action): ActionReducer<ReceiversState> {
  return combineReducers({
    [fromMessages.featureKey]: fromMessages.reducer
  })(state, action) as unknown as ActionReducer<ReceiversState>;
}
