import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthFacade } from '@iot-platform/auth';
import { AbstractMasterViewComponent } from '@iot-platform/feature/master-view';
import { CLEAR_FILTERS_BUTTON_CONFIG, IotToolbarDefaultButton, REFRESH_BUTTON_CONFIG, TOGGLE_FILTER_ENGINE_BUTTON_CONFIG } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { CommonApiRequest, Filter, MasterViewEngineEvent, PlatformResponse } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';
import { AuthorizationService } from '../../../auth/services/authorization.service';
import { OyanAuthorizationConcept, OyanAuthorizationType } from '../../../auth/types/authorization.types';
import { ProductEditFormComponent } from '../../components/product-edit-form/product-edit-form.component';
import { ProductsFacade } from '../../state/facades/products.facade';

@Component({
    selector: 'oyan-ui-products-catalog',
    templateUrl: './products-catalog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProductsCatalogComponent extends AbstractMasterViewComponent<Product> implements OnInit {
  userPermissions: WritableSignal<{ key: string; value: boolean }[]> = signal([]);
  dialog: MatDialog = inject(MatDialog);
  protected authFacade: AuthFacade = inject(AuthFacade);
  protected facade: ProductsFacade = inject(ProductsFacade);
  protected authorizationService: AuthorizationService = inject(AuthorizationService);

  constructor() {
    super();
    this.toolbarButtonList = signal([
      new IotToolbarDefaultButton(
        {
          ...CLEAR_FILTERS_BUTTON_CONFIG,
          tooltip: 'MV_TOOLBAR.TOOLTIP.CLEAR_APPLIED_FILTERS'
        },
        1
      ),
      new IotToolbarDefaultButton(
        {
          ...TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
          tooltip: 'MV_TOOLBAR.TOOLTIP.SHOW_FILTER_ENGINE'
        },
        2
      ),
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.REFRESH_MV' }, 3)
    ]);
  }

  ngOnInit() {
    super.ngOnInit();
    this.userPermissions.set([
      {
        key: 'canUpdateProduct',
        value: this.authorizationService.applyAuthorization(OyanAuthorizationConcept.PRODUCT, OyanAuthorizationType.UPDATE)
      }
    ]);
  }

  reLoadMasterView(page: number = this.pagination().currentPage): void {
    const filters: Filter[] = this.currentFilters();
    if (!filters.find((f) => f.criteriaKey === 'entityId' && f.value === this.businessProfile()?.entityId)) {
      filters.push({ criteriaKey: 'entityId', value: this.businessProfile().entityId, isHidden: true });
    }
    const request: CommonApiRequest = {
      page,
      limit: this.pagination().limit,
      filters
    };
    this.facade.getAll(request);
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    if (event.type === 'editProduct') {
      this.editProduct(event.rawData);
    }
  }

  sortData(platformResponse: PlatformResponse): PlatformResponse {
    return {
      ...platformResponse,
      data: platformResponse.data.sort(SortUtil.sortByProperty('name'))
    };
  }

  editProduct(product: Product): void {
    this.dialog
      .open(ProductEditFormComponent, {
        width: '700px',
        disableClose: true,
        data: {
          product
        }
      })
      .afterClosed()
      .subscribe((productToUpdate: Product) => {
        if (productToUpdate) {
          this.facade.updateProduct(productToUpdate);
        }
      });
  }
}
