// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-info-container {
  font-size: 12px;
}

.user-profile-info-section-title {
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  padding-bottom: 20px;
}

.user-profile-info-name {
  font-size: 16px;
  padding-bottom: 10px;
}

.user-profile-info-status-container {
  padding-top: 10px;
}

.user-profile-info-status {
  font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItcHJvZmlsZS1pbmZvLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtFQUNBLGdCQUFBO0VBQ0EsbUJBQUE7RUFDQSxvQkFBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtFQUNBLG9CQUFBO0FBQ0Y7O0FBRUE7RUFDRSxpQkFBQTtBQUNGOztBQUVBO0VBQ0UsZ0JBQUE7QUFDRiIsImZpbGUiOiJ1c2VyLXByb2ZpbGUtaW5mby5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi51c2VyLXByb2ZpbGUtaW5mby1jb250YWluZXIge1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxufVxyXG5cclxuLnVzZXItcHJvZmlsZS1pbmZvLXNlY3Rpb24tdGl0bGUge1xyXG4gIGZvbnQtc2l6ZTogMThweDtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIHdoaXRlLXNwYWNlOiBub3dyYXA7XHJcbiAgcGFkZGluZy1ib3R0b206IDIwcHg7XHJcbn1cclxuXHJcbi51c2VyLXByb2ZpbGUtaW5mby1uYW1lIHtcclxuICBmb250LXNpemU6IDE2cHg7XHJcbiAgcGFkZGluZy1ib3R0b206IDEwcHg7XHJcbn1cclxuXHJcbi51c2VyLXByb2ZpbGUtaW5mby1zdGF0dXMtY29udGFpbmVyIHtcclxuICBwYWRkaW5nLXRvcDogMTBweDtcclxufVxyXG5cclxuLnVzZXItcHJvZmlsZS1pbmZvLXN0YXR1cyB7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/oyan-ui/src/lib/features/admin/components/user-profile-info/user-profile-info.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;AACF;;AAEA;EACE,eAAA;EACA,oBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF;AACA,ggCAAggC","sourcesContent":[".user-profile-info-container {\r\n  font-size: 12px;\r\n}\r\n\r\n.user-profile-info-section-title {\r\n  font-size: 18px;\r\n  font-weight: 600;\r\n  white-space: nowrap;\r\n  padding-bottom: 20px;\r\n}\r\n\r\n.user-profile-info-name {\r\n  font-size: 16px;\r\n  padding-bottom: 10px;\r\n}\r\n\r\n.user-profile-info-status-container {\r\n  padding-top: 10px;\r\n}\r\n\r\n.user-profile-info-status {\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
