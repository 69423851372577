import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthFacade } from '@iot-platform/auth';
import { ContactsFacade } from '@iot-platform/data-access/contacts';
import { ContactFormComponent, PopupComponent } from '@iot-platform/iot-platform-ui';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import {
  CardEvent,
  CardEventType,
  CommonApiResponse,
  CommonIndexedPagination,
  Contact,
  MasterViewEngineEvent,
  Pagination,
  PlatformResponse
} from '@iot-platform/models/common';
import { Gateway, Product, Receiver, Site, Ward } from '@iot-platform/models/oyan';
import { NotificationService } from '@iot-platform/notification';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { ThresholdType } from '../../../../components/cards/models/enums/threshold-type';
import { AuthorizationService } from '../../../auth/services/authorization.service';
import { OyanAuthorizationConcept, OyanAuthorizationType } from '../../../auth/types/authorization.types';
import { GatewaysService } from '../../../gateways/services/gateways.service';
import { ProductsService } from '../../../products/services/products.service';
import { ReceiversService } from '../../../receivers/services/receivers.service';
import { LinkReceiversFormDialogComponent } from '../../components/link-receivers-form-dialog/link-receivers-form-dialog.component';
import { SiteInfoFormComponent } from '../../components/site-info-form/site-info-form.component';
import { SitesFacade } from '../../state/facades/sites.facade';

@Component({
    selector: 'oyan-ui-site-overview',
    templateUrl: './site-overview.component.html',
    styleUrls: ['./site-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SiteOverviewComponent implements OnInit, OnDestroy {
  currentSite$: BehaviorSubject<Site> = new BehaviorSubject<Site>(null);
  productsBySite$: Observable<Product[]> = this.sitesFacade.productsBySite$;
  contactsBySite$: Observable<Contact[]> = this.sitesFacade.contactsBySite$;
  gatewaysBySite$: Observable<Gateway[]> = this.sitesFacade.gatewaysBySite$;
  siteLoading$: Observable<boolean> = this.sitesFacade.loading$;
  productsLoading$: Observable<boolean> = this.sitesFacade.productsLoading$;
  gatewaysLoading$: Observable<boolean> = this.sitesFacade.gatewaysLoading$;
  wardsLoading$: Observable<boolean> = this.sitesFacade.wardsLoading$;
  wardsLoaded$: Observable<boolean> = this.sitesFacade.wardsLoaded$;
  contactsLoading$: Observable<boolean> = this.sitesFacade.contactsLoading$;

  products$: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  gateways$: BehaviorSubject<Gateway[]> = new BehaviorSubject([]);

  wardsBySiteAsPlatformResponse$: BehaviorSubject<PlatformResponse> = new BehaviorSubject({
    total: 0,
    maxPage: 0,
    limit: 0,
    hasMore: false,
    data: [],
    currentPage: 0
  });

  canDeleteSite = false;
  canUpdateSite = false;
  canUpdateWard = false;
  canAddProduct = false;
  canDeleteProduct = false;
  canAddGateway = false;
  canDeleteGateway = false;
  canAddContact = false;
  canUpdateContact = false;
  canDeleteContact = false;
  userPermissions: { key: string; value: boolean }[] = [];
  subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public authorizationService: AuthorizationService,
    private sitesFacade: SitesFacade,
    private authFacade: AuthFacade,
    private productsService: ProductsService,
    private notificationService: NotificationService,
    private receiversService: ReceiversService,
    private translateService: TranslateService,
    private readonly gatewaysService: GatewaysService,
    private readonly contactsFacade: ContactsFacade
  ) {
    this.canDeleteSite = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.SITE, OyanAuthorizationType.DELETE);
    this.canUpdateSite = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.SITE, OyanAuthorizationType.UPDATE);
    this.canUpdateWard = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.WARD, OyanAuthorizationType.UPDATE);
    this.canAddProduct = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.PRODUCT, OyanAuthorizationType.CREATE);
    this.canDeleteProduct = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.PRODUCT, OyanAuthorizationType.DELETE);
    this.canAddContact = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.CONTACT, OyanAuthorizationType.CREATE);
    this.canUpdateContact = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.CONTACT, OyanAuthorizationType.UPDATE);
    this.canDeleteContact = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.CONTACT, OyanAuthorizationType.DELETE);
    this.canAddGateway = false;
    this.canDeleteGateway = false;
    this.userPermissions = [
      { key: 'canDeleteSite', value: this.canDeleteSite },
      { key: 'canUpdateSite', value: this.canUpdateSite },
      { key: 'canUpdateWard', value: this.canUpdateWard }
    ];
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    let isLookupsInitialized = false; // Used to prevent multiple api calls to load lookups

    this.subscriptions.add(
      this.sitesFacade.wardsBySiteAsPlatformResponse$.subscribe((response: PlatformResponse) => this.wardsBySiteAsPlatformResponse$.next(response))
    );
    this.subscriptions.add(
      combineLatest([this.authFacade.selectedBusinessProfile$, this.sitesFacade.currentEntity$])
        // Should call apis when business profile initialized
        .pipe(filter(([selectedBusinessProfile]) => !!selectedBusinessProfile))
        .subscribe(([_, currentSite]) => {
          // Set current site
          if (currentSite) {
            this.currentSite$.next(currentSite);
          }
          // Load site by id if not exist
          const siteId = this.activatedRoute.snapshot.paramMap.get('siteId');
          const changedSite: boolean = !currentSite || currentSite.id.toString() !== siteId.toString();
          if (changedSite) {
            this.sitesFacade.loadSiteById(siteId);
          }
        })
    );
    this.subscriptions.add(
      this.currentSite$
        .pipe(
          // Should call lookups apis once
          filter((s: Site) => !!s && !isLookupsInitialized),
          switchMap((currentSite: Site) => {
            isLookupsInitialized = true;
            const products$ = this.productsService
              .getAll({ filters: [{ criteriaKey: 'entityId', value: currentSite.entity.id }] })
              .pipe(map(({ data }) => data));
            const gateways$ = this.gatewaysService
              .getAll({ filters: [{ criteriaKey: 'entityId', value: currentSite.entity.id }] })
              .pipe(map(({ data }) => data));
            return combineLatest([products$, gateways$, this.productsBySite$, this.gatewaysBySite$]);
          })
        )
        .subscribe(([products, gateways, productsBySite, gatewaysBySite]) => {
          // Set unassociated products
          this.products$.next(products.filter((p) => !productsBySite.some((ps) => ps.id === p.id)));
          // Set unassociated gateways
          this.gateways$.next(gateways.filter((g) => !gatewaysBySite.some((gs) => gs.id === g.id)));
        })
    );
  }

  onEditSite(): void {
    this.subscriptions.add(
      this.dialog
        .open(SiteInfoFormComponent, {
          width: '990px',
          disableClose: true,
          data: { site: this.currentSite$.value }
        })
        .afterClosed()
        .subscribe((result: Site) => {
          if (result) {
            this.sitesFacade.updateSite(result);
          }
        })
    );
  }

  onDeleteSite(): void {
    const currentSite: Site = this.currentSite$.value;
    this.subscriptions.add(
      this.dialog
        .open(PopupComponent, {
          width: '500px',
          disableClose: true,
          data: { type: 'delete', value: currentSite.name }
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.sitesFacade.deleteSite(currentSite);
          }
        })
    );
  }

  onDispatchCardEvent(event: CardEvent): void {
    switch (event.eventType) {
      case CardEventType.ADD_PRODUCT:
        this.sitesFacade.addProductToSite(event.data as Product, this.currentSite$.value);
        break;
      case CardEventType.REMOVE_PRODUCT:
        this.removeProductFromSite(event.data as Product, this.currentSite$.value);
        break;
      case CardEventType.ADD_GATEWAY:
        this.sitesFacade.addGatewayToSite(event.data as Gateway, this.currentSite$.value);
        break;
      case CardEventType.REMOVE_GATEWAY:
        this.removeGatewayFromSite(event.data as Gateway, this.currentSite$.value);
        break;
      case CardEventType.THRESHOLD_SELECTION_CHANGE:
        this.updateSiteThreshold(event.data as { thresholdType: ThresholdType; value: number });
        break;
      case CardEventType.SYNCHRONIZE_GATEWAY:
        this.sitesFacade.synchronizeGateways(this.currentSite$.value);
        break;
      case CardEventType.UPDATE_CONTACT:
        this.editContact(event.data as Contact);
        break;
      case CardEventType.ADD_CONTACT:
        this.createContact();
        break;
      case CardEventType.REMOVE_CONTACT:
        this.removeContact(event.data as Contact);
        break;
      default:
        break;
    }
  }

  removeGatewayFromSite(gateway: Gateway, site: Site): void {
    this.subscriptions.add(
      this.dialog
        .open(PopupComponent, {
          width: '500px',
          disableClose: true,
          data: { type: 'delete', value: gateway.eui }
        })
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.sitesFacade.removeGatewayFromSite(gateway, site);
          }
        })
    );
  }

  removeProductFromSite(product: Product, site: Site): void {
    this.subscriptions.add(
      this.dialog
        .open(PopupComponent, {
          width: '500px',
          disableClose: true,
          data: { type: 'delete', value: product.name }
        })
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.sitesFacade.removeProductFromSite(product, site);
          }
        })
    );
  }

  editContact(contact: Contact): void {
    this.subscriptions.add(
      this.dialog
        .open(ContactFormComponent, {
          width: '600px',
          disableClose: true,
          data: {
            contact: {
              ...contact,
              site: {
                id: this.currentSite$.value.id
              }
            },
            site: this.currentSite$.value
          }
        })
        .afterClosed()
        .subscribe((editedContact: Contact) => {
          if (editedContact) {
            this.contactsFacade.updateContact(editedContact);
          }
        })
    );
  }

  createContact(): void {
    this.subscriptions.add(
      this.dialog
        .open(ContactFormComponent, {
          width: '600px',
          disableClose: true,
          data: {
            contact: {
              site: {
                id: this.currentSite$.value.id
              }
            },
            site: this.currentSite$.value
          }
        })
        .afterClosed()
        .subscribe((contact: Contact) => {
          if (contact) {
            this.contactsFacade.addContact(contact);
          }
        })
    );
  }

  removeContact(contact: Contact): void {
    this.subscriptions.add(
      this.dialog
        .open(PopupComponent, {
          width: '500px',
          disableClose: true,
          data: { type: 'delete', value: `${contact.firstname} ${contact.lastname}` }
        })
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.contactsFacade.deleteContact(contact);
          }
        })
    );
  }

  onStocksPageChangeEvent(pagination: Pagination): void {
    this.sitesFacade.loadWardsBySite(this.currentSite$.value, pagination);
  }

  onDispatchStocksMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'linkReceivers':
        this.linkReceiversToWard(event.rawData as Ward);
        break;
      default:
        break;
    }
  }

  updateSiteThreshold({ thresholdType, value }): void {
    const site: Site = { ...this.currentSite$.value };
    switch (thresholdType) {
      case ThresholdType.CHECK_IN_THRESHOLD:
        site.checkInThreshold = value;
        break;
      case ThresholdType.CHECK_OUT_THRESHOLD:
        site.checkOutThreshold = value;
        break;
      case ThresholdType.TIME_BEFORE_NOT_DETECTED:
        site.timeBeforeNotDetected = value;
        break;
      case ThresholdType.THRESHOLD_FULL:
        site.thresholdFull = value;
        break;
      default:
        break;
    }
    this.sitesFacade.updateSite(site);
  }

  linkReceiversToWard(ward: Ward): void {
    this.notificationService.displayLoader(true);
    const message$ = this.translateService.get('SITES.LINK_RECEIVERS_FORM.NO_RECEIVERS_TO_ASSIGN');
    const receivers$ = this.receiversService
      .getAll({
        filters: [
          { criteriaKey: 'siteId', value: this.currentSite$.value.id },
          { criteriaKey: 'entityId', value: this.currentSite$.value.entity.id },
          { criteriaKey: 'assign', value: false }
        ]
      })
      .pipe(
        finalize(() => this.notificationService.displayLoader(false)),
        map((response: CommonApiResponse<Receiver, CommonIndexedPagination>) => GetUtils.get(response, 'data', []))
      );
    this.subscriptions.add(
      combineLatest([receivers$, message$]).subscribe(([receivers, message]) => {
        const wardReceivers: Receiver[] = GetUtils.get(ward, 'receivers', []);
        const canAssignReceivers = !!receivers.length || !!wardReceivers.length;
        if (canAssignReceivers) {
          this.openReceiversForm(ward, receivers);
        } else {
          this.showInfoMessage(message);
        }
      })
    );
  }

  openReceiversForm(ward: Ward, receivers: Receiver[]): void {
    this.dialog.open(LinkReceiversFormDialogComponent, {
      width: '900px',
      disableClose: true,
      data: {
        site: this.currentSite$.value,
        ward,
        receivers
      }
    });
  }

  showInfoMessage(message: string): void {
    this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'info', value: message }
    });
  }
}
