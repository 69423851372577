import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { UserAccount } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortUtil } from '@iot-platform/iot-platform-utils';

@Component({
    selector: 'oyan-ui-user-preferences-form-dialog',
    templateUrl: './user-preferences-form-dialog.component.html',
    styleUrls: ['./user-preferences-form-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserPreferencesFormDialogComponent implements OnInit {
  form: UntypedFormGroup;
  availableLanguages: string[] = this.translateService.langs;
  countries$: Observable<{ key: string; value: string }[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: UserAccount },
    private readonly dialogRef: MatDialogRef<UserPreferencesFormDialogComponent>,
    private readonly translateService: TranslateService
  ) {
    this.countries$ = this.translateService.get('IOT_DICTIONARY.COUNTRIES').pipe(
      map((countries: { [key: string]: string }) =>
        Object.keys(countries)
          .map((key: string) => ({ key, value: countries[key] }))
          .sort(SortUtil.sortByProperty('value'))
      )
    );
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      appLanguage: new UntypedFormControl(GetUtils.get(this.data, 'user.preferences.appLanguage', 'en'), [Validators.required]),
      addressCountry: new UntypedFormControl(GetUtils.get(this.data, 'user.addressCountry', ''))
    });
  }

  get appLanguage(): AbstractControl {
    return this.form.get('appLanguage');
  }

  get addressCountry(): AbstractControl {
    return this.form.get('addressCountry');
  }

  save(): void {
    const updated: UserAccount = {
      ...this.data.user,
      addressCountry: this.addressCountry.value,
      preferences: {
        ...this.data.user.preferences,
        appLanguage: this.appLanguage.value
      }
    } as UserAccount;
    this.dialogRef.close(updated);
  }

  close(): void {
    this.dialogRef.close();
  }
}
