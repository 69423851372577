// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-route-section {
  padding: 4px 12px 2px 12px;
}

.sub-route-section {
  padding-left: 20px;
}

.day-section {
  max-height: 200px;
  overflow-y: auto;
}

.sub-route-color {
  width: 10px;
  height: 10px;
  border-radius: 45px;
  align-content: baseline;
  margin-left: 8px;
  margin-right: 6px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hcC1wYW5lbC1pbmZvLXBvcHVwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsMEJBQUE7QUFDRjs7QUFFQTtFQUNFLGtCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxpQkFBQTtFQUNBLGdCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxXQUFBO0VBQWEsWUFBQTtFQUFjLG1CQUFBO0VBQXFCLHVCQUFBO0VBQXlCLGdCQUFBO0VBQWtCLGlCQUFBO0FBTTdGIiwiZmlsZSI6Im1hcC1wYW5lbC1pbmZvLXBvcHVwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1haW4tcm91dGUtc2VjdGlvbiB7XHJcbiAgcGFkZGluZzogNHB4IDEycHggMnB4IDEycHhcclxufVxyXG5cclxuLnN1Yi1yb3V0ZS1zZWN0aW9uIHtcclxuICBwYWRkaW5nLWxlZnQ6IDIwcHg7XHJcbn1cclxuXHJcbi5kYXktc2VjdGlvbiB7XHJcbiAgbWF4LWhlaWdodDogMjAwcHg7XHJcbiAgb3ZlcmZsb3cteTogYXV0bztcclxufVxyXG5cclxuLnN1Yi1yb3V0ZS1jb2xvciB7XHJcbiAgd2lkdGg6IDEwcHg7IGhlaWdodDogMTBweDsgYm9yZGVyLXJhZGl1czogNDVweDsgYWxpZ24tY29udGVudDogYmFzZWxpbmU7IG1hcmdpbi1sZWZ0OiA4cHg7IG1hcmdpbi1yaWdodDogNnB4O1xyXG59XHJcblxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-maps/src/lib/components/map-panel-info-popup/map-panel-info-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EAAa,YAAA;EAAc,mBAAA;EAAqB,uBAAA;EAAyB,gBAAA;EAAkB,iBAAA;AAM7F;AACA,45BAA45B","sourcesContent":[".main-route-section {\r\n  padding: 4px 12px 2px 12px\r\n}\r\n\r\n.sub-route-section {\r\n  padding-left: 20px;\r\n}\r\n\r\n.day-section {\r\n  max-height: 200px;\r\n  overflow-y: auto;\r\n}\r\n\r\n.sub-route-color {\r\n  width: 10px; height: 10px; border-radius: 45px; align-content: baseline; margin-left: 8px; margin-right: 6px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
