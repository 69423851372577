// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clear-all-button {
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  line-height: 26px;
  border-radius: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNvdXJjZS12YXJpYWJsZXMtY29uZmlndXJhdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxnQkFBQTtFQUNBLGlCQUFBO0VBQ0EsaUJBQUE7RUFDQSxtQkFBQTtBQUNGIiwiZmlsZSI6InNvdXJjZS12YXJpYWJsZXMtY29uZmlndXJhdGlvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbGVhci1hbGwtYnV0dG9uIHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICBtYXJnaW4tbGVmdDogMTBweDtcclxuICBsaW5lLWhlaWdodDogMjZweDtcclxuICBib3JkZXItcmFkaXVzOiAxNnB4O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-asset/src/lib/source-variables-configuration/source-variables-configuration.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AACF;AACA,wjBAAwjB","sourcesContent":[".clear-all-button {\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n  margin-left: 10px;\r\n  line-height: 26px;\r\n  border-radius: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
