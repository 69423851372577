// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version {
  font-size: 10px;
  color: #c3c3c3;
  padding: 16px 0;
}
.version:hover {
  cursor: pointer;
  text-decoration: underline;
}
.version__label {
  text-transform: capitalize;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ1aWxkLWluZm8uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsY0FBQTtFQUNBLGVBQUE7QUFDRjtBQUNFO0VBQ0UsZUFBQTtFQUNBLDBCQUFBO0FBQ0o7QUFDRTtFQUNFLDBCQUFBO0FBQ0oiLCJmaWxlIjoiYnVpbGQtaW5mby5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi52ZXJzaW9uIHtcclxuICBmb250LXNpemU6IDEwcHg7XHJcbiAgY29sb3I6ICNjM2MzYzM7XHJcbiAgcGFkZGluZzogMTZweCAwO1xyXG5cclxuICAmOmhvdmVyIHtcclxuICAgIGN1cnNvcjogcG9pbnRlcjtcclxuICAgIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xyXG4gIH1cclxuICAmX19sYWJlbCB7XHJcbiAgICB0ZXh0LXRyYW5zZm9ybTogY2FwaXRhbGl6ZTtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/build-info/build-info.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;EACA,0BAAA;AACJ;AACE;EACE,0BAAA;AACJ;AACA,gpBAAgpB","sourcesContent":[".version {\r\n  font-size: 10px;\r\n  color: #c3c3c3;\r\n  padding: 16px 0;\r\n\r\n  &:hover {\r\n    cursor: pointer;\r\n    text-decoration: underline;\r\n  }\r\n  &__label {\r\n    text-transform: capitalize;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
