// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-grid;
}

.wrapper {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.basic-click-cell {
  font-size: 12px;
  cursor: pointer;
  transition: all 300ms ease 0ms;
}
.basic-click-cell:hover {
  color: #8ed0ff;
  text-decoration: underline;
}
.basic-click-cell.active {
  color: #8ed0ff;
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhc2ljLWxpbmstY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG9CQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLHFCQUFBO0VBQ0EsdUJBQUE7QUFDRjs7QUFFQTtFQUNFLGVBQUE7RUFDQSxlQUFBO0VBQ0EsOEJBQUE7QUFDRjtBQUNFO0VBQ0UsY0FBQTtFQUNBLDBCQUFBO0FBQ0o7QUFFRTtFQUNFLGNBQUE7RUFDQSwwQkFBQTtBQUFKIiwiZmlsZSI6ImJhc2ljLWxpbmstY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcclxuICBkaXNwbGF5OiBpbmxpbmUtZ3JpZDtcclxufVxyXG5cclxuLndyYXBwZXIge1xyXG4gIG92ZXJmbG93OiBoaWRkZW47XHJcbiAgd29yZC1icmVhazogYnJlYWstYWxsO1xyXG4gIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xyXG59XHJcblxyXG4uYmFzaWMtY2xpY2stY2VsbCB7XHJcbiAgZm9udC1zaXplOiAxMnB4O1xyXG4gIGN1cnNvcjogcG9pbnRlcjtcclxuICB0cmFuc2l0aW9uOiBhbGwgMzAwbXMgZWFzZSAwbXM7XHJcblxyXG4gICY6aG92ZXIge1xyXG4gICAgY29sb3I6ICM4ZWQwZmY7XHJcbiAgICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTtcclxuICB9XHJcblxyXG4gICYuYWN0aXZlIHtcclxuICAgIGNvbG9yOiAjOGVkMGZmO1xyXG4gICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/basic-link-cell/basic-link-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,gBAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,8BAAA;AACF;AACE;EACE,cAAA;EACA,0BAAA;AACJ;AAEE;EACE,cAAA;EACA,0BAAA;AAAJ;AACA,ohCAAohC","sourcesContent":[":host {\r\n  display: inline-grid;\r\n}\r\n\r\n.wrapper {\r\n  overflow: hidden;\r\n  word-break: break-all;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.basic-click-cell {\r\n  font-size: 12px;\r\n  cursor: pointer;\r\n  transition: all 300ms ease 0ms;\r\n\r\n  &:hover {\r\n    color: #8ed0ff;\r\n    text-decoration: underline;\r\n  }\r\n\r\n  &.active {\r\n    color: #8ed0ff;\r\n    text-decoration: underline;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
