// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-info__image img {
  max-width: 365px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpdGUtaW5mby1mb3JtLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsZ0JBQUE7QUFBSiIsImZpbGUiOiJzaXRlLWluZm8tZm9ybS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zaXRlLWluZm8ge1xyXG4gICZfX2ltYWdlIGltZyB7XHJcbiAgICBtYXgtd2lkdGg6IDM2NXB4O1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/oyan-ui/src/lib/features/sites/components/site-info-form/site-info-form.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AACA,gXAAgX","sourcesContent":[".site-info {\r\n  &__image img {\r\n    max-width: 365px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
