import { Component, effect, Signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserPreferences } from '@iot-platform/models/common';
import { fromUserPreferences, PreferencesActions, UserProfileService } from '@iot-platform/users';
import { Store } from '@ngrx/store';

@Component({
  selector: 'oyan-app-root',
  template: `
    <router-outlet></router-outlet>
    <iot-platform-notification></iot-platform-notification>
  `,
  standalone: false
})
export class AppComponent {
  private preferences: Signal<UserPreferences> = this.store.selectSignal(fromUserPreferences.getPreferences);

  constructor(
    private store: Store,
    private userProfileService: UserProfileService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iot4bos-icon-set.svg'));
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/oyan-icon-set.svg'));

    effect(() => {
      const preferences = this.preferences();
      if (preferences) {
        this.userProfileService.setThemeOverlay(preferences.appTheme);
        this.store.dispatch(PreferencesActions.changeLanguage({ lang: preferences.appLanguage }));
      }
    });
  }
}
