// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-cell {
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
  word-break: break-all;
  color: #394c5a;
}

.grid-cell--error-message {
  font-style: italic;
  color: #b0b0b0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdyaWQtbmFtZS1jZWxsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtFQUNBLG9DQUFBO0VBQ0EscUJBQUE7RUFDQSxjQUFBO0FBQ0Y7O0FBRUE7RUFDRSxrQkFBQTtFQUNBLGNBQUE7QUFDRiIsImZpbGUiOiJncmlkLW5hbWUtY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5ncmlkLWNlbGwge1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBsaW5lLWhlaWdodDogMTZweDtcclxuICBmb250LWZhbWlseTogJ09wZW4gU2FucycsIHNhbnMtc2VyaWY7XHJcbiAgd29yZC1icmVhazogYnJlYWstYWxsO1xyXG4gIGNvbG9yOiAjMzk0YzVhO1xyXG59XHJcblxyXG4uZ3JpZC1jZWxsLS1lcnJvci1tZXNzYWdlIHtcclxuICBmb250LXN0eWxlOiBpdGFsaWM7XHJcbiAgY29sb3I6ICNiMGIwYjA7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/grid-name-cell/grid-name-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,qBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,cAAA;AACF;AACA,guBAAguB","sourcesContent":[".grid-cell {\r\n  display: block;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  word-break: break-all;\r\n  color: #394c5a;\r\n}\r\n\r\n.grid-cell--error-message {\r\n  font-style: italic;\r\n  color: #b0b0b0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
