import { Injectable } from '@angular/core';
import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { transformApiRequest, transformApiResponse } from '../../../../helpers/apis-helpers';
import { GatewaysService } from '../../services/gateways.service';
import { GatewaysActions } from '../actions';

@Injectable()
export class GatewaysEffects {
  loadGateways$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GatewaysActions.loadGateways),
      switchMap(({ request }) =>
        this.gatewaysService.getAll(transformApiRequest(request)).pipe(
          map((response: CommonApiResponse<Gateway, CommonIndexedPagination>) =>
            GatewaysActions.loadGatewaysSuccess({ response: transformApiResponse<Gateway>(response) })
          ),
          catchError((error) => of(GatewaysActions.loadGatewaysFailure({ error })))
        )
      )
    )
  );

  loadGatewayById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GatewaysActions.loadGatewayById),
      switchMap((action) =>
        this.gatewaysService.getById(action.id).pipe(
          map((response: Gateway) => GatewaysActions.loadGatewayByIdSuccess({ response })),
          catchError((error) => of(GatewaysActions.loadGatewayByIdFailure({ error })))
        )
      )
    )
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GatewaysActions.loadGatewaysFailure, GatewaysActions.loadGatewayByIdFailure),
        tap((action) => this.notificationService.displayError(action.type))
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GatewaysActions.loadGateways, GatewaysActions.loadGatewayById),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GatewaysActions.loadGatewaysSuccess,
          GatewaysActions.loadGatewaysFailure,
          GatewaysActions.loadGatewayByIdSuccess,
          GatewaysActions.loadGatewayByIdFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly gatewaysService: GatewaysService
  ) {}
}
