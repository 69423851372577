// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-click-cell {
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  cursor: pointer;
  transition: all 300ms ease 0ms;
}
.basic-click-cell:hover, .basic-click-cell.active, .basic-click-cell.visited {
  color: #8ed0ff;
}
.basic-click-cell:hover, .basic-click-cell.active {
  text-decoration: underline;
}

.link_padding {
  padding: 5px 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhc2ljLWxpbmstY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxvQkFBQTtFQUNBLHFCQUFBO0VBQ0EsNEJBQUE7RUFDQSxnQkFBQTtFQUNBLHFCQUFBO0VBRUEsZUFBQTtFQUNBLDhCQUFBO0FBQUY7QUFFRTtFQUdFLGNBQUE7QUFGSjtBQUlFO0VBRUUsMEJBQUE7QUFISjs7QUFPQTtFQUNFLGlCQUFBO0FBSkYiLCJmaWxlIjoiYmFzaWMtbGluay1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmJhc2ljLWNsaWNrLWNlbGwge1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBkaXNwbGF5OiAtd2Via2l0LWJveDtcclxuICAtd2Via2l0LWxpbmUtY2xhbXA6IDE7XHJcbiAgLXdlYmtpdC1ib3gtb3JpZW50OiB2ZXJ0aWNhbDtcclxuICBvdmVyZmxvdzogaGlkZGVuO1xyXG4gIHdvcmQtYnJlYWs6IGJyZWFrLWFsbDtcclxuXHJcbiAgY3Vyc29yOiBwb2ludGVyO1xyXG4gIHRyYW5zaXRpb246IGFsbCAzMDBtcyBlYXNlIDBtcztcclxuXHJcbiAgJjpob3ZlcixcclxuICAmLmFjdGl2ZSxcclxuICAmLnZpc2l0ZWQge1xyXG4gICAgY29sb3I6ICM4ZWQwZmY7XHJcbiAgfVxyXG4gICY6aG92ZXIsXHJcbiAgJi5hY3RpdmUge1xyXG4gICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XHJcbiAgfVxyXG59XHJcblxyXG4ubGlua19wYWRkaW5nIHtcclxuICBwYWRkaW5nOiA1cHggMjBweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/basic-link-cell/basic-link-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,qBAAA;EAEA,eAAA;EACA,8BAAA;AAAF;AAEE;EAGE,cAAA;AAFJ;AAIE;EAEE,0BAAA;AAHJ;;AAOA;EACE,iBAAA;AAJF;AACA,4iCAA4iC","sourcesContent":[".basic-click-cell {\r\n  font-size: 12px;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 1;\r\n  -webkit-box-orient: vertical;\r\n  overflow: hidden;\r\n  word-break: break-all;\r\n\r\n  cursor: pointer;\r\n  transition: all 300ms ease 0ms;\r\n\r\n  &:hover,\r\n  &.active,\r\n  &.visited {\r\n    color: #8ed0ff;\r\n  }\r\n  &:hover,\r\n  &.active {\r\n    text-decoration: underline;\r\n  }\r\n}\r\n\r\n.link_padding {\r\n  padding: 5px 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
