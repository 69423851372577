// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entity-name {
  font-style: italic;
  font-size: 14px;
  padding-left: 16px;
}
.entity-name:hover:not(.entity-name--disabled) {
  cursor: pointer;
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkbWluLWJ1c2luZXNzLXByb2ZpbGVzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usa0JBQUE7RUFDQSxlQUFBO0VBQ0Esa0JBQUE7QUFDRjtBQUNFO0VBQ0UsZUFBQTtFQUNBLDBCQUFBO0FBQ0oiLCJmaWxlIjoiYWRtaW4tYnVzaW5lc3MtcHJvZmlsZXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZW50aXR5LW5hbWUge1xyXG4gIGZvbnQtc3R5bGU6IGl0YWxpYztcclxuICBmb250LXNpemU6IDE0cHg7XHJcbiAgcGFkZGluZy1sZWZ0OiAxNnB4O1xyXG5cclxuICAmOmhvdmVyOm5vdCguZW50aXR5LW5hbWUtLWRpc2FibGVkKSB7XHJcbiAgICBjdXJzb3I6IHBvaW50ZXI7XHJcbiAgICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-backoffice/src/lib/features/admin-business-profiles/containers/admin-business-profiles.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,0BAAA;AACJ;AACA,goBAAgoB","sourcesContent":[".entity-name {\r\n  font-style: italic;\r\n  font-size: 14px;\r\n  padding-left: 16px;\r\n\r\n  &:hover:not(.entity-name--disabled) {\r\n    cursor: pointer;\r\n    text-decoration: underline;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
