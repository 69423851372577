import { BaseUser, BusinessProfile, PlatformResponse, UserAccount } from '@iot-platform/models/common';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const listUsersSuccess = createAction('[Users API] List Users Success', props<{ response: PlatformResponse }>());
export const listUsersFailure = createAction('[Users API] List Users Failure', props<{ error: unknown }>());

export const addUserSuccess = createAction('[Users API] Add User Success', props<{ addedUser: BaseUser }>());
export const addUserFailure = createAction('[[Users API] Add User Failure', props<{ error: unknown }>());

export const activateUserSuccess = createAction('[Users API] Activate User Success', props<{ updatedUser: Update<BaseUser> }>());
export const activateUserFailure = createAction('[Users API] Activate User Failure', props<{ error: unknown }>());

export const disableUserSuccess = createAction('[Users API] Disable User Success', props<{ updatedUser: Update<BaseUser> }>());
export const disableUserFailure = createAction('[Users API] Disable User Failure', props<{ error: unknown }>());

export const resetUserSuccess = createAction('[Users API] Reset User Success', props<{ updatedUser: Update<BaseUser> }>());
export const resetUserFailure = createAction('[Users API] Reset User Failure', props<{ error: unknown }>());

export const updateUserSuccess = createAction('[Users API] Update User Success', props<{ updatedUser: Update<BaseUser> }>());
export const updateUserFailure = createAction('[Users API] Update User Failure', props<{ error: unknown }>());

export const deleteUserSuccess = createAction('[Users API] Delete User Success', props<{ deletedUserId: string }>());
export const deleteUserFailure = createAction('[Users API] Delete User Failure', props<{ error: unknown }>());

export const selectUserSuccess = createAction('[Users API] Select User Success', props<{ selectedUser: UserAccount }>());
export const selectUserFailure = createAction('[[Users API] Select User Failure', props<{ error: unknown }>());

export const filterUsersByStatusSuccess = createAction('[Users/API] Filter Users By Status Success', props<{ response: PlatformResponse }>());
export const filterUsersByStatusFailure = createAction('[Users/API] Filter Users By Status Failure', props<{ error: unknown }>());

export const getBusinessProfilesByUserIdSuccess = createAction(
  '[Users/Admin Page] GET Business Profiles By User Id Success',
  props<{ businessProfiles: BusinessProfile[] }>()
);
export const getBusinessProfilesByUserIdFailure = createAction('[Users/Admin Page] GET Business Profiles By User Id Failure', props<{ error: unknown }>());

export const getVisibleEntityIdsSuccess = createAction('[Users/API] Get Visible Entity Ids Success', props<{ entityIds: string[] }>());
export const getVisibleEntityIdsFailure = createAction('[Users/API] Get Visible Entity Ids Failure', props<{ error: unknown }>());
