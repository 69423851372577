import { BaseUser, BusinessProfile, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminUsersApiActions, AdminUsersPageActions } from '../actions';

export const adminUsersApiFeatureKey = 'adminUsersApi';

export interface State extends EntityState<BaseUser> {
  selectedUserId: string | null;
  businessProfiles: BusinessProfile[] | null;
  pagination: Pagination;
  visibleEntityIds: string[];
}

export const adapter: EntityAdapter<BaseUser> = createEntityAdapter<BaseUser>({
  selectId: (user: BaseUser) => user.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedUserId: null,
  businessProfiles: null,
  pagination: { total: 0, hasMore: false, maxPage: 0, currentPage: 0, limit: 100 },
  visibleEntityIds: []
});

export const reducer = createReducer(
  initialState,
  on(AdminUsersPageActions.listUsers, (state: State, { request }) => ({
    ...state,
    pagination: { ...state.pagination, currentPage: request.page }
  })),
  on(AdminUsersApiActions.listUsersSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: { total: response.total, hasMore: response.hasMore, maxPage: response.maxPage, currentPage: response.currentPage, limit: response.limit }
    })
  ),
  on(AdminUsersApiActions.listUsersFailure, (state: State, { error }) => ({ ...state, error })),
  on(AdminUsersPageActions.selectUser, (state: State, { selectedUserId }) => ({ ...state, selectedUserId })),
  on(AdminUsersApiActions.selectUserSuccess, (state: State, { selectedUser }) =>
    adapter.upsertOne(selectedUser as BaseUser, { ...state, selectedUserId: selectedUser.id })
  ),
  on(AdminUsersApiActions.addUserSuccess, (state: State, { addedUser }) => adapter.addOne(addedUser, state)),
  on(AdminUsersApiActions.updateUserSuccess, (state: State, { updatedUser }) => adapter.updateOne(updatedUser, state)),
  on(AdminUsersApiActions.activateUserSuccess, (state: State, { updatedUser }) => adapter.updateOne(updatedUser, state)),
  on(AdminUsersApiActions.disableUserSuccess, (state: State, { updatedUser }) => adapter.updateOne(updatedUser, state)),
  on(AdminUsersApiActions.resetUserSuccess, (state: State, { updatedUser }) => adapter.updateOne(updatedUser, state)),
  on(AdminUsersApiActions.deleteUserSuccess, (state: State, { deletedUserId }) => adapter.removeOne(deletedUserId, state)),

  on(AdminUsersPageActions.filterUsersByStatus, (state: State) => adapter.removeAll(state)),
  on(AdminUsersApiActions.filterUsersByStatusSuccess, (state: State, { response }) => adapter.setAll(response.data, { ...state, response })),

  on(AdminUsersPageActions.getBusinessProfilesByUserId, (state: State) => ({ ...state, error: null, pending: true, businessProfiles: [] })),
  on(AdminUsersApiActions.getBusinessProfilesByUserIdSuccess, (state: State, { businessProfiles }) => ({ ...state, businessProfiles: [...businessProfiles] })),

  on(AdminUsersPageActions.getVisibleEntityIds, (state: State) => ({ ...state, error: null, visibleEntityIds: [] })),
  on(AdminUsersApiActions.getVisibleEntityIdsSuccess, (state: State, { entityIds }) => ({ ...state, visibleEntityIds: [...entityIds] })),
  on(AdminUsersApiActions.getVisibleEntityIdsFailure, (state: State, { error }) => ({ ...state, error }))
);

export const getSelectedId = (state: State) => state.selectedUserId;
export const getBusinessProfiles = (state: State) => state.businessProfiles;
export const getPagination = (state: State) => state.pagination;
export const getVisibleEntityIds = (state: State) => state.visibleEntityIds;
