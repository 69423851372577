// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.score-cell {
  background-color: rgba(14, 194, 84, 0.8);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.address-accuracy-cell-value {
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  color: #394c5a;
  padding-right: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZHJlc3MtYWNjdXJhY3ktY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHdDQUFBO0VBQ0EsV0FBQTtFQUNBLFlBQUE7RUFDQSxTQUFBO0VBQ0EsVUFBQTtBQUNGOztBQUVBO0VBQ0UsY0FBQTtFQUNBLGVBQUE7RUFDQSxnQkFBQTtFQUNBLG9DQUFBO0VBQ0EsZ0JBQUE7RUFDQSxxQkFBQTtFQUNBLHVCQUFBO0VBQ0EsY0FBQTtFQUNBLG1CQUFBO0FBQ0YiLCJmaWxlIjoiYWRkcmVzcy1hY2N1cmFjeS1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNjb3JlLWNlbGwge1xyXG4gIGJhY2tncm91bmQtY29sb3I6ICMwZWMyNTRjYztcclxuICB3aWR0aDogMTAwJTtcclxuICBoZWlnaHQ6IDEwMCU7XHJcbiAgbWFyZ2luOiAwO1xyXG4gIHBhZGRpbmc6IDA7XHJcbn1cclxuXHJcbi5hZGRyZXNzLWFjY3VyYWN5LWNlbGwtdmFsdWUge1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIGZvbnQtZmFtaWx5OiAnT3BlbiBTYW5zJywgc2Fucy1zZXJpZjtcclxuICBvdmVyZmxvdzogaGlkZGVuO1xyXG4gIHdvcmQtYnJlYWs6IGJyZWFrLWFsbDtcclxuICB0ZXh0LW92ZXJmbG93OiBlbGxpcHNpcztcclxuICBjb2xvcjogIzM5NGM1YTtcclxuICBwYWRkaW5nLXJpZ2h0OiAxMHB4O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/address-accuracy-cell/address-accuracy-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,cAAA;EACA,mBAAA;AACF;AACA,ogCAAogC","sourcesContent":[".score-cell {\r\n  background-color: #0ec254cc;\r\n  width: 100%;\r\n  height: 100%;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.address-accuracy-cell-value {\r\n  display: block;\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n  font-family: 'Open Sans', sans-serif;\r\n  overflow: hidden;\r\n  word-break: break-all;\r\n  text-overflow: ellipsis;\r\n  color: #394c5a;\r\n  padding-right: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
