// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-header-icon {
  font-size: 20px;
  margin-top: 6px;
}

.chat-header-close-button.mat-mdc-icon-button {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXQtaGVhZGVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBQTtFQUNBLGVBQUE7QUFDRjs7QUFFQTtFQUNFLFlBQUE7RUFDQSxXQUFBO0VBQ0EsaUJBQUE7QUFDRiIsImZpbGUiOiJjaGF0LWhlYWRlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jaGF0LWhlYWRlci1pY29uIHtcclxuICBmb250LXNpemU6IDIwcHg7XHJcbiAgbWFyZ2luLXRvcDogNnB4O1xyXG59XHJcblxyXG4uY2hhdC1oZWFkZXItY2xvc2UtYnV0dG9uLm1hdC1tZGMtaWNvbi1idXR0b24ge1xyXG4gIGhlaWdodDogMzBweDtcclxuICB3aWR0aDogMzBweDtcclxuICBsaW5lLWhlaWdodDogMzBweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/chat/chat-header/chat-header.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AACF;AACA,4kBAA4kB","sourcesContent":[".chat-header-icon {\r\n  font-size: 20px;\r\n  margin-top: 6px;\r\n}\r\n\r\n.chat-header-close-button.mat-mdc-icon-button {\r\n  height: 30px;\r\n  width: 30px;\r\n  line-height: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
