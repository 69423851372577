// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unsubscribed {
  filter: invert(88%) sepia(7%) saturate(14%) hue-rotate(316deg) brightness(94%) contrast(87%);
}

.subscribed {
  filter: invert(62%) sepia(43%) saturate(514%) hue-rotate(54deg) brightness(91%) contrast(87%);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1YnNjcmlwdGlvbi1idXR0b24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSw0RkFBQTtBQUNGOztBQUVBO0VBQ0UsNkZBQUE7QUFDRiIsImZpbGUiOiJzdWJzY3JpcHRpb24tYnV0dG9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnVuc3Vic2NyaWJlZCB7XHJcbiAgZmlsdGVyOiBpbnZlcnQoODglKSBzZXBpYSg3JSkgc2F0dXJhdGUoMTQlKSBodWUtcm90YXRlKDMxNmRlZykgYnJpZ2h0bmVzcyg5NCUpIGNvbnRyYXN0KDg3JSk7XHJcbn1cclxuXHJcbi5zdWJzY3JpYmVkIHtcclxuICBmaWx0ZXI6IGludmVydCg2MiUpIHNlcGlhKDQzJSkgc2F0dXJhdGUoNTE0JSkgaHVlLXJvdGF0ZSg1NGRlZykgYnJpZ2h0bmVzcyg5MSUpIGNvbnRyYXN0KDg3JSk7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/subscription-button/subscription-button.component.scss"],"names":[],"mappings":"AAAA;EACE,4FAAA;AACF;;AAEA;EACE,6FAAA;AACF;AACA,ooBAAooB","sourcesContent":[".unsubscribed {\r\n  filter: invert(88%) sepia(7%) saturate(14%) hue-rotate(316deg) brightness(94%) contrast(87%);\r\n}\r\n\r\n.subscribed {\r\n  filter: invert(62%) sepia(43%) saturate(514%) hue-rotate(54deg) brightness(91%) contrast(87%);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
