// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const commonEnvironment = {
  production: false,
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_ohn2NJmLa',
    clientId: '4mve1fpj8nkdegjfsje58rj8rt'
  },
  api: {
    url: '/api/v1',
    url_v2: '/api/v2',
    key: '',
    endpoints: {
      sites: '/sites',
      gateways: '/gateways',
      synchronize: '/synchronize',
      receivers: '/forwarders',
      wards: '/wards',
      assets: '/assets',
      entities: '/entities',
      ack: '/ack',
      product: '/product',
      users: '/users',
      businessProfiles: '/business-profiles',
      favoriteViews: '/favorite-views',
      messages: '/messages',
      contacts: '/contacts',
      backendVersion: '/version',
      usersLightWeight: '/directory/users'
    },
    kerlinkUrl: 'https://wmc-poc.wanesy.com/#/login',
    customerAppAccessUrl: 'https://dev-oyansmarteservices.airliquide.com/login'
  },
  storage: {
    appPrefix: 'oyan'
  },
  apiKeys: {
    agGrid:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-072579}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ALIZENT_INTERNATIONAL}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_January_2027}____[v3]_[01]_MTc5OTcxMjAwMDAwMA==7e14622e3e03c3c787c4a2329ae238a3'
  }
};
