import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';

import { SharedModule } from '@iot-platform/shared';
import { MasterViewEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { GatewaysShellComponent } from './containers/gateways-shell/gateways-shell.component';
import { GatewaysRoutingModule } from './gateways-routing.module';
import { GatewaysEffects } from './state/effects/gateways.effects';
import * as fromGateways from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformUiModule,
    MasterViewEngineModule,
    GatewaysRoutingModule,
    StoreModule.forFeature(fromGateways.featureKey, fromGateways.reducers),
    EffectsModule.forFeature([GatewaysEffects])
  ],
  declarations: [GatewaysShellComponent]
})
export class GatewaysModule {}
