// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: inherit;
}

span {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  word-break: break-word;
  color: #394c5a;
  padding-left: 4px;
}

.header-icon {
  font-size: 20px;
  width: 20px;
  height: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImljb24taGVhZGVyLWNlbGwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0FBQ0Y7O0FBQ0E7RUFDRSxxQkFBQTtFQUNBLGVBQUE7RUFDQSxnQkFBQTtFQUNBLG9DQUFBO0VBQ0Esc0JBQUE7RUFDQSxjQUFBO0VBQ0EsaUJBQUE7QUFFRjs7QUFDQTtFQUNFLGVBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtBQUVGIiwiZmlsZSI6Imljb24taGVhZGVyLWNlbGwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XHJcbiAgd2lkdGg6IGluaGVyaXQ7XHJcbn1cclxuc3BhbiB7XHJcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIGZvbnQtZmFtaWx5OiAnT3BlbiBTYW5zJywgc2Fucy1zZXJpZjtcclxuICB3b3JkLWJyZWFrOiBicmVhay13b3JkO1xyXG4gIGNvbG9yOiAjMzk0YzVhO1xyXG4gIHBhZGRpbmctbGVmdDogNHB4O1xyXG59XHJcblxyXG4uaGVhZGVyLWljb24ge1xyXG4gIGZvbnQtc2l6ZTogMjBweDtcclxuICB3aWR0aDogMjBweDtcclxuICBoZWlnaHQ6IDIwcHg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/header-cells/icon-header-cell/icon-header-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AACA;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,sBAAA;EACA,cAAA;EACA,iBAAA;AAEF;;AACA;EACE,eAAA;EACA,WAAA;EACA,YAAA;AAEF;AACA,g3BAAg3B","sourcesContent":[":host {\r\n  width: inherit;\r\n}\r\nspan {\r\n  display: inline-block;\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n  font-family: 'Open Sans', sans-serif;\r\n  word-break: break-word;\r\n  color: #394c5a;\r\n  padding-left: 4px;\r\n}\r\n\r\n.header-icon {\r\n  font-size: 20px;\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
