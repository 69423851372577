import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, CommonCRUDService, CommonIndexedPagination } from '@iot-platform/models/common';
import { Receiver, Site, Ward } from '@iot-platform/models/oyan';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WardsService implements CommonCRUDService<Ward, CommonIndexedPagination> {
  constructor(
    @Inject('environment') private readonly environment,
    private readonly httpClient: HttpClient
  ) {}

  getAll(request?: CommonApiRequest): Observable<CommonApiResponse<Ward, CommonIndexedPagination>> {
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.wards}`, { params: ApiHelpers.getHttpParams(request) })
      .pipe(map((response: CommonApiListResponse<Ward>) => ApiHelpers.getHttpResponse<Ward>(response)));
  }

  getById(id: string): Observable<Ward> {
    return this.httpClient.get<Ward>(`${this.environment.api.url}${this.environment.api.endpoints.wards}/${id}`);
  }

  addOne(ward: Ward): Observable<Ward> {
    return this.httpClient.post<Ward>(`${this.environment.api.url}${this.environment.api.endpoints.wards}`, ward);
  }

  updateOne(ward: Ward): Observable<Ward> {
    return this.httpClient.put<Ward>(`${this.environment.api.url}${this.environment.api.endpoints.wards}/${ward.id}`, ward);
  }

  deleteOne(ward: Ward): Observable<Ward> {
    return this.httpClient.delete<Ward>(`${this.environment.api.url}${this.environment.api.endpoints.wards}/${ward.id}`);
  }

  addReceiverToWard(receiver: Receiver, ward: Ward, site: Site): Observable<Receiver> {
    const params: HttpParams = new HttpParams({ encoder: new CustomEncoder() }).set('entityId', site.entity.id);
    return this.httpClient.post<Receiver>(
      `${this.environment.api.url}${this.environment.api.endpoints.receivers}/${receiver.id}/ward/${ward.id}`,
      {},
      { params }
    );
  }

  removeReceiverFromWard(receiver: Receiver, ward: Ward, site: Site): Observable<Receiver> {
    const params: HttpParams = new HttpParams({ encoder: new CustomEncoder() }).set('entityId', site.entity.id);
    return this.httpClient.delete<Receiver>(`${this.environment.api.url}${this.environment.api.endpoints.receivers}/${receiver.id}/ward/${ward.id}`, {
      params
    });
  }
}
