// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dynamic-widget_chart {
  padding: 0.5rem;
}
.dynamic-widget_legend {
  text-align: center;
  padding: 0 0.5rem;
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR5bmFtaWMtd2lkZ2V0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsZUFBQTtBQUFKO0FBRUU7RUFDRSxrQkFBQTtFQUNBLGlCQUFBO0VBQ0EsZ0JBQUE7RUFDQSx5QkFBQTtFQUNBLHVCQUFBO0FBQUoiLCJmaWxlIjoiZHluYW1pYy13aWRnZXQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZHluYW1pYy13aWRnZXQge1xyXG4gICZfY2hhcnQge1xyXG4gICAgcGFkZGluZzogMC41cmVtO1xyXG4gIH1cclxuICAmX2xlZ2VuZCB7XHJcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbiAgICBwYWRkaW5nOiAwIDAuNXJlbTtcclxuICAgIG92ZXJmbG93OiBoaWRkZW47XHJcbiAgICB3aGl0ZS1zcGFjZTogYnJlYWstc3BhY2VzO1xyXG4gICAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/widgets/components/dynamic-widget/dynamic-widget.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEE;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,uBAAA;AAAJ;AACA,oqBAAoqB","sourcesContent":[".dynamic-widget {\r\n  &_chart {\r\n    padding: 0.5rem;\r\n  }\r\n  &_legend {\r\n    text-align: center;\r\n    padding: 0 0.5rem;\r\n    overflow: hidden;\r\n    white-space: break-spaces;\r\n    text-overflow: ellipsis;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
