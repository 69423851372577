// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-round-button-mv .mat-mdc-icon-button {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px;
}
.app-round-button-mv .mat-icon {
  font-size: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbGwtdG8tYWN0aW9uLWNlbGwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxzQkFBQTtFQUNBLHVCQUFBO0VBQ0EsaUJBQUE7QUFBSjtBQUdFO0VBQ0UsZUFBQTtBQURKIiwiZmlsZSI6ImNhbGwtdG8tYWN0aW9uLWNlbGwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYXBwLXJvdW5kLWJ1dHRvbi1tdiB7XHJcbiAgLm1hdC1tZGMtaWNvbi1idXR0b24ge1xyXG4gICAgd2lkdGg6IDI0cHggIWltcG9ydGFudDtcclxuICAgIGhlaWdodDogMjRweCAhaW1wb3J0YW50O1xyXG4gICAgbGluZS1oZWlnaHQ6IDI0cHg7XHJcbiAgfVxyXG5cclxuICAubWF0LWljb24ge1xyXG4gICAgZm9udC1zaXplOiAyMHB4O1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/call-to-action-cell/call-to-action-cell.component.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,uBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AACA,wmBAAwmB","sourcesContent":[".app-round-button-mv {\r\n  .mat-mdc-icon-button {\r\n    width: 24px !important;\r\n    height: 24px !important;\r\n    line-height: 24px;\r\n  }\r\n\r\n  .mat-icon {\r\n    font-size: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
