import { Injectable } from '@angular/core';
import { AdminBusinessProfilesFacade as BusinessProfilesPlatformFacade } from '@iot-platform/iot4bos/ui/backoffice';
import { BaseFacade, BaseUser, BusinessProfile, Filter, Pagination } from '@iot-platform/models/common';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AdminBusinessProfilesActions } from '../actions';
import { AdminBusinessProfilesSelectors } from '../selectors/admin-business-profiles.selectors';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesFacade extends BaseFacade<BusinessProfile, Pagination, Filter> {
  allBusinessProfilesEntities$: Observable<Dictionary<BusinessProfile>> = this.businessProfilesPlatformFacade.entities$;
  allBusinessProfiles$: Observable<BusinessProfile[]> = this.businessProfilesPlatformFacade.all$;

  userBusinessProfiles$: Observable<BusinessProfile[]> = this.store.select(this.selector.selectAll);
  selectedBusinessProfile$: Observable<BusinessProfile> = this.store.select(this.selector.selectSelectedBusinessProfile);

  constructor(
    protected store: Store,
    protected selector: AdminBusinessProfilesSelectors,
    protected businessProfilesPlatformFacade: BusinessProfilesPlatformFacade
  ) {
    super(store, selector);
  }

  getAll(): void {
    this.store.dispatch(AdminBusinessProfilesActions.listBusinessProfiles());
  }

  setFilters(filters: Filter[]): void {
    this.filters$ = of(filters);
  }

  unlinkBusinessProfileFromMember(businessProfileId: string, user: BaseUser) {
    this.store.dispatch(
      AdminBusinessProfilesActions.removeMemberFromBusinessProfile({
        businessProfileId,
        memberToRemove: user
      })
    );
  }

  linkBusinessProfileToMember(businessProfileId: string, user: BaseUser) {
    this.store.dispatch(
      AdminBusinessProfilesActions.addMemberToBusinessProfile({
        businessProfileId,
        memberToAdd: user
      })
    );
  }
}
