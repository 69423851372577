import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import * as fromUsersApi from './admin-users-api.reducer';
import * as fromUsersPage from './admin-users-page.reducer';
import * as fromAdminBusinessProfiles from './admin-business-profiles.reducer';
import { BaseUser, Pagination, PlatformResponse, UserAccount } from '@iot-platform/models/common';

export const featureKey = 'oyanAdminBusinessProfiles';

export interface AdminState {
  [fromUsersPage.adminUsersPageFeatureKey]: fromUsersPage.State;
  [fromUsersApi.adminUsersApiFeatureKey]: fromUsersApi.State;
  [fromAdminBusinessProfiles.featureKey]: fromAdminBusinessProfiles.State;
}

export interface State {
  [featureKey]: AdminState;
}

export function reducers(state: AdminState, action: Action): ActionReducer<AdminState> {
  return combineReducers({
    [fromUsersPage.adminUsersPageFeatureKey]: fromUsersPage.reducer,
    [fromUsersApi.adminUsersApiFeatureKey]: fromUsersApi.reducer,
    [fromAdminBusinessProfiles.featureKey]: fromAdminBusinessProfiles.reducer
  })(state, action) as unknown as ActionReducer<AdminState>;
}

export const selectUsersState = createFeatureSelector<AdminState>(featureKey);

export const selectUsersPageState = createSelector(selectUsersState, (state: AdminState) => state[fromUsersPage.adminUsersPageFeatureKey]);

export const selectUsersApiState = createSelector(selectUsersState, (state: AdminState) => state[fromUsersApi.adminUsersApiFeatureKey]);

export const {
  selectIds: getUsersIds,
  selectEntities: getUsersEntities,
  selectAll: getAllUsers,
  selectTotal: getTotalUsers
} = fromUsersApi.adapter.getSelectors(selectUsersApiState);

export const getSelectedUserId = createSelector(selectUsersApiState, fromUsersApi.getSelectedId);

export const getSelectedUser = createSelector(getUsersEntities, getSelectedUserId, (entities, selectedId) => {
  if (entities && selectedId) {
    return selectedId && (entities[selectedId] as UserAccount);
  } else {
    return null;
  }
});

export const getBusinessProfiles = createSelector(selectUsersApiState, fromUsersApi.getBusinessProfiles);

export const getUsersPageError = createSelector(selectUsersPageState, fromUsersPage.getError);

export const getUsersPagePending = createSelector(selectUsersPageState, fromUsersPage.getPending);

export const getCurrentFilters = createSelector(selectUsersPageState, fromUsersPage.getCurrentFilters);

export const getPagination = createSelector(selectUsersApiState, fromUsersApi.getPagination);

export const getTotal = createSelector(getPagination, (pagination: Pagination) => pagination.total);

export const selectVisibleEntityIds = createSelector(selectUsersApiState, fromUsersApi.getVisibleEntityIds);

export const getUsersAsPlatformResponse = createSelector(getAllUsers, getPagination, selectVisibleEntityIds, (users: BaseUser[], pagination: Pagination, _) => {
  const response: PlatformResponse = {
    currentPage: pagination.currentPage,
    maxPage: pagination.maxPage,
    hasMore: pagination.hasMore,
    total: pagination.total,
    limit: pagination.limit,
    data: users
  };
  return response;
});
