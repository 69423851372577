// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-icon-content {
  vertical-align: middle;
}
.table-icon-content.icon-delay {
  height: 20px;
  width: 20px;
  line-height: 20px;
}

.delay-true {
  color: #bf5f69;
}

.delay-false {
  color: #cdcdcd;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR5bmFtaWMtaWNvbi1jZWxsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usc0JBQUE7QUFDRjtBQUNFO0VBQ0UsWUFBQTtFQUNBLFdBQUE7RUFDQSxpQkFBQTtBQUNKOztBQUdBO0VBQ0UsY0FBQTtBQUFGOztBQUdBO0VBQ0UsY0FBQTtBQUFGIiwiZmlsZSI6ImR5bmFtaWMtaWNvbi1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRhYmxlLWljb24tY29udGVudCB7XHJcbiAgdmVydGljYWwtYWxpZ246IG1pZGRsZTtcclxuXHJcbiAgJi5pY29uLWRlbGF5IHtcclxuICAgIGhlaWdodDogMjBweDtcclxuICAgIHdpZHRoOiAyMHB4O1xyXG4gICAgbGluZS1oZWlnaHQ6IDIwcHg7XHJcbiAgfVxyXG59XHJcblxyXG4uZGVsYXktdHJ1ZSB7XHJcbiAgY29sb3I6ICNiZjVmNjk7XHJcbn1cclxuXHJcbi5kZWxheS1mYWxzZSB7XHJcbiAgY29sb3I6ICNjZGNkY2Q7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/dynamic-icon-cell/dynamic-icon-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AACJ;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,cAAA;AAAF;AACA,otBAAotB","sourcesContent":[".table-icon-content {\r\n  vertical-align: middle;\r\n\r\n  &.icon-delay {\r\n    height: 20px;\r\n    width: 20px;\r\n    line-height: 20px;\r\n  }\r\n}\r\n\r\n.delay-true {\r\n  color: #bf5f69;\r\n}\r\n\r\n.delay-false {\r\n  color: #cdcdcd;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
