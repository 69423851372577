import { Action, combineReducers } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as fromReceivers from './receivers.reducer';

export const featureKey = 'oyanReceivers';

export interface ReceiversState {
  [fromReceivers.featureKey]: fromReceivers.State;
}

export interface State {
  [featureKey]: ReceiversState;
}

export function reducers(state: ReceiversState, action: Action): ActionReducer<ReceiversState> {
  return combineReducers({
    [fromReceivers.featureKey]: fromReceivers.reducer
  })(state, action) as unknown as ActionReducer<ReceiversState>;
}
