import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { CardEvent, CardEventType } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'oyan-ui-site-products',
    templateUrl: './site-products.component.html',
    styleUrls: ['./site-products.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SiteProductsComponent implements OnChanges {
  @Input() products: Product[];
  @Input() productsBySite: Product[];
  @Input() loading: boolean;
  @Input() canUpdateSite: boolean;
  @Input() canAddProduct: boolean;
  @Input() canDeleteProduct: boolean;

  @Output() dispatchEvent: EventEmitter<CardEvent> = new EventEmitter();

  horizontalLayout = true;
  filteredProducts$: BehaviorSubject<Product[]> = new BehaviorSubject([]);

  get hasProducts(): boolean {
    return this.products && !!this.products.length;
  }

  get hasAssignedProducts(): boolean {
    return this.productsBySite && !!this.productsBySite.length;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.products && changes.products.currentValue) {
      this.filteredProducts$.next(changes.products.currentValue);
    }
  }

  onApplyFilter(searchTerm: string): void {
    const filteredProducts = this.products.filter(
      (p: Product) =>
        `${GetUtils.get(p, 'name', '')}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${GetUtils.get(p, 'displayName', '')}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${GetUtils.get(p, 'type', '')}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.filteredProducts$.next(filteredProducts);
  }

  onClearFilter(): void {
    this.filteredProducts$.next([...this.products]);
  }

  onDispatchEvent(event: CardEvent): void {
    this.dispatchEvent.emit(event);
  }

  onAddProduct(product: Product): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.ADD_PRODUCT,
      data: product
    });
  }
}
