// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-footer-input {
  font-size: 12px !important;
}

.chat-footer-button.mat-mdc-icon-button {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXQtZm9vdGVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsMEJBQUE7QUFDRjs7QUFFQTtFQUNFLFlBQUE7RUFDQSxXQUFBO0VBQ0EsaUJBQUE7QUFDRiIsImZpbGUiOiJjaGF0LWZvb3Rlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jaGF0LWZvb3Rlci1pbnB1dCB7XHJcbiAgZm9udC1zaXplOiAxMnB4ICFpbXBvcnRhbnQ7XHJcbn1cclxuXHJcbi5jaGF0LWZvb3Rlci1idXR0b24ubWF0LW1kYy1pY29uLWJ1dHRvbiB7XHJcbiAgaGVpZ2h0OiAzMHB4O1xyXG4gIHdpZHRoOiAzMHB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAzMHB4O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/chat/chat-footer/chat-footer.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AACF;AACA,4iBAA4iB","sourcesContent":[".chat-footer-input {\r\n  font-size: 12px !important;\r\n}\r\n\r\n.chat-footer-button.mat-mdc-icon-button {\r\n  height: 30px;\r\n  width: 30px;\r\n  line-height: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
