// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-cell {
  font-size: 12px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFycmF5LW9mLW9iamVjdHMtY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxxQkFBQTtFQUNBLDRCQUFBO0FBQ0YiLCJmaWxlIjoiYXJyYXktb2Ytb2JqZWN0cy1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmJhc2ljLWNlbGwge1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICAtd2Via2l0LWxpbmUtY2xhbXA6IDE7XHJcbiAgLXdlYmtpdC1ib3gtb3JpZW50OiB2ZXJ0aWNhbDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/array-of-objects-cell/array-of-objects-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,qBAAA;EACA,4BAAA;AACF;AACA,4cAA4c","sourcesContent":[".basic-cell {\r\n  font-size: 12px;\r\n  -webkit-line-clamp: 1;\r\n  -webkit-box-orient: vertical;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
