import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AssetsActions } from '../actions';

export const featureKey = 'assetsFeatureKey';

export type State = BaseState<Asset, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Asset> = createEntityAdapter<Asset>({
  selectId: (entity: Asset) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,

  on(
    AssetsActions.setFilters,
    (state: State, { filters }): State => ({
      ...state,
      filters: [...filters]
    })
  ),

  on(
    AssetsActions.loadAssets,
    (state: State, { request }): State => ({
      ...state,
      entity: null,
      filters: GetUtils.get(request, 'filters', [])
    })
  ),
  on(AssetsActions.loadAssetsSuccess, (state: State, { response }) => adapter.setAll(response.data, { ...state, pagination: response.pagination })),

  on(AssetsActions.loadAssetById, (state: State, { id }): State => ({ ...state, selectedId: id, entity: null })),
  on(AssetsActions.loadAssetByIdSuccess, (state: State, { response }): State => ({ ...state, selectedId: response.id, entity: response })),
  on(AssetsActions.loadAssetByIdFailure, (state: State): State => ({ ...state, selectedId: null, entity: null })),

  on(
    AssetsActions.loadAssets,
    AssetsActions.loadAssetById,
    (state: State): State => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),
  on(
    AssetsActions.loadAssetsSuccess,
    AssetsActions.loadAssetByIdSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    AssetsActions.loadAssetsFailure,
    AssetsActions.loadAssetByIdFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
