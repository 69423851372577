// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-option {
  font-size: 12px;
}

.timezone-autocomplete--field {
  width: 300px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpbWV6b25lLWF1dG9jb21wbGV0ZS1tdWx0aXBsZS1zZWxlY3RzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBQTtBQUNGOztBQUVBO0VBQ0UsWUFBQTtBQUNGIiwiZmlsZSI6InRpbWV6b25lLWF1dG9jb21wbGV0ZS1tdWx0aXBsZS1zZWxlY3RzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1hdC1tZGMtb3B0aW9uIHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbn1cclxuXHJcbi50aW1lem9uZS1hdXRvY29tcGxldGUtLWZpZWxkIHtcclxuICB3aWR0aDogMzAwcHg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/timezone-autocomplete-multiple-selects/timezone-autocomplete-multiple-selects.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,YAAA;AACF;AACA,4fAA4f","sourcesContent":[".mat-mdc-option {\r\n  font-size: 12px;\r\n}\r\n\r\n.timezone-autocomplete--field {\r\n  width: 300px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
