// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.concept-name {
  font-size: 12px;
  height: 30px;
}
.concept-name button .mat-icon {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
}

.tag-category {
  padding: 6px 40px 6px 0;
  font-size: 12px;
}

.tag-category-name {
  padding-top: 4px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRhZy1jYXRlZ29yeS1saXN0LWJ5LWNvbmNlcHQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsWUFBQTtBQUNGO0FBQ0U7RUFDRSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtBQUNKOztBQUdBO0VBQ0UsdUJBQUE7RUFDQSxlQUFBO0FBQUY7O0FBR0E7RUFDRSxnQkFBQTtBQUFGIiwiZmlsZSI6InRhZy1jYXRlZ29yeS1saXN0LWJ5LWNvbmNlcHQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29uY2VwdC1uYW1lIHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbiAgaGVpZ2h0OiAzMHB4O1xyXG5cclxuICBidXR0b24gLm1hdC1pY29uIHtcclxuICAgIGZvbnQtc2l6ZTogMThweDtcclxuICAgIGxpbmUtaGVpZ2h0OiAxOHB4O1xyXG4gICAgaGVpZ2h0OiAxOHB4O1xyXG4gICAgd2lkdGg6IDE4cHg7XHJcbiAgfVxyXG59XHJcblxyXG4udGFnLWNhdGVnb3J5IHtcclxuICBwYWRkaW5nOiA2cHggNDBweCA2cHggMDtcclxuICBmb250LXNpemU6IDEycHg7XHJcbn1cclxuXHJcbi50YWctY2F0ZWdvcnktbmFtZSB7XHJcbiAgcGFkZGluZy10b3A6IDRweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/tags/tag-category-list-by-concept/tag-category-list-by-concept.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAGA;EACE,uBAAA;EACA,eAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;AACA,g4BAAg4B","sourcesContent":[".concept-name {\r\n  font-size: 12px;\r\n  height: 30px;\r\n\r\n  button .mat-icon {\r\n    font-size: 18px;\r\n    line-height: 18px;\r\n    height: 18px;\r\n    width: 18px;\r\n  }\r\n}\r\n\r\n.tag-category {\r\n  padding: 6px 40px 6px 0;\r\n  font-size: 12px;\r\n}\r\n\r\n.tag-category-name {\r\n  padding-top: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
