import { NgModule } from '@angular/core';
import { ContactFormModule, DetailPopupCardsModule, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { InfoDisplayPipe } from '@iot-platform/pipes';

import { SharedModule } from '@iot-platform/shared';
import { FavoriteViewsModule } from '@iot-platform/shared/components';
import { MasterViewEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { CardsModule } from '../../components/cards/cards.module';
import { MatMenuListModule } from '../../components/mat-menu-list/mat-menu-list.module';
import { OyanInfoDisplayModule } from '../../containers';
import { LinkReceiversFormDialogComponent } from './components/link-receivers-form-dialog/link-receivers-form-dialog.component';
import { SiteCardComponent } from './components/site-card/site-card.component';
import { SiteConfigurationsComponent } from './components/site-configurations/site-configurations.component';
import { SiteContactsComponent } from './components/site-contacts/site-contacts.component';
import { SiteGatewaysComponent } from './components/site-gateways/site-gateways.component';
import { SiteInfoFormComponent } from './components/site-info-form/site-info-form.component';
import { SiteProductsComponent } from './components/site-products/site-products.component';
import { SiteStocksComponent } from './components/site-stocks/site-stocks.component';
import { SiteOverviewComponent } from './containers/site-overview/site-overview.component';
import { SitesShellComponent } from './containers/sites-shell/sites-shell.component';
import { SitesRoutingModule } from './sites-routing.module';
import { SitesEffects } from './state/effects/sites.effects';
import * as fromSites from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformUiModule,
    DetailPopupCardsModule,
    FavoriteViewsModule,
    MasterViewEngineModule,
    OyanInfoDisplayModule,
    CardsModule,
    MatMenuListModule,
    ContactFormModule,
    StoreModule.forFeature(fromSites.featureKey, fromSites.reducers),
    EffectsModule.forFeature([SitesEffects]),
    SitesRoutingModule,
    InfoDisplayPipe
  ],
  declarations: [
    SitesShellComponent,
    SiteOverviewComponent,
    SiteCardComponent,
    SiteInfoFormComponent,
    SiteProductsComponent,
    SiteConfigurationsComponent,
    SiteGatewaysComponent,
    SiteStocksComponent,
    LinkReceiversFormDialogComponent,
    SiteContactsComponent
  ]
})
export class OyanUiSitesModule {}
