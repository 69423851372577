// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-platform-ui-toolbar-button .toggle-on {
  color: white;
  background-color: #4f78b7 !important;
}
.iot-platform-ui-toolbar-button .unsubscribed {
  filter: invert(88%) sepia(7%) saturate(14%) hue-rotate(316deg) brightness(94%) contrast(87%);
}
.iot-platform-ui-toolbar-button .subscribed {
  filter: invert(62%) sepia(43%) saturate(514%) hue-rotate(54deg) brightness(91%) contrast(87%);
}
.iot-platform-ui-toolbar-button__value-meta-icon {
  margin: 0 5px;
  font-size: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2xiYXItYnV0dG9uLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsWUFBQTtFQUNBLG9DQUFBO0FBQUo7QUFHRTtFQUNFLDRGQUFBO0FBREo7QUFJRTtFQUNFLDZGQUFBO0FBRko7QUFLRTtFQUNFLGFBQUE7RUFDQSxlQUFBO0FBSEoiLCJmaWxlIjoidG9vbGJhci1idXR0b24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaW90LXBsYXRmb3JtLXVpLXRvb2xiYXItYnV0dG9uIHtcclxuICAudG9nZ2xlLW9uIHtcclxuICAgIGNvbG9yOiB3aGl0ZTtcclxuICAgIGJhY2tncm91bmQtY29sb3I6ICM0Zjc4YjcgIWltcG9ydGFudDtcclxuICB9XHJcblxyXG4gIC51bnN1YnNjcmliZWQge1xyXG4gICAgZmlsdGVyOiBpbnZlcnQoODglKSBzZXBpYSg3JSkgc2F0dXJhdGUoMTQlKSBodWUtcm90YXRlKDMxNmRlZykgYnJpZ2h0bmVzcyg5NCUpIGNvbnRyYXN0KDg3JSk7XHJcbiAgfVxyXG5cclxuICAuc3Vic2NyaWJlZCB7XHJcbiAgICBmaWx0ZXI6IGludmVydCg2MiUpIHNlcGlhKDQzJSkgc2F0dXJhdGUoNTE0JSkgaHVlLXJvdGF0ZSg1NGRlZykgYnJpZ2h0bmVzcyg5MSUpIGNvbnRyYXN0KDg3JSk7XHJcbiAgfVxyXG5cclxuICAmX192YWx1ZS1tZXRhLWljb24ge1xyXG4gICAgbWFyZ2luOiAwIDVweDtcclxuICAgIGZvbnQtc2l6ZTogMjBweDtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/toolbar-v2/toolbar-button/toolbar-button.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,oCAAA;AAAJ;AAGE;EACE,4FAAA;AADJ;AAIE;EACE,6FAAA;AAFJ;AAKE;EACE,aAAA;EACA,eAAA;AAHJ;AACA,4/BAA4/B","sourcesContent":[".iot-platform-ui-toolbar-button {\r\n  .toggle-on {\r\n    color: white;\r\n    background-color: #4f78b7 !important;\r\n  }\r\n\r\n  .unsubscribed {\r\n    filter: invert(88%) sepia(7%) saturate(14%) hue-rotate(316deg) brightness(94%) contrast(87%);\r\n  }\r\n\r\n  .subscribed {\r\n    filter: invert(62%) sepia(43%) saturate(514%) hue-rotate(54deg) brightness(91%) contrast(87%);\r\n  }\r\n\r\n  &__value-meta-icon {\r\n    margin: 0 5px;\r\n    font-size: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
