// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-info-container {
  font-size: 12px;
}

.user-profile-info-section-title {
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  padding-bottom: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItcHJvZmlsZS1wcmVmZXJlbmNlcy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7QUFDRjs7QUFFQTtFQUNFLGVBQUE7RUFDQSxnQkFBQTtFQUNBLG1CQUFBO0VBQ0Esb0JBQUE7QUFDRiIsImZpbGUiOiJ1c2VyLXByb2ZpbGUtcHJlZmVyZW5jZXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudXNlci1wcm9maWxlLWluZm8tY29udGFpbmVyIHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbn1cclxuXHJcbi51c2VyLXByb2ZpbGUtaW5mby1zZWN0aW9uLXRpdGxlIHtcclxuICBmb250LXNpemU6IDE4cHg7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICB3aGl0ZS1zcGFjZTogbm93cmFwO1xyXG4gIHBhZGRpbmctYm90dG9tOiAyMHB4O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/oyan-ui/src/lib/features/admin/components/user-profile-preferences/user-profile-preferences.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;AACF;AACA,ooBAAooB","sourcesContent":[".user-profile-info-container {\r\n  font-size: 12px;\r\n}\r\n\r\n.user-profile-info-section-title {\r\n  font-size: 18px;\r\n  font-weight: 600;\r\n  white-space: nowrap;\r\n  padding-bottom: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
