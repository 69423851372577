// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex: 1 1 0;
}

.iot-platform-ui-display-value-card__content {
  padding: 16px;
  border-radius: 4px;
}
.iot-platform-ui-display-value-card__value {
  font-size: 24px;
  font-weight: 700;
}
.iot-platform-ui-display-value-card__value:hover {
  cursor: pointer;
}
.iot-platform-ui-display-value-card__unit {
  font-size: 14px;
}
.iot-platform-ui-display-value-card__value, .iot-platform-ui-display-value-card__unit {
  line-height: normal;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRpc3BsYXktdmFsdWUtY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFdBQUE7QUFDRjs7QUFHRTtFQUNFLGFBQUE7RUFDQSxrQkFBQTtBQUFKO0FBR0U7RUFDRSxlQUFBO0VBQ0EsZ0JBQUE7QUFESjtBQUdJO0VBQ0UsZUFBQTtBQUROO0FBS0U7RUFDRSxlQUFBO0FBSEo7QUFNRTtFQUVFLG1CQUFBO0FBTEoiLCJmaWxlIjoiZGlzcGxheS12YWx1ZS1jYXJkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xyXG4gIGZsZXg6IDEgMSAwO1xyXG59XHJcblxyXG4uaW90LXBsYXRmb3JtLXVpLWRpc3BsYXktdmFsdWUtY2FyZCB7XHJcbiAgJl9fY29udGVudCB7XHJcbiAgICBwYWRkaW5nOiAxNnB4O1xyXG4gICAgYm9yZGVyLXJhZGl1czogNHB4O1xyXG4gIH1cclxuXHJcbiAgJl9fdmFsdWUge1xyXG4gICAgZm9udC1zaXplOiAyNHB4O1xyXG4gICAgZm9udC13ZWlnaHQ6IDcwMDtcclxuXHJcbiAgICAmOmhvdmVyIHtcclxuICAgICAgY3Vyc29yOiBwb2ludGVyO1xyXG4gICAgfVxyXG4gIH1cclxuXHJcbiAgJl9fdW5pdCB7XHJcbiAgICBmb250LXNpemU6IDE0cHg7XHJcbiAgfVxyXG5cclxuICAmX192YWx1ZSxcclxuICAmX191bml0IHtcclxuICAgIGxpbmUtaGVpZ2h0OiBub3JtYWw7XHJcbiAgfVxyXG59Il19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/cards/display-value-card/display-value-card.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAGE;EACE,aAAA;EACA,kBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;AADJ;AAGI;EACE,eAAA;AADN;AAKE;EACE,eAAA;AAHJ;AAME;EAEE,mBAAA;AALJ;AACA,w9BAAw9B","sourcesContent":[":host {\r\n  flex: 1 1 0;\r\n}\r\n\r\n.iot-platform-ui-display-value-card {\r\n  &__content {\r\n    padding: 16px;\r\n    border-radius: 4px;\r\n  }\r\n\r\n  &__value {\r\n    font-size: 24px;\r\n    font-weight: 700;\r\n\r\n    &:hover {\r\n      cursor: pointer;\r\n    }\r\n  }\r\n\r\n  &__unit {\r\n    font-size: 14px;\r\n  }\r\n\r\n  &__value,\r\n  &__unit {\r\n    line-height: normal;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
