// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-card-list {
  padding: 10px;
  background-color: #e9eff6;
}

.detail-card-item {
  position: relative;
  padding-left: 110px;
  word-break: break-all;
  word-wrap: break-word;
}
.detail-card-item::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRldGFpbC1hc3NldC12YXJpYWJsZS1jYXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLHlCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxrQkFBQTtFQUNBLG1CQUFBO0VBQ0EscUJBQUE7RUFDQSxxQkFBQTtBQUNGO0FBQ0U7RUFDRSx5QkFBQTtFQUNBLGtCQUFBO0VBQ0EsT0FBQTtFQUNBLGdCQUFBO0FBQ0oiLCJmaWxlIjoiZGV0YWlsLWFzc2V0LXZhcmlhYmxlLWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZGV0YWlsLWNhcmQtbGlzdCB7XHJcbiAgcGFkZGluZzogMTBweDtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZTllZmY2O1xyXG59XHJcblxyXG4uZGV0YWlsLWNhcmQtaXRlbSB7XHJcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xyXG4gIHBhZGRpbmctbGVmdDogMTEwcHg7XHJcbiAgd29yZC1icmVhazogYnJlYWstYWxsO1xyXG4gIHdvcmQtd3JhcDogYnJlYWstd29yZDtcclxuXHJcbiAgJjo6YmVmb3JlIHtcclxuICAgIGNvbnRlbnQ6IGF0dHIoZGF0YS1sYWJlbCk7XHJcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgICBsZWZ0OiAwO1xyXG4gICAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/detail-popup-cards/detail-asset-variable-card/detail-asset-variable-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,qBAAA;EACA,qBAAA;AACF;AACE;EACE,yBAAA;EACA,kBAAA;EACA,OAAA;EACA,gBAAA;AACJ;AACA,45BAA45B","sourcesContent":[".detail-card-list {\r\n  padding: 10px;\r\n  background-color: #e9eff6;\r\n}\r\n\r\n.detail-card-item {\r\n  position: relative;\r\n  padding-left: 110px;\r\n  word-break: break-all;\r\n  word-wrap: break-word;\r\n\r\n  &::before {\r\n    content: attr(data-label);\r\n    position: absolute;\r\n    left: 0;\r\n    font-weight: 600;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
