// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-icon-content {
  line-height: 20px;
  vertical-align: middle;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvbGxvd2VkLW51bWJlci1jZWxsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsaUJBQUE7RUFDQSxzQkFBQTtBQUNGIiwiZmlsZSI6ImZvbGxvd2VkLW51bWJlci1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRhYmxlLWljb24tY29udGVudCB7XHJcbiAgbGluZS1oZWlnaHQ6IDIwcHg7XHJcbiAgdmVydGljYWwtYWxpZ246IG1pZGRsZTtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/followed-number-cell/followed-number-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,sBAAA;AACF;AACA,4ZAA4Z","sourcesContent":[".table-icon-content {\r\n  line-height: 20px;\r\n  vertical-align: middle;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
