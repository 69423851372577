// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
  word-break: break-word;
  color: #394c5a;
}
div::first-letter {
  text-transform: capitalize;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhc2ljLWhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxnQkFBQTtFQUNBLGlCQUFBO0VBQ0Esb0NBQUE7RUFDQSxzQkFBQTtFQUNBLGNBQUE7QUFDRjtBQUNFO0VBQ0UsMEJBQUE7QUFDSiIsImZpbGUiOiJiYXNpYy1oZWFkZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJkaXYge1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIGxpbmUtaGVpZ2h0OiAxNnB4O1xyXG4gIGZvbnQtZmFtaWx5OiAnT3BlbiBTYW5zJywgc2Fucy1zZXJpZjtcclxuICB3b3JkLWJyZWFrOiBicmVhay13b3JkO1xyXG4gIGNvbG9yOiAjMzk0YzVhO1xyXG5cclxuICAmOjpmaXJzdC1sZXR0ZXIge1xyXG4gICAgdGV4dC10cmFuc2Zvcm06IGNhcGl0YWxpemU7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/header-cells/basic-header/basic-header.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,oCAAA;EACA,sBAAA;EACA,cAAA;AACF;AACE;EACE,0BAAA;AACJ;AACA,orBAAorB","sourcesContent":["div {\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n  line-height: 16px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  word-break: break-word;\r\n  color: #394c5a;\r\n\r\n  &::first-letter {\r\n    text-transform: capitalize;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
