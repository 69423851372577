import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from '@iot-platform/iot-platform-ui';

import { InfoDisplayComponent } from './info-display.component';

@NgModule({
  imports: [CommonModule, RouterModule, MatSidenavModule, BreadcrumbModule, MatProgressSpinnerModule],
  declarations: [InfoDisplayComponent],
  exports: [InfoDisplayComponent]
})
export class OyanInfoDisplayModule {}
