import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardEvent, CardEventType, Contact } from '@iot-platform/models/common';

@Component({
    selector: 'oyan-ui-site-contacts',
    templateUrl: './site-contacts.component.html',
    styleUrls: ['./site-contacts.component.scss'],
    standalone: false
})
export class SiteContactsComponent {
  @Input() contacts: Contact[];
  @Input() loading: boolean;
  @Input() canUpdateSite: boolean;
  @Input() canUpdateContact: boolean;
  @Input() canAddContact: boolean;
  @Input() canDeleteContact: boolean;

  @Output() dispatchEvent: EventEmitter<CardEvent> = new EventEmitter();

  horizontalLayout = true;

  get hasContacts(): boolean {
    return this.contacts && !!this.contacts.length;
  }

  onDispatchEvent(event: CardEvent): void {
    this.dispatchEvent.emit(event);
  }

  onAddContact(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.ADD_CONTACT,
      data: null
    });
  }
}
