export enum OyanAuthorizationType {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum OyanAuthorizationConcept {
  ADMIN_PORTAL = 'ADMIN_PORTAL',
  ASSET = 'ASSET',
  BUSINESS_PROFILE = 'BUSINESS_PROFILE',
  CATALOG = 'CATALOG',
  ORGANIZATION_TREE = 'ORGANIZATION_TREE',
  PRODUCT = 'PRODUCT',
  PRODUCT_SETTINGS = 'PRODUCT_SETTINGS',
  SITE = 'SITE',
  USER = 'USER',
  USER_STATUS = 'USER_STATUS',
  WARD = 'WARD',
  GATEWAY = 'GATEWAY',
  FORWARDER = 'FORWARDER',
  MESSAGE = 'MESSAGE',
  TRANSLATION = 'TRANSLATION',
  CONTACT = 'CONTACT'
}
