import { Injectable } from '@angular/core';
import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Receiver } from '@iot-platform/models/oyan';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { transformApiRequest, transformApiResponse } from '../../../../helpers/apis-helpers';
import { ReceiversService } from '../../services/receivers.service';
import { ReceiversActions } from '../actions';

@Injectable()
export class ReceiversEffects {
  loadReceivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiversActions.loadReceivers),
      switchMap(({ request }) =>
        this.receiversService.getAll(transformApiRequest(request)).pipe(
          map((response: CommonApiResponse<Receiver, CommonIndexedPagination>) =>
            ReceiversActions.loadReceiversSuccess({ response: transformApiResponse<Receiver>(response) })
          ),
          catchError((error) => of(ReceiversActions.loadReceiversFailure({ error })))
        )
      )
    )
  );

  loadReceiverById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiversActions.loadReceiverById),
      switchMap((action) =>
        this.receiversService.getById(action.id).pipe(
          map((response: Receiver) => ReceiversActions.loadReceiverByIdSuccess({ response })),
          catchError((error) => of(ReceiversActions.loadReceiverByIdFailure({ error })))
        )
      )
    )
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReceiversActions.loadReceiversFailure, ReceiversActions.loadReceiverByIdFailure),
        tap((action) => this.notificationService.displayError(action.type))
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReceiversActions.loadReceivers, ReceiversActions.loadReceiverById),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ReceiversActions.loadReceiversSuccess,
          ReceiversActions.loadReceiversFailure,
          ReceiversActions.loadReceiverByIdSuccess,
          ReceiversActions.loadReceiverByIdFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly receiversService: ReceiversService
  ) {}
}
