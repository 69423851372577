// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.concept-container {
  max-height: 420px;
  overflow-y: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVkaXQtcm9sZS1kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxpQkFBQTtFQUNBLGdCQUFBO0FBQ0YiLCJmaWxlIjoiZWRpdC1yb2xlLWRpYWxvZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb25jZXB0LWNvbnRhaW5lciB7XHJcbiAgbWF4LWhlaWdodDogNDIwcHg7XHJcbiAgb3ZlcmZsb3cteTogYXV0bztcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-backoffice/src/lib/components/dialogs/edit-role-dialog/edit-role-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;AACA,wYAAwY","sourcesContent":[".concept-container {\r\n  max-height: 420px;\r\n  overflow-y: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
