// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-simple-card-value {
  display: block;
  padding: 10px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  color: #111b44;
  background-color: #e9eff6;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRldGFpbC1zaW1wbGUtY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7RUFDQSxhQUFBO0VBQ0Esa0JBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxvQ0FBQTtFQUNBLGNBQUE7RUFDQSx5QkFBQTtBQUNGIiwiZmlsZSI6ImRldGFpbC1zaW1wbGUtY2FyZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5kZXRhaWwtc2ltcGxlLWNhcmQtdmFsdWUge1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIHBhZGRpbmc6IDEwcHg7XHJcbiAgYm9yZGVyLXJhZGl1czogMnB4O1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBsaW5lLWhlaWdodDogMjBweDtcclxuICBmb250LWZhbWlseTogJ09wZW4gU2FucycsIHNhbnMtc2VyaWY7XHJcbiAgY29sb3I6ICMxMTFiNDQ7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogI2U5ZWZmNjtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/detail-popup-cards/detail-simple-card/detail-simple-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,cAAA;EACA,yBAAA;AACF;AACA,gsBAAgsB","sourcesContent":[".detail-simple-card-value {\r\n  display: block;\r\n  padding: 10px;\r\n  border-radius: 2px;\r\n  font-size: 12px;\r\n  line-height: 20px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  color: #111b44;\r\n  background-color: #e9eff6;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
