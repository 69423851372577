import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, CommonCRUDService, CommonIndexedPagination } from '@iot-platform/models/common';
import { Product, Site } from '@iot-platform/models/oyan';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsService implements CommonCRUDService<Product, CommonIndexedPagination> {
  constructor(
    @Inject('environment') private readonly environment,
    private readonly httpClient: HttpClient
  ) {}

  getAll(request?: CommonApiRequest): Observable<CommonApiResponse<Product, CommonIndexedPagination>> {
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.product}`, { params: ApiHelpers.getHttpParams(request) })
      .pipe(map((response: CommonApiListResponse<Product>) => ApiHelpers.getHttpResponse<Product>(response)));
  }

  getById(id: string): Observable<Product> {
    return this.httpClient.get<Product>(`${this.environment.api.url}${this.environment.api.endpoints.product}/${id}`);
  }

  addOne(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(`${this.environment.api.url}${this.environment.api.endpoints.product}`, product);
  }

  updateOne(product: Product): Observable<Product> {
    return this.httpClient.put<Product>(`${this.environment.api.url}${this.environment.api.endpoints.product}/${product.id}`, product);
  }

  deleteOne(product: Product): Observable<Product> {
    return this.httpClient.delete<Product>(`${this.environment.api.url}${this.environment.api.endpoints.product}/${product.id}`);
  }

  addProductToSite(product: Product, site: Site): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.environment.api.url}${this.environment.api.endpoints.product}/${product.id}/site/${site.id}`, {});
  }

  removeProductFromSite(product: Product, site: Site): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.environment.api.url}${this.environment.api.endpoints.product}/${product.id}/site/${site.id}`);
  }
}
