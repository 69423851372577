// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-platform-ui-phone-number-input__warning {
  position: absolute;
  margin-top: 0.4em;
  top: calc(100% - 0em);
}
.iot-platform-ui-phone-number-input__warning_chip {
  padding: 4px 12px;
  border-radius: 16px;
  background-color: rgba(242, 93, 32, 0.1);
  color: #f25d20;
  font-size: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBob25lLW51bWJlci1pbnB1dC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGtCQUFBO0VBQ0EsaUJBQUE7RUFDQSxxQkFBQTtBQUFKO0FBRUk7RUFDRSxpQkFBQTtFQUNBLG1CQUFBO0VBQ0Esd0NBQUE7RUFDQSxjQUFBO0VBQ0EsZUFBQTtBQUFOIiwiZmlsZSI6InBob25lLW51bWJlci1pbnB1dC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5pb3QtcGxhdGZvcm0tdWktcGhvbmUtbnVtYmVyLWlucHV0IHtcclxuICAmX193YXJuaW5nIHtcclxuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcclxuICAgIG1hcmdpbi10b3A6IDAuNGVtO1xyXG4gICAgdG9wOiBjYWxjKDEwMCUgLSAwZW0pO1xyXG5cclxuICAgICZfY2hpcCB7XHJcbiAgICAgIHBhZGRpbmc6IDRweCAxMnB4O1xyXG4gICAgICBib3JkZXItcmFkaXVzOiAxNnB4O1xyXG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKDI0MiwgOTMsIDMyLCAwLjEpO1xyXG4gICAgICBjb2xvcjogI2YyNWQyMDtcclxuICAgICAgZm9udC1zaXplOiAxMHB4O1xyXG4gICAgfVxyXG4gIH1cclxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/forms/controls/phone-number-input/phone-number-input.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,iBAAA;EACA,qBAAA;AAAJ;AAEI;EACE,iBAAA;EACA,mBAAA;EACA,wCAAA;EACA,cAAA;EACA,eAAA;AAAN;AACA,g1BAAg1B","sourcesContent":[".iot-platform-ui-phone-number-input {\r\n  &__warning {\r\n    position: absolute;\r\n    margin-top: 0.4em;\r\n    top: calc(100% - 0em);\r\n\r\n    &_chip {\r\n      padding: 4px 12px;\r\n      border-radius: 16px;\r\n      background-color: rgba(242, 93, 32, 0.1);\r\n      color: #f25d20;\r\n      font-size: 10px;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
