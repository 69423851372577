import { Site } from '@iot-platform/models/oyan';
import { createReducer, on } from '@ngrx/store';
import { ActiveElement } from '../../models/active-element.model';
import { NavigationsActions } from '../actions';

export const featureKey = 'navigations';

export interface State {
  activeElement: ActiveElement;
}

export const initialState: State = {
  activeElement: null
};

export const reducer = createReducer(
  initialState,
  on(NavigationsActions.setActiveElement, (state: State, { activeElement }): State => {
    if ((activeElement.type as string) === 'site') {
      const site: Site = activeElement.data as Site;
      activeElement.title = site.name;
      activeElement.label = site.entity.name;
      activeElement.icon = 'site';
    }
    return {
      ...state,
      activeElement
    };
  })
);
