import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Site } from '@iot-platform/models/oyan';

@Component({
    selector: 'oyan-ui-site-card',
    templateUrl: './site-card.component.html',
    styleUrls: ['./site-card.component.scss'],
    standalone: false
})
export class SiteCardComponent {
  @Input() canUpdate: boolean;
  @Input() canDelete: boolean;
  @Input() site: Site;
  @Input() loading: boolean;

  @Output() update: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();

  get imgPath(): string {
    return this.site && this.site.imageUrl ? this.site.imageUrl : 'assets/images/site/site_default.png';
  }
}
