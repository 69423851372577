import { Injectable } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AdminBusinessProfilesActions, AdminUsersApiActions } from '../actions';
import { AdminBusinessProfilesFacade } from '../facades/admin-business-profiles.facade';

@Injectable()
export class AdminBusinessProfilesEffects {
  setUserBusinessProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersApiActions.getBusinessProfilesByUserIdSuccess),
      map(({ businessProfiles }) => AdminBusinessProfilesActions.setUserBusinessProfiles({ businessProfiles }))
    )
  );

  setSelectedBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminBusinessProfilesActions.addMemberToBusinessProfile, AdminBusinessProfilesActions.removeMemberFromBusinessProfile),
      concatLatestFrom(() => this.adminBusinessProfilesFacade.allBusinessProfilesEntities$),
      mergeMap(([action, businessProfilesEntities]) =>
        of(AdminBusinessProfilesActions.setSelectedBusinessProfile({ businessProfile: businessProfilesEntities[action.businessProfileId] }))
      )
    )
  );

  linkBusinessProfileToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminBusinessProfilesActions.addMemberToBusinessProfileSuccess),
      concatLatestFrom(() => this.adminBusinessProfilesFacade.selectedBusinessProfile$),
      mergeMap(([action, businessProfile]) => of(AdminBusinessProfilesActions.linkBusinessProfileToUserSuccess({ businessProfile, user: action.addedMember })))
    )
  );

  unlinkBusinessProfileFromUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminBusinessProfilesActions.removeMemberFromBusinessProfileSuccess),
      concatLatestFrom(() => this.adminBusinessProfilesFacade.selectedBusinessProfile$),
      mergeMap(([action, businessProfile]) =>
        of(AdminBusinessProfilesActions.unlinkBusinessProfileFromUserSuccess({ businessProfile, user: action.removedMember }))
      )
    )
  );

  linkBusinessProfileToUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminBusinessProfilesActions.linkBusinessProfileToUserSuccess),
      map(({ user }) => {
        user.totalBusinessProfile++;
        return AdminUsersApiActions.updateUserSuccess({ updatedUser: { id: user.id, changes: user } });
      })
    )
  );

  unlinkBusinessProfileFromUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminBusinessProfilesActions.unlinkBusinessProfileFromUserSuccess),
      map(({ user }) => {
        user.totalBusinessProfile--;
        return AdminUsersApiActions.updateUserSuccess({ updatedUser: { id: user.id, changes: user } });
      })
    )
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminBusinessProfilesActions.addMemberToBusinessProfile,
          AdminBusinessProfilesActions.removeMemberFromBusinessProfile,
          AdminBusinessProfilesActions.listBusinessProfilesFailure
        ),
        tap(() => {
          this.notificationService.displayLoader(true);
        })
      ),
    { dispatch: false }
  );
  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminBusinessProfilesActions.addMemberToBusinessProfileSuccess,
          AdminBusinessProfilesActions.addMemberToBusinessProfileFailure,
          AdminBusinessProfilesActions.removeMemberFromBusinessProfileSuccess,
          AdminBusinessProfilesActions.removeMemberFromBusinessProfileFailure
        ),
        tap(() => {
          this.notificationService.displayLoader(false);
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminBusinessProfilesActions.addMemberToBusinessProfileSuccess, AdminBusinessProfilesActions.removeMemberFromBusinessProfileSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminBusinessProfilesActions.addMemberToBusinessProfileFailure, AdminBusinessProfilesActions.removeMemberFromBusinessProfileFailure),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly adminBusinessProfilesFacade: AdminBusinessProfilesFacade
  ) {}
}
