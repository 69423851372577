import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Receiver } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ReceiversActions } from '../actions';

export const featureKey = 'receiverFeatureKey';

export type State = BaseState<Receiver, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Receiver> = createEntityAdapter<Receiver>({
  selectId: (entity: Receiver) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,

  on(
    ReceiversActions.setFilters,
    (state: State, { filters }): State => ({
      ...state,
      filters: [...filters]
    })
  ),

  on(
    ReceiversActions.loadReceivers,
    (state: State, { request }): State => ({
      ...state,
      entity: null,
      filters: GetUtils.get(request, 'filters', [])
    })
  ),
  on(ReceiversActions.loadReceiversSuccess, (state: State, { response }) => adapter.setAll(response.data, { ...state, pagination: response.pagination })),

  on(ReceiversActions.loadReceiverById, (state: State, { id }): State => ({ ...state, selectedId: id, entity: null })),
  on(ReceiversActions.loadReceiverByIdSuccess, (state: State, { response }): State => ({ ...state, selectedId: response.id, entity: response })),
  on(ReceiversActions.loadReceiverByIdFailure, (state: State): State => ({ ...state, selectedId: null, entity: null })),

  on(
    ReceiversActions.loadReceivers,
    ReceiversActions.loadReceiverById,
    (state: State): State => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),
  on(
    ReceiversActions.loadReceiversSuccess,
    ReceiversActions.loadReceiverByIdSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    ReceiversActions.loadReceiversFailure,
    ReceiversActions.loadReceiverByIdFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
