import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TabNavBarModule } from '../../components';
import { TabNavComponent } from './tab-nav.component';

@NgModule({
  imports: [CommonModule, TabNavBarModule, RouterModule],
  declarations: [TabNavComponent],
  exports: [TabNavComponent]
})
export class OyanTabNavModule {}
