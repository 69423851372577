import { BaseUser, Filter, PlatformRequest } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listUsers = createAction('[Users/Admin Page] List Users', props<{ request: PlatformRequest }>());
export const addUser = createAction('[Users/Admin Page] Add User', props<{ newUser: BaseUser }>());
export const updateUser = createAction('[Users/Admin Page] Update User', props<{ updatedUser: BaseUser }>());
export const activateUser = createAction('[Users/Admin Page] Activate User', props<{ user: BaseUser }>());
export const disableUser = createAction('[Users/Admin Page] Disable User', props<{ user: BaseUser }>());
export const resetUser = createAction('[Users/Admin Page] Reset User', props<{ user: BaseUser }>());
export const deleteUser = createAction('[Users/Admin Page] Delete User', props<{ user: BaseUser }>());
export const selectUser = createAction('[Users/Admin Page] Select User', props<{ selectedUserId: string }>());
export const navigateToUser = createAction('[Users/Admin Page] Navigate To User', props<{ selectedUserId: string }>());
export const backToUsers = createAction('[Users/Admin Page] Navigate Back To Users');
export const filterUsersByStatus = createAction('[Users/Admin Page] Filter Users By Status', props<{ status: string }>());
export const saveUsersFilters = createAction('[Users/Admin Page] Save Users Filters', props<{ filters: Filter[] }>());

export const getBusinessProfilesByUserId = createAction('[Users/Admin Page] GET Business Profiles By User', props<{ userId: string }>());
export const getVisibleEntityIds = createAction('[Users/Admin Page] Get Visible Entity Ids');
