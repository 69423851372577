import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseUser } from '@iot-platform/models/common';

@Component({
    selector: 'oyan-ui-user-profile-preferences',
    templateUrl: './user-profile-preferences.component.html',
    styleUrls: ['./user-profile-preferences.component.scss'],
    standalone: false
})
export class UserProfilePreferencesComponent {
  @Input() currentUser: BaseUser;
  @Output() changeUserPreferences: EventEmitter<void> = new EventEmitter<void>();
}
