// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .mat-mdc-form-field-flex {
  align-items: flex-end;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.layout-button-rotation:active {
  animation: rotate 200ms ease 0ms 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvZ2luLXJlc2V0LXBhc3N3b3JkLWZvcm0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxxQkFBQTtBQUNGOztBQUVBO0VBQ0U7SUFDRSx1QkFBQTtFQUNGO0VBQ0E7SUFDRSwwQkFBQTtFQUNGO0FBQ0Y7QUFHRTtFQUNFLGtDQUFBO0FBREoiLCJmaWxlIjoibG9naW4tcmVzZXQtcGFzc3dvcmQtZm9ybS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0OjpuZy1kZWVwIC5tYXQtbWRjLWZvcm0tZmllbGQtZmxleCB7XHJcbiAgYWxpZ24taXRlbXM6IGZsZXgtZW5kO1xyXG59XHJcblxyXG5Aa2V5ZnJhbWVzIHJvdGF0ZSB7XHJcbiAgZnJvbSB7XHJcbiAgICB0cmFuc2Zvcm06IHJvdGF0ZSgwZGVnKTtcclxuICB9XHJcbiAgdG8ge1xyXG4gICAgdHJhbnNmb3JtOiByb3RhdGUoLTM2MGRlZyk7XHJcbiAgfVxyXG59XHJcblxyXG4ubGF5b3V0LWJ1dHRvbi1yb3RhdGlvbiB7XHJcbiAgJjphY3RpdmUge1xyXG4gICAgYW5pbWF0aW9uOiByb3RhdGUgMjAwbXMgZWFzZSAwbXMgMTtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/auth/src/lib/components/login-reset-password-form/login-reset-password-form.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,0BAAA;EACF;AACF;AAGE;EACE,kCAAA;AADJ;AACA,ozBAAozB","sourcesContent":[":host::ng-deep .mat-mdc-form-field-flex {\r\n  align-items: flex-end;\r\n}\r\n\r\n@keyframes rotate {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n  to {\r\n    transform: rotate(-360deg);\r\n  }\r\n}\r\n\r\n.layout-button-rotation {\r\n  &:active {\r\n    animation: rotate 200ms ease 0ms 1;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
