// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-type-disclaimer {
  margin-top: 10px;
  min-height: 40px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZC11c2VyLWRpYWxvZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGdCQUFBO0VBQ0EsZ0JBQUE7QUFDRiIsImZpbGUiOiJhZGQtdXNlci1kaWFsb2cuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYWNjb3VudC10eXBlLWRpc2NsYWltZXIge1xyXG4gIG1hcmdpbi10b3A6IDEwcHg7XHJcbiAgbWluLWhlaWdodDogNDBweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-backoffice/src/lib/components/dialogs/add-user-dialog/add-user-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;AACA,4YAA4Y","sourcesContent":[".account-type-disclaimer {\r\n  margin-top: 10px;\r\n  min-height: 40px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
