// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-details-widget__label {
  font-weight: 600;
}
.card-details-widget__value a {
  text-decoration: none;
  color: #66aef2;
}
.card-details-widget__value a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhcmQtZGV0YWlscy13aWRnZXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxnQkFBQTtBQUFKO0FBR0k7RUFDRSxxQkFBQTtFQUNBLGNBQUE7QUFETjtBQUVNO0VBQ0UscUNBQUE7RUFDQSxlQUFBO0FBQVIiLCJmaWxlIjoiY2FyZC1kZXRhaWxzLXdpZGdldC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jYXJkLWRldGFpbHMtd2lkZ2V0IHtcclxuICAmX19sYWJlbCB7XHJcbiAgICBmb250LXdlaWdodDogNjAwO1xyXG4gIH1cclxuICAmX192YWx1ZSB7XHJcbiAgICBhIHtcclxuICAgICAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xyXG4gICAgICBjb2xvcjogIzY2YWVmMjtcclxuICAgICAgJjpob3ZlciB7XHJcbiAgICAgICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmUgIWltcG9ydGFudDtcclxuICAgICAgICBjdXJzb3I6IHBvaW50ZXI7XHJcbiAgICAgIH1cclxuICAgIH1cclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/card-details-widget/card-details-widget/card-details-widget.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGI;EACE,qBAAA;EACA,cAAA;AADN;AAEM;EACE,qCAAA;EACA,eAAA;AAAR;AACA,4uBAA4uB","sourcesContent":[".card-details-widget {\r\n  &__label {\r\n    font-weight: 600;\r\n  }\r\n  &__value {\r\n    a {\r\n      text-decoration: none;\r\n      color: #66aef2;\r\n      &:hover {\r\n        text-decoration: underline !important;\r\n        cursor: pointer;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
