import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { LayoutModule } from '../../components/layout/layout.module';
import { OyanTabNavModule } from '../tab-nav/tab-nav.module';
import { AppShellComponent } from './app-shell.component';
import { AppShellRouterModule } from './app-shell.router.module';

@NgModule({
  imports: [OyanTabNavModule, AppShellRouterModule, SharedModule, IotPlatformUiModule, LayoutModule],
  exports: [AppShellComponent],
  declarations: [AppShellComponent]
})
export class OyanUiAppShellModule {}
