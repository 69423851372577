import { Injectable } from '@angular/core';
import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { transformApiRequest, transformApiResponse } from '../../../../helpers/apis-helpers';
import { ProductsService } from '../../services/products.service';
import { ProductsActions } from '../actions';

@Injectable()
export class ProductsEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadProducts),
      switchMap(({ request }) =>
        this.productsService.getAll(transformApiRequest(request)).pipe(
          map((response: CommonApiResponse<Product, CommonIndexedPagination>) =>
            ProductsActions.loadProductsSuccess({ response: transformApiResponse<Product>(response) })
          ),
          catchError((error) => of(ProductsActions.loadProductsFailure({ error })))
        )
      )
    )
  );

  loadProductById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadProductById),
      switchMap((action) =>
        this.productsService.getById(action.id).pipe(
          map((response: Product) => ProductsActions.loadProductByIdSuccess({ response })),
          catchError((error) => of(ProductsActions.loadProductByIdFailure({ error })))
        )
      )
    )
  );

  addProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.addProduct),
      concatMap((action) =>
        this.productsService.addOne(action.product).pipe(
          map((response: Product) => ProductsActions.addProductSuccess({ response })),
          catchError((error) => of(ProductsActions.addProductFailure({ error })))
        )
      )
    )
  );

  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.updateProduct),
      concatMap((action) =>
        this.productsService.updateOne(action.product).pipe(
          map((response: Product) => ProductsActions.updateProductSuccess({ response })),
          catchError((error) => of(ProductsActions.updateProductFailure({ error })))
        )
      )
    )
  );

  deleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.deleteProduct),
      concatMap((action) =>
        this.productsService.deleteOne(action.product).pipe(
          map((response) => ProductsActions.deleteProductSuccess({ response })),
          catchError((error) => of(ProductsActions.deleteProductFailure({ error })))
        )
      )
    )
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductsActions.loadProductsFailure,
          ProductsActions.loadProductByIdFailure,
          ProductsActions.addProductFailure,
          ProductsActions.updateProductFailure,
          ProductsActions.deleteProductFailure
        ),
        tap((action) => this.notificationService.displayError(action.type))
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductsActions.addProductSuccess, ProductsActions.updateProductSuccess, ProductsActions.deleteProductSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductsActions.loadProducts,
          ProductsActions.loadProductById,
          ProductsActions.addProduct,
          ProductsActions.updateProduct,
          ProductsActions.deleteProduct
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductsActions.loadProductsSuccess,
          ProductsActions.loadProductByIdSuccess,
          ProductsActions.addProductSuccess,
          ProductsActions.updateProductSuccess,
          ProductsActions.deleteProductSuccess,
          ProductsActions.loadProductsFailure,
          ProductsActions.loadProductByIdFailure,
          ProductsActions.addProductFailure,
          ProductsActions.updateProductFailure,
          ProductsActions.deleteProductFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly productsService: ProductsService
  ) {}
}
