// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (max-width: 959px) {
  .select-field {
    min-width: auto;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1maWVsZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQURGO0lBRUksZUFBQTtFQUNGO0FBQ0YiLCJmaWxlIjoic2VsZWN0LWZpZWxkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNlbGVjdC1maWVsZCB7XHJcbiAgQG1lZGlhIGFsbCBhbmQgKG1heC13aWR0aDogOTU5cHgpIHtcclxuICAgIG1pbi13aWR0aDogYXV0bztcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/filter-engine/fields/select-field/select-field.component.scss"],"names":[],"mappings":"AACE;EADF;IAEI,eAAA;EACF;AACF;AACA,oZAAoZ","sourcesContent":[".select-field {\r\n  @media all and (max-width: 959px) {\r\n    min-width: auto;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
