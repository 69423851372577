// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
}
:host .spinner-value {
  position: absolute;
  width: inherit;
  height: inherit;
}
:host .spinner-value {
  text-align: center;
  overflow: hidden;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNwaW5uZXItYmFja2dyb3VuZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7RUFDQSxrQkFBQTtBQUNGO0FBQ0U7RUFDRSxrQkFBQTtFQUNBLGNBQUE7RUFDQSxlQUFBO0FBQ0o7QUFFRTtFQUNFLGtCQUFBO0VBQ0EsZ0JBQUE7QUFBSiIsImZpbGUiOiJzcGlubmVyLWJhY2tncm91bmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XHJcbiAgZGlzcGxheTogYmxvY2s7XHJcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xyXG5cclxuICAuc3Bpbm5lci12YWx1ZSB7XHJcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgICB3aWR0aDogaW5oZXJpdDtcclxuICAgIGhlaWdodDogaW5oZXJpdDtcclxuICB9XHJcblxyXG4gIC5zcGlubmVyLXZhbHVlIHtcclxuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcclxuICAgIG92ZXJmbG93OiBoaWRkZW47XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/spinner-background/spinner-background.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,cAAA;EACA,eAAA;AACJ;AAEE;EACE,kBAAA;EACA,gBAAA;AAAJ;AACA,4tBAA4tB","sourcesContent":[":host {\r\n  display: block;\r\n  position: relative;\r\n\r\n  .spinner-value {\r\n    position: absolute;\r\n    width: inherit;\r\n    height: inherit;\r\n  }\r\n\r\n  .spinner-value {\r\n    text-align: center;\r\n    overflow: hidden;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
