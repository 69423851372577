import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { provideContactsState } from '@iot-platform/data-access/contacts';
import { InfoDisplayComponent } from '../../containers/info-display/info-display.component';
import { SiteOverviewComponent } from './containers/site-overview/site-overview.component';
import { SitesShellComponent } from './containers/sites-shell/sites-shell.component';

const routes: Routes = [
  {
    path: '',
    component: SitesShellComponent,
    providers: [provideContactsState()]
  },
  {
    path: ':siteId',
    component: InfoDisplayComponent,
    children: [
      {
        path: '',
        component: SiteOverviewComponent,
        pathMatch: 'full'
      }
    ],
    providers: [provideContactsState()]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SitesRoutingModule {}
