// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-bottom: 1rem;
}

.timeline-list {
  width: 100%;
}
.timeline-list__title {
  padding: 0.5rem;
}
.timeline-list__title .mat-icon {
  width: 20px;
  height: 20px;
}
.timeline-list__title span {
  font-weight: 600;
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpbWVsaW5lLWxpc3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxtQkFBQTtBQUNGOztBQUNBO0VBQ0UsV0FBQTtBQUVGO0FBREU7RUFDRSxlQUFBO0FBR0o7QUFGSTtFQUNFLFdBQUE7RUFDQSxZQUFBO0FBSU47QUFESTtFQUNFLGdCQUFBO0VBQ0EsZUFBQTtBQUdOIiwiZmlsZSI6InRpbWVsaW5lLWxpc3QuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XHJcbiAgbWFyZ2luLWJvdHRvbTogMXJlbTtcclxufVxyXG4udGltZWxpbmUtbGlzdCB7XHJcbiAgd2lkdGg6IDEwMCU7XHJcbiAgJl9fdGl0bGUge1xyXG4gICAgcGFkZGluZzogMC41cmVtO1xyXG4gICAgLm1hdC1pY29uIHtcclxuICAgICAgd2lkdGg6IDIwcHg7XHJcbiAgICAgIGhlaWdodDogMjBweDtcclxuICAgIH1cclxuXHJcbiAgICBzcGFuIHtcclxuICAgICAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICAgICAgZm9udC1zaXplOiAxMnB4O1xyXG4gICAgfVxyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/timeline-list/timeline-list.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,WAAA;AAEF;AADE;EACE,eAAA;AAGJ;AAFI;EACE,WAAA;EACA,YAAA;AAIN;AADI;EACE,gBAAA;EACA,eAAA;AAGN;AACA,gxBAAgxB","sourcesContent":[":host {\r\n  margin-bottom: 1rem;\r\n}\r\n.timeline-list {\r\n  width: 100%;\r\n  &__title {\r\n    padding: 0.5rem;\r\n    .mat-icon {\r\n      width: 20px;\r\n      height: 20px;\r\n    }\r\n\r\n    span {\r\n      font-weight: 600;\r\n      font-size: 12px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
