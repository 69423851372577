// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-info {
  height: auto !important;
}
.user-profile-info__container {
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItcHJvZmlsZS1zaGVsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHVCQUFBO0FBQ0Y7QUFBQztFQUNFLGVBQUE7QUFFSCIsImZpbGUiOiJ1c2VyLXByb2ZpbGUtc2hlbGwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudXNlci1wcm9maWxlLWluZm8ge1xyXG4gIGhlaWdodDogYXV0byAhaW1wb3J0YW50O1xyXG4gJl9fY29udGFpbmVyIHtcclxuICAgZm9udC1zaXplOiAxMnB4O1xyXG4gfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/oyan-ui/src/lib/features/admin/containers/user-profile-shell/user-profile-shell.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAAC;EACE,eAAA;AAEH;AACA,ocAAoc","sourcesContent":[".user-profile-info {\r\n  height: auto !important;\r\n &__container {\r\n   font-size: 12px;\r\n }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
