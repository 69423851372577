import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetUtils, SortUtil } from '@iot-platform/iot-platform-utils';
import { Entity } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/oyan';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntitiesService } from '../../../../../../../shared/src/lib/entities.service';

@Component({
    selector: 'oyan-ui-site-info-form',
    templateUrl: './site-info-form.component.html',
    styleUrls: ['./site-info-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SiteInfoFormComponent implements OnInit, OnDestroy {
  siteForm: UntypedFormGroup;
  defaultName: string;
  imageUrl: string = null;
  sortedEntities: Entity[];
  countries$: Observable<{ key: string; value: string }[]>;

  subscriptions = new Subscription();

  constructor(
    private entitiesService: EntitiesService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<SiteInfoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { site: Site }
  ) {}

  get imgPath(): string {
    return this.imageUrl ? this.imageUrl : 'assets/images/site/site_default.png';
  }

  get title$(): Observable<string> {
    return this.data.site
      ? this.translateService.get('SITES.INFO_FORM.EDIT_SITE', { value: GetUtils.get(this.data, 'site.displayName', '') })
      : this.translateService.get('SITES.INFO_FORM.CREATE_SITE');
  }

  get entity(): AbstractControl {
    return this.siteForm.get('entity');
  }

  get name(): AbstractControl {
    return this.siteForm.get('name');
  }

  get shipToId(): AbstractControl {
    return this.siteForm.get('shipToId');
  }

  get address1(): AbstractControl {
    return this.siteForm.get('address1');
  }

  get address2(): AbstractControl {
    return this.siteForm.get('address2');
  }

  get zipCode(): AbstractControl {
    return this.siteForm.get('zipCode');
  }

  get city(): AbstractControl {
    return this.siteForm.get('city');
  }

  get country(): AbstractControl {
    return this.siteForm.get('country');
  }

  get description(): AbstractControl {
    return this.siteForm.get('description');
  }

  get action$(): Observable<string> {
    return this.data.site ? this.translateService.get('SITES.INFO_FORM.UPDATE') : this.translateService.get('SITES.INFO_FORM.CREATE');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.siteForm = new UntypedFormGroup({
      entity: new UntypedFormControl(null, [Validators.required]),
      name: new UntypedFormControl(GetUtils.get(this.data, 'site.displayName', null), [
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern('\\S.*')
      ]),
      shipToId: new UntypedFormControl({ value: GetUtils.get(this.data, 'site.name', null), disabled: true }, [Validators.required, Validators.maxLength(30)]),
      address1: new UntypedFormControl(GetUtils.get(this.data, 'site.address.address1', null), [Validators.required, Validators.maxLength(50)]),
      address2: new UntypedFormControl(GetUtils.get(this.data, 'site.address.address2', null), [Validators.maxLength(50)]),
      zipCode: new UntypedFormControl(GetUtils.get(this.data, 'site.address.zipCode', null), [Validators.required, Validators.maxLength(10)]),
      city: new UntypedFormControl(GetUtils.get(this.data, 'site.address.city', null), [Validators.required, Validators.maxLength(30)]),
      country: new UntypedFormControl(GetUtils.get(this.data, 'site.address.country', null), [Validators.required]),
      description: new UntypedFormControl(GetUtils.get(this.data, 'site.description', null), [Validators.maxLength(300)])
    });

    this.shipToId.disable();

    if (this.data.site) {
      this.defaultName = this.data.site.displayName;
      this.imageUrl = this.data.site.imageUrl;
    }

    this.countries$ = this.translateService.get('IOT_DICTIONARY.COUNTRIES').pipe(
      map((countries: { [key: string]: string }) =>
        Object.keys(countries)
          .map((key: string) => ({ key, value: countries[key] }))
          .sort(SortUtil.sortByProperty('value'))
      )
    );

    this.subscriptions.add(
      this.countries$.subscribe((countries: { key: string; value: string }[]) => {
        if (this.data.site) {
          const countryKey = GetUtils.get(this.data, 'site.address.country', '');
          if (!countries.find((c) => c.key.toLowerCase() === countryKey.toLowerCase())) {
            this.country.reset();
          }
        }
      })
    );
    this.subscriptions.add(
      this.entitiesService.getHierarchicallySortedEntities().subscribe((entities) => {
        this.sortedEntities = entities;
        if (this.data.site) {
          this.siteForm.controls.entity.setValue(this.sortedEntities.find((e) => e.id === this.data.site.entity.id));
        }
      })
    );
  }

  save(): void {
    const site: Site = {
      ...this.data.site,
      entity: { id: this.entity.value.id, name: this.entity.value.name },
      name: this.shipToId.value,
      displayName: this.name.value.trim(),
      address: {
        address1: this.address1.value,
        address2: this.address2.value,
        zipCode: this.zipCode.value,
        city: this.city.value,
        country: this.country.value
      },
      description: this.description.value || '',
      imageUrl: this.imageUrl
    };
    this.dialogRef.close(site);
  }

  close(): void {
    this.dialogRef.close();
  }
}
