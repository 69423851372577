// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (max-width: 959px) {
  .select-field {
    min-width: auto;
  }
}

.filter-label {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR5bmFtaWMtbGlzdC1maWVsZC1zaW5nbGUtc2VsZWN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBREY7SUFFSSxlQUFBO0VBQ0Y7QUFDRjs7QUFFQTtFQUNFLGdCQUFBO0VBQ0EscUJBQUE7RUFDQSx1QkFBQTtBQUNGIiwiZmlsZSI6ImR5bmFtaWMtbGlzdC1maWVsZC1zaW5nbGUtc2VsZWN0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNlbGVjdC1maWVsZCB7XHJcbiAgQG1lZGlhIGFsbCBhbmQgKG1heC13aWR0aDogOTU5cHgpIHtcclxuICAgIG1pbi13aWR0aDogYXV0bztcclxuICB9XHJcbn1cclxuXHJcbi5maWx0ZXItbGFiZWwge1xyXG4gIG92ZXJmbG93OiBoaWRkZW47XHJcbiAgd29yZC1icmVhazogYnJlYWstYWxsO1xyXG4gIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xyXG59Il19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/filter-engine/fields/dynamic-list-field/single-select/dynamic-list-field-single-select.component.scss"],"names":[],"mappings":"AACE;EADF;IAEI,eAAA;EACF;AACF;;AAEA;EACE,gBAAA;EACA,qBAAA;EACA,uBAAA;AACF;AACA,gpBAAgpB","sourcesContent":[".select-field {\r\n  @media all and (max-width: 959px) {\r\n    min-width: auto;\r\n  }\r\n}\r\n\r\n.filter-label {\r\n  overflow: hidden;\r\n  word-break: break-all;\r\n  text-overflow: ellipsis;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
