import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthFacade } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { OyanUiRoutingConstants } from '../../../oyan-ui.router.constants';
import { AuthorizationService } from '../services/authorization.service';
import { OyanAuthorizationConcept } from '../types/authorization.types';

@Injectable({
  providedIn: 'root'
})
export class OyanAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private authFacade: AuthFacade,
    private readonly storage: LocalStorageService
  ) {}

  canActivate(): Observable<boolean> {
    return this.isAuthorized().pipe(
      tap((hasPermission: boolean) => {
        if (!hasPermission) {
          this.router.navigate([`/${OyanUiRoutingConstants.LOGIN}`]);
        }
      })
    );
  }

  checkInApi(): Observable<boolean | null> {
    return this.authorizationService.getAuthorizations().pipe(
      tap((privileges) => {
        this.authFacade.loadPrivilegesSuccess(privileges);
      }),
      map((privileges) => this.hasPermission(privileges)),
      catchError(() => of(false))
    );
  }

  checkInStore(): Observable<{ [key: string]: string[] }> {
    return this.authFacade.privileges$.pipe(
      map((privileges) => {
        if (!privileges && this.storage.get(LocalStorageKeys.STORAGE_PRIVILEGES_KEY)) {
          return JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_PRIVILEGES_KEY));
        }
        return privileges;
      }),
      take(1)
    );
  }

  private hasPermission(privileges: { [key: string]: string[] }): boolean {
    return Object.keys(privileges).includes(OyanAuthorizationConcept.ADMIN_PORTAL);
  }

  private isAuthorized(): Observable<boolean> {
    return this.checkInStore().pipe(
      switchMap((privileges: { [key: string]: string[] }) => {
        if (privileges) {
          return of(this.hasPermission(privileges));
        }
        return this.checkInApi();
      })
    );
  }
}
