import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { CardEvent, CardEventType } from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'oyan-ui-site-gateways',
    templateUrl: './site-gateways.component.html',
    styleUrls: ['./site-gateways.component.scss'],
    standalone: false
})
export class SiteGatewaysComponent implements OnChanges {
  @Input() gateways: Gateway[];
  @Input() gatewaysBySite: Gateway[];
  @Input() loading: boolean;
  @Input() canUpdateSite: boolean;
  @Input() canAddGateway: boolean;
  @Input() canDeleteGateway: boolean;

  @Output() dispatchEvent: EventEmitter<CardEvent> = new EventEmitter();

  horizontalLayout = true;
  filteredGateways$: BehaviorSubject<Gateway[]> = new BehaviorSubject([]);

  get hasGateways(): boolean {
    return this.gateways && !!this.gateways.length;
  }

  get hasAssignedGateways(): boolean {
    return this.gatewaysBySite && !!this.gatewaysBySite.length;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.gateways && changes.gateways.currentValue) {
      this.filteredGateways$.next(changes.gateways.currentValue);
    }
  }

  onApplyFilter(searchTerm: string): void {
    const filteredGateways = this.gateways.filter(
      (p: Gateway) =>
        `${GetUtils.get(p, 'name', '')}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${GetUtils.get(p, 'eui', '')}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${GetUtils.get(p, 'description', '')}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.filteredGateways$.next(filteredGateways);
  }

  onClearFilter(): void {
    this.filteredGateways$.next([...this.gateways]);
  }

  onDispatchEvent(event: CardEvent): void {
    this.dispatchEvent.emit(event);
  }

  onAddGateway(gateway: Gateway): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.ADD_GATEWAY,
      data: gateway
    });
  }

  onSyncGateways(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.SYNCHRONIZE_GATEWAY,
      data: null
    });
  }
}
