// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.last-command-status-cell {
  width: 100px;
  font-size: 12px;
}

.last-command-status-cell-success {
  background-color: rgba(164, 255, 0, 0.1);
  padding: 9px;
}

.last-command-status-cell-failure {
  background-color: rgba(255, 0, 99, 0.1);
  padding: 9px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxhc3QtY29tbWFuZC1zdGF0dXMtY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxlQUFBO0FBQ0Y7O0FBRUE7RUFDRSx3Q0FBQTtFQUNBLFlBQUE7QUFDRjs7QUFFQTtFQUNFLHVDQUFBO0VBQ0EsWUFBQTtBQUNGIiwiZmlsZSI6Imxhc3QtY29tbWFuZC1zdGF0dXMtY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5sYXN0LWNvbW1hbmQtc3RhdHVzLWNlbGwge1xyXG4gIHdpZHRoOiAxMDBweDtcclxuICBmb250LXNpemU6IDEycHg7XHJcbn1cclxuXHJcbi5sYXN0LWNvbW1hbmQtc3RhdHVzLWNlbGwtc3VjY2VzcyB7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogcmdiYSgxNjQsIDI1NSwgMCwgMC4xKTtcclxuICBwYWRkaW5nOiA5cHg7XHJcbn1cclxuXHJcbi5sYXN0LWNvbW1hbmQtc3RhdHVzLWNlbGwtZmFpbHVyZSB7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogcmdiYSgyNTUsIDAsIDk5LCAwLjEpO1xyXG4gIHBhZGRpbmc6IDlweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/last-command-status-cell/last-command-status-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;AACF;;AAEA;EACE,wCAAA;EACA,YAAA;AACF;;AAEA;EACE,uCAAA;EACA,YAAA;AACF;AACA,gyBAAgyB","sourcesContent":[".last-command-status-cell {\r\n  width: 100px;\r\n  font-size: 12px;\r\n}\r\n\r\n.last-command-status-cell-success {\r\n  background-color: rgba(164, 255, 0, 0.1);\r\n  padding: 9px;\r\n}\r\n\r\n.last-command-status-cell-failure {\r\n  background-color: rgba(255, 0, 99, 0.1);\r\n  padding: 9px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
