// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .app-round-button-mv .mat-mdc-icon-button {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px;
  color: white !important;
  background: var(--mdc-icon-button-icon-color, inherit) !important;
}
:host .app-round-button-mv .mat-icon {
  font-size: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbGwtdG8tYWN0aW9uLWhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtFQUNFLHNCQUFBO0VBQ0EsdUJBQUE7RUFDQSxpQkFBQTtFQUNBLHVCQUFBO0VBQ0EsaUVBQUE7QUFETjtBQUlJO0VBQ0UsZUFBQTtBQUZOIiwiZmlsZSI6ImNhbGwtdG8tYWN0aW9uLWhlYWRlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcclxuICAuYXBwLXJvdW5kLWJ1dHRvbi1tdiB7XHJcbiAgICAubWF0LW1kYy1pY29uLWJ1dHRvbiB7XHJcbiAgICAgIHdpZHRoOiAyNHB4ICFpbXBvcnRhbnQ7XHJcbiAgICAgIGhlaWdodDogMjRweCAhaW1wb3J0YW50O1xyXG4gICAgICBsaW5lLWhlaWdodDogMjRweDtcclxuICAgICAgY29sb3I6IHdoaXRlICFpbXBvcnRhbnQ7XHJcbiAgICAgIGJhY2tncm91bmQ6IHZhcigtLW1kYy1pY29uLWJ1dHRvbi1pY29uLWNvbG9yLCBpbmhlcml0KSAhaW1wb3J0YW50O1xyXG4gICAgfVxyXG5cclxuICAgIC5tYXQtaWNvbiB7XHJcbiAgICAgIGZvbnQtc2l6ZTogMjBweDtcclxuICAgIH1cclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/call-to-action-header/call-to-action-header.component.scss"],"names":[],"mappings":"AAEI;EACE,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,uBAAA;EACA,iEAAA;AADN;AAII;EACE,eAAA;AAFN;AACA,40BAA40B","sourcesContent":[":host {\r\n  .app-round-button-mv {\r\n    .mat-mdc-icon-button {\r\n      width: 24px !important;\r\n      height: 24px !important;\r\n      line-height: 24px;\r\n      color: white !important;\r\n      background: var(--mdc-icon-button-icon-color, inherit) !important;\r\n    }\r\n\r\n    .mat-icon {\r\n      font-size: 20px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
