import { BusinessProfile, Filter } from '@iot-platform/models/common';
import { Asset, Message, Receiver, Site, Ward } from '@iot-platform/models/oyan';

export const getShiptoFilter = (site: Site): Filter => ({
  criteriaKey: 'shipto',
  criteriaLabel: 'FILTER_ENGINE.CRITERIA_FULL_LABEL.SITE_SHIP_TO',
  label: site.name,
  value: site.name,
  isHidden: false
});

// This filter should be excluded to prevent displayed in the filter engine
export const getEntityFilter = (businessProfile: BusinessProfile): Filter => ({
  criteriaKey: 'entityId',
  value: businessProfile.entityId,
  isHidden: true
});

export const getShipToAndEntityFilters = (site: Site, businessProfile: BusinessProfile): Filter[] => [getShiptoFilter(site), getEntityFilter(businessProfile)];
export const NAVIGATIONS_FILTERS = {
  SITES: {
    gatewayFilters: getShipToAndEntityFilters,
    receiverFilters: getShipToAndEntityFilters,
    assetFilters: (site: Site) => [getShiptoFilter(site)]
  },
  RECEIVERS: {
    communicationFilters: (receiver: Receiver) => [
      {
        criteriaKey: 'forwarderEui',
        criteriaLabel: 'FILTER_ENGINE.CRITERIA_FULL_LABEL.RECEIVER_ID',
        label: receiver.devEui,
        value: receiver.devEui
      }
    ],
    assetFilters: (ward: Ward) => [
      {
        criteriaKey: 'wardName',
        criteriaLabel: 'FILTER_ENGINE.CRITERIA_FULL_LABEL.WARD_NAME',
        label: ward.name,
        value: ward.name,
        isHidden: false
      }
    ]
  },
  ASSETS: {
    communicationFilters: (asset: Asset) => [
      {
        criteriaKey: 'identifier',
        criteriaLabel: 'FILTER_ENGINE.CRITERIA_FULL_LABEL.VIPR_ID',
        label: asset.identifier,
        value: asset.identifier
      }
    ]
  },
  COMMUNICATIONS: {
    receiverFilters: (message: Message) => [
      {
        criteriaKey: 'devEui',
        criteriaLabel: 'FILTER_ENGINE.CRITERIA_FULL_LABEL.RECEIVER_ID',
        label: message.forwarderEui,
        value: message.forwarderEui,
        isHidden: false
      }
    ]
  }
};
