// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-card__separator, .asset-card__group-name {
  color: #666666;
}
.asset-card__separator {
  font-size: 8px;
}
.asset-card__icon {
  height: 16px;
  width: 16px;
}
.asset-card__group-name {
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFzc2V0LWNhcmQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFFRSxjQUFBO0FBREo7QUFJRTtFQUNFLGNBQUE7QUFGSjtBQUtFO0VBQ0UsWUFBQTtFQUNBLFdBQUE7QUFISjtBQU1FO0VBQ0UsZUFBQTtBQUpKIiwiZmlsZSI6ImFzc2V0LWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYXNzZXQtY2FyZCB7XHJcbiAgJl9fc2VwYXJhdG9yLFxyXG4gICZfX2dyb3VwLW5hbWUge1xyXG4gICAgY29sb3I6ICM2NjY2NjY7XHJcbiAgfVxyXG5cclxuICAmX19zZXBhcmF0b3Ige1xyXG4gICAgZm9udC1zaXplOiA4cHg7XHJcbiAgfVxyXG5cclxuICAmX19pY29uIHtcclxuICAgIGhlaWdodDogMTZweDtcclxuICAgIHdpZHRoOiAxNnB4O1xyXG4gIH1cclxuXHJcbiAgJl9fZ3JvdXAtbmFtZSB7XHJcbiAgICBmb250LXNpemU6IDEycHg7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-asset/src/lib/asset-card/asset-card.component.scss"],"names":[],"mappings":"AACE;EAEE,cAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,YAAA;EACA,WAAA;AAHJ;AAME;EACE,eAAA;AAJJ;AACA,wsBAAwsB","sourcesContent":[".asset-card {\r\n  &__separator,\r\n  &__group-name {\r\n    color: #666666;\r\n  }\r\n\r\n  &__separator {\r\n    font-size: 8px;\r\n  }\r\n\r\n  &__icon {\r\n    height: 16px;\r\n    width: 16px;\r\n  }\r\n\r\n  &__group-name {\r\n    font-size: 12px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
