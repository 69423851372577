// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-icon-content.icon-active, .table-icon-content.icon-closed, .table-icon-content.icon-acknowledged, .table-icon-content.icon-snoozed {
  width: 20px;
  height: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImljb24tY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUlFLFdBQUE7RUFDQSxZQUFBO0FBSEoiLCJmaWxlIjoiaWNvbi1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRhYmxlLWljb24tY29udGVudCB7XHJcbiAgJi5pY29uLWFjdGl2ZSxcclxuICAmLmljb24tY2xvc2VkLFxyXG4gICYuaWNvbi1hY2tub3dsZWRnZWQsXHJcbiAgJi5pY29uLXNub296ZWQge1xyXG4gICAgd2lkdGg6IDIwcHg7XHJcbiAgICBoZWlnaHQ6IDIwcHg7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/icon-cell/icon-cell.component.scss"],"names":[],"mappings":"AACE;EAIE,WAAA;EACA,YAAA;AAHJ;AACA,4eAA4e","sourcesContent":[".table-icon-content {\r\n  &.icon-active,\r\n  &.icon-closed,\r\n  &.icon-acknowledged,\r\n  &.icon-snoozed {\r\n    width: 20px;\r\n    height: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
