import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { UserAccount } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import { catchError, map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { UsersService } from '../../services/users.service';
import { AdminUsersApiActions, AdminUsersPageActions } from '../actions';

@Injectable()
export class AdminUsersEffects {
  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.listUsers),
      switchMap((action) =>
        this.usersService.getUsers(action.request).pipe(
          retry(3),
          map((response) => AdminUsersApiActions.listUsersSuccess({ response })),
          catchError((error) => of(AdminUsersApiActions.listUsersFailure({ error })))
        )
      )
    )
  );

  filterUsersByStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.filterUsersByStatus),
      mergeMap((action) =>
        this.usersService.getUsersFilteredByStatus(action.status).pipe(
          map((response) => AdminUsersApiActions.filterUsersByStatusSuccess({ response })),
          catchError((error) => of(AdminUsersApiActions.filterUsersByStatusFailure({ error })))
        )
      )
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.addUser),
      mergeMap((action) =>
        this.usersService.addUser(action.newUser).pipe(
          map((addedUser) => AdminUsersApiActions.addUserSuccess({ addedUser })),
          catchError((error) => of(AdminUsersApiActions.addUserFailure({ error })))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.updateUser),
      concatMap((action) =>
        this.usersService.updateUser(action.updatedUser).pipe(
          map((updatedUser) => AdminUsersApiActions.updateUserSuccess({ updatedUser: { id: updatedUser.id, changes: updatedUser } })),
          catchError((error) => of(AdminUsersApiActions.updateUserFailure({ error })))
        )
      )
    )
  );

  activateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.activateUser),
      switchMap((action) =>
        this.usersService.activateUser(action.user).pipe(
          map((updatedUser) => AdminUsersApiActions.activateUserSuccess({ updatedUser: { id: updatedUser.id, changes: updatedUser } })),
          catchError((error) => of(AdminUsersApiActions.activateUserFailure({ error })))
        )
      )
    )
  );

  disableUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.disableUser),
      switchMap((action) =>
        this.usersService.disableUser(action.user).pipe(
          map((updatedUser) => AdminUsersApiActions.disableUserSuccess({ updatedUser: { id: updatedUser.id, changes: updatedUser } })),
          catchError((error) => of(AdminUsersApiActions.disableUserFailure({ error })))
        )
      )
    )
  );

  resetUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.resetUser),
      switchMap((action) =>
        this.usersService.resetUser(action.user).pipe(
          map((updatedUser) => AdminUsersApiActions.resetUserSuccess({ updatedUser: { id: updatedUser.id, changes: updatedUser } })),
          catchError((error) => of(AdminUsersApiActions.resetUserFailure({ error })))
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.deleteUser),
      mergeMap((action) =>
        this.usersService.deleteUser(action.user).pipe(
          map((deletedUser) => AdminUsersApiActions.deleteUserSuccess({ deletedUserId: deletedUser.id })),
          catchError((error) => of(AdminUsersApiActions.deleteUserFailure({ error })))
        )
      )
    )
  );

  getBusinessProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.getBusinessProfilesByUserId),
      mergeMap((action) =>
        this.usersService.getBusinessProfilesByUserId(action.userId).pipe(
          map((businessProfiles) => AdminUsersApiActions.getBusinessProfilesByUserIdSuccess({ businessProfiles })),
          catchError((error) => of(AdminUsersApiActions.getBusinessProfilesByUserIdFailure({ error })))
        )
      )
    )
  );

  selectUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.selectUser),
      mergeMap((action) =>
        this.usersService.getById(action.selectedUserId).pipe(
          map((selectedUser: UserAccount) => AdminUsersApiActions.selectUserSuccess({ selectedUser })),
          catchError((error) => of(AdminUsersApiActions.selectUserFailure({ error })))
        )
      )
    )
  );

  loadVisibleEntitiesByBusinessProfileId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.getVisibleEntityIds),
      switchMap(() =>
        this.usersService.getVisibleEntityIds().pipe(
          map((entityIds: string[]) => AdminUsersApiActions.getVisibleEntityIdsSuccess({ entityIds })),
          catchError((error) => of(AdminUsersApiActions.getVisibleEntityIdsFailure({ error })))
        )
      )
    )
  );

  navigateToUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersPageActions.navigateToUser),
      tap((action) => {
        this.router.navigate([`/admin/users/${action.selectedUserId}`]);
        this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, '/admin/users');
        this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, action.selectedUserId);
      }),
      map((action) => AdminUsersPageActions.selectUser({ selectedUserId: action.selectedUserId }))
    )
  );

  backToUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminUsersPageActions.backToUsers),
        tap(() => {
          const route = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY) ?? '/admin/users';
          this.router.navigate(route.split('/'));
        })
      ),
    { dispatch: false }
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminUsersApiActions.updateUserSuccess,
          AdminUsersApiActions.addUserSuccess,
          AdminUsersApiActions.deleteUserSuccess,
          AdminUsersApiActions.activateUserSuccess,
          AdminUsersApiActions.disableUserSuccess,
          AdminUsersApiActions.resetUserSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminUsersApiActions.activateUserFailure,
          AdminUsersApiActions.disableUserFailure,
          AdminUsersApiActions.resetUserFailure,
          AdminUsersApiActions.addUserFailure,
          AdminUsersApiActions.updateUserFailure,
          AdminUsersApiActions.deleteUserFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminUsersPageActions.updateUser,
          AdminUsersPageActions.addUser,
          AdminUsersPageActions.listUsers,
          AdminUsersPageActions.activateUser,
          AdminUsersPageActions.disableUser,
          AdminUsersPageActions.resetUser,
          AdminUsersPageActions.filterUsersByStatus
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminUsersApiActions.updateUserSuccess,
          AdminUsersApiActions.addUserSuccess,
          AdminUsersApiActions.deleteUserSuccess,
          AdminUsersApiActions.activateUserSuccess,
          AdminUsersApiActions.disableUserSuccess,
          AdminUsersApiActions.resetUserSuccess,
          AdminUsersApiActions.listUsersSuccess,
          AdminUsersApiActions.filterUsersByStatusSuccess,
          AdminUsersApiActions.updateUserFailure,
          AdminUsersApiActions.addUserFailure,
          AdminUsersApiActions.deleteUserFailure,
          AdminUsersApiActions.activateUserFailure,
          AdminUsersApiActions.disableUserFailure,
          AdminUsersApiActions.resetUserFailure,
          AdminUsersApiActions.listUsersFailure,
          AdminUsersApiActions.filterUsersByStatusFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private storage: LocalStorageService,
    private readonly router: Router
  ) {}
}
