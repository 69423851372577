// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-platform-ui-infos-display-card-label {
  line-height: 36px;
  padding: 0 10px;
  text-align: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZm9zLWRpc3BsYXktY2FyZC1sYWJlbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGlCQUFBO0VBQ0EsZUFBQTtFQUNBLGtCQUFBO0FBQ0YiLCJmaWxlIjoiaW5mb3MtZGlzcGxheS1jYXJkLWxhYmVsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmlvdC1wbGF0Zm9ybS11aS1pbmZvcy1kaXNwbGF5LWNhcmQtbGFiZWwge1xyXG4gIGxpbmUtaGVpZ2h0OiAzNnB4O1xyXG4gIHBhZGRpbmc6IDAgMTBweDtcclxuICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/cards/infos-display-card/infos-display-card-label/infos-display-card-label.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;AACF;AACA,oeAAoe","sourcesContent":[".iot-platform-ui-infos-display-card-label {\r\n  line-height: 36px;\r\n  padding: 0 10px;\r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
