// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-variable-icon {
  width: 120px;
  height: 250px;
}

.mat-mdc-option {
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZhcmlhYmxlLWNyZWF0ZS1mb3JtLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtFQUNBLGFBQUE7QUFDRjs7QUFFQTtFQUNFLGVBQUE7QUFDRiIsImZpbGUiOiJ2YXJpYWJsZS1jcmVhdGUtZm9ybS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5hc3NldC12YXJpYWJsZS1pY29uIHtcclxuICB3aWR0aDogMTIwcHg7XHJcbiAgaGVpZ2h0OiAyNTBweDtcclxufVxyXG5cclxuLm1hdC1tZGMtb3B0aW9uIHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-asset/src/lib/variable-create-form/variable-create-form.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;AACF;AACA,weAAwe","sourcesContent":[".asset-variable-icon {\r\n  width: 120px;\r\n  height: 250px;\r\n}\r\n\r\n.mat-mdc-option {\r\n  font-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
