// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-panel-container {
  width: 400px;
}

.catalog-panel-content {
  font-size: 12px;
}

.catalog-panel-catalog-card {
  padding: 6px 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.sticky {
  position: sticky;
  top: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkbWluLXByb2R1Y3QtY2F0YWxvZy1wYW5lbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7QUFDRjs7QUFFQTtFQUNFLGVBQUE7QUFDRjs7QUFFQTtFQUNFLGdCQUFBO0VBQ0EseUNBQUE7QUFDRjs7QUFFQTtFQUNFLGdCQUFBO0VBQ0EsTUFBQTtBQUNGIiwiZmlsZSI6ImFkbWluLXByb2R1Y3QtY2F0YWxvZy1wYW5lbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jYXRhbG9nLXBhbmVsLWNvbnRhaW5lciB7XHJcbiAgd2lkdGg6IDQwMHB4O1xyXG59XHJcblxyXG4uY2F0YWxvZy1wYW5lbC1jb250ZW50IHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbn1cclxuXHJcbi5jYXRhbG9nLXBhbmVsLWNhdGFsb2ctY2FyZCB7XHJcbiAgcGFkZGluZzogNnB4IDRweDtcclxuICBib3gtc2hhZG93OiAwIDJweCAxMnB4IHJnYmEoMCwgMCwgMCwgMC4xKTtcclxufVxyXG5cclxuLnN0aWNreSB7XHJcbiAgcG9zaXRpb246IHN0aWNreTtcclxuICB0b3A6IDA7XHJcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-backoffice/src/lib/features/admin-product-catalogs/components/admin-product-catalog-panel/admin-product-catalog-panel.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,yCAAA;AACF;;AAEA;EACE,gBAAA;EACA,MAAA;AACF;AACA,wxBAAwxB","sourcesContent":[".catalog-panel-container {\r\n  width: 400px;\r\n}\r\n\r\n.catalog-panel-content {\r\n  font-size: 12px;\r\n}\r\n\r\n.catalog-panel-catalog-card {\r\n  padding: 6px 4px;\r\n  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.sticky {\r\n  position: sticky;\r\n  top: 0;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
