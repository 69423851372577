// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline {
  padding-left: 48px;
  font-size: 16px;
  line-height: 20px;
}
.timeline__container > *:last-child {
  background: aliceblue;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpbWVsaW5lLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usa0JBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7QUFDRjtBQUVJO0VBQ0UscUJBQUE7QUFBTiIsImZpbGUiOiJ0aW1lbGluZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi50aW1lbGluZSB7XHJcbiAgcGFkZGluZy1sZWZ0OiA0OHB4O1xyXG4gIGZvbnQtc2l6ZTogMTZweDtcclxuICBsaW5lLWhlaWdodDogMjBweDtcclxuXHJcbiAgJl9fY29udGFpbmVyID4gKiB7XHJcbiAgICAmOmxhc3QtY2hpbGQge1xyXG4gICAgICBiYWNrZ3JvdW5kOiBhbGljZWJsdWU7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/timeline/timeline.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;AACF;AAEI;EACE,qBAAA;AAAN;AACA,ojBAAojB","sourcesContent":[".timeline {\r\n  padding-left: 48px;\r\n  font-size: 16px;\r\n  line-height: 20px;\r\n\r\n  &__container > * {\r\n    &:last-child {\r\n      background: aliceblue;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
