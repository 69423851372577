import { Component, effect, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { LocalStorageKeys } from '@iot-platform/core';
import { HeaderComponent as SharedHeaderComponent } from '@iot-platform/feature/app-shell';
import { BusinessProfile } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
    imports: [MatTooltip, MatSelectModule, MatMenuModule, MatDivider, MatIcon, TranslateModule, FlexLayoutModule, MatButtonModule],
    selector: 'oyan-ui-header',
    templateUrl: './header.component.html',
    styleUrls: ['../../../../../../iot-platform/iot-platform-feature-app-shell/src/lib/components/header/header.component.scss', './header.component.scss']
})
export class HeaderComponent extends SharedHeaderComponent {
  @Input() appLogo: string;

  constructor() {
    super();

    this.initSelectedBPEffect();
  }

  initSelectedBPEffect(): void {
    effect(() => {
      const bp: BusinessProfile = this.selectedBusinessProfile();

      if (bp) {
        this.storage.set(
          LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY,
          JSON.stringify({
            ...bp,
            timezone: moment(new Date()).format('Z'),
            timezoneDetails: { name: null, offset: null }
          })
        );
      }
    });
  }
}
