// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline__content {
  position: relative;
  margin-top: 10px;
  padding: 5px 30px 0 0;
}
.timeline__content::before {
  position: absolute;
  top: -5px;
  left: -6px;
  z-index: 10;
  content: "";
  display: block;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpbWVsaW5lLWNvbnRlbnQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxrQkFBQTtFQUNBLGdCQUFBO0VBQ0EscUJBQUE7QUFBSjtBQUVJO0VBQ0Usa0JBQUE7RUFDQSxTQUFBO0VBQ0EsVUFBQTtFQUNBLFdBQUE7RUFDQSxXQUFBO0VBQ0EsY0FBQTtFQUNBLGtDQUFBO0VBQ0EsbUNBQUE7QUFBTiIsImZpbGUiOiJ0aW1lbGluZS1jb250ZW50LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRpbWVsaW5lIHtcclxuICAmX19jb250ZW50IHtcclxuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcclxuICAgIG1hcmdpbi10b3A6IDEwcHg7XHJcbiAgICBwYWRkaW5nOiA1cHggMzBweCAwIDA7XHJcblxyXG4gICAgJjo6YmVmb3JlIHtcclxuICAgICAgcG9zaXRpb246IGFic29sdXRlO1xyXG4gICAgICB0b3A6IC01cHg7XHJcbiAgICAgIGxlZnQ6IC02cHg7XHJcbiAgICAgIHotaW5kZXg6IDEwO1xyXG4gICAgICBjb250ZW50OiAnJztcclxuICAgICAgZGlzcGxheTogYmxvY2s7XHJcbiAgICAgIGJvcmRlci1sZWZ0OiA1cHggc29saWQgdHJhbnNwYXJlbnQ7XHJcbiAgICAgIGJvcmRlci1yaWdodDogNXB4IHNvbGlkIHRyYW5zcGFyZW50O1xyXG4gICAgfVxyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/timeline/timeline-content/timeline-content.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,gBAAA;EACA,qBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,cAAA;EACA,kCAAA;EACA,mCAAA;AAAN;AACA,o7BAAo7B","sourcesContent":[".timeline {\r\n  &__content {\r\n    position: relative;\r\n    margin-top: 10px;\r\n    padding: 5px 30px 0 0;\r\n\r\n    &::before {\r\n      position: absolute;\r\n      top: -5px;\r\n      left: -6px;\r\n      z-index: 10;\r\n      content: '';\r\n      display: block;\r\n      border-left: 5px solid transparent;\r\n      border-right: 5px solid transparent;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
