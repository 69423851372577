// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-nav {
  width: 400px;
  margin: 10px;
}

.view-type {
  color: #b1b1c0;
  padding-right: 6px;
  padding-left: 2px;
}

:host {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdyaWQtbWFuYWdlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxZQUFBO0FBQ0Y7O0FBRUE7RUFDRSxjQUFBO0VBQ0Esa0JBQUE7RUFDQSxpQkFBQTtBQUNGOztBQUVBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsWUFBQTtFQUNBLHNCQUFBO0FBQ0YiLCJmaWxlIjoiZ3JpZC1tYW5hZ2VyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbmZpZy1uYXYge1xyXG4gIHdpZHRoOiA0MDBweDtcclxuICBtYXJnaW46IDEwcHg7XHJcbn1cclxuXHJcbi52aWV3LXR5cGUge1xyXG4gIGNvbG9yOiAjYjFiMWMwO1xyXG4gIHBhZGRpbmctcmlnaHQ6IDZweDtcclxuICBwYWRkaW5nLWxlZnQ6IDJweDtcclxufVxyXG5cclxuOmhvc3Qge1xyXG4gIGRpc3BsYXk6IGZsZXg7XHJcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcclxuICBoZWlnaHQ6IDEwMCU7XHJcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/containers/grid-manager/grid-manager.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,sBAAA;AACF;AACA,gwBAAgwB","sourcesContent":[".config-nav {\r\n  width: 400px;\r\n  margin: 10px;\r\n}\r\n\r\n.view-type {\r\n  color: #b1b1c0;\r\n  padding-right: 6px;\r\n  padding-left: 2px;\r\n}\r\n\r\n:host {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100%;\r\n  box-sizing: border-box;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
