// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-card-list {
  padding: 10px;
  background-color: #e9eff6;
}

.detail-card-item {
  position: relative;
  padding-left: 110px;
  word-break: break-all;
  word-wrap: break-word;
}
.detail-card-item::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRldGFpbC1kZXZpY2UtdmFyaWFibGUtY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQUE7RUFDQSx5QkFBQTtBQUNGOztBQUVBO0VBQ0Usa0JBQUE7RUFDQSxtQkFBQTtFQUNBLHFCQUFBO0VBQ0EscUJBQUE7QUFDRjtBQUNFO0VBQ0UseUJBQUE7RUFDQSxrQkFBQTtFQUNBLE9BQUE7RUFDQSxnQkFBQTtBQUNKIiwiZmlsZSI6ImRldGFpbC1kZXZpY2UtdmFyaWFibGUtY2FyZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5kZXRhaWwtY2FyZC1saXN0IHtcclxuICBwYWRkaW5nOiAxMHB4O1xyXG4gIGJhY2tncm91bmQtY29sb3I6ICNlOWVmZjY7XHJcbn1cclxuXHJcbi5kZXRhaWwtY2FyZC1pdGVtIHtcclxuICBwb3NpdGlvbjogcmVsYXRpdmU7XHJcbiAgcGFkZGluZy1sZWZ0OiAxMTBweDtcclxuICB3b3JkLWJyZWFrOiBicmVhay1hbGw7XHJcbiAgd29yZC13cmFwOiBicmVhay13b3JkO1xyXG5cclxuICAmOjpiZWZvcmUge1xyXG4gICAgY29udGVudDogYXR0cihkYXRhLWxhYmVsKTtcclxuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcclxuICAgIGxlZnQ6IDA7XHJcbiAgICBmb250LXdlaWdodDogNjAwO1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/detail-popup-cards/detail-device-variable-card/detail-device-variable-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,qBAAA;EACA,qBAAA;AACF;AACE;EACE,yBAAA;EACA,kBAAA;EACA,OAAA;EACA,gBAAA;AACJ;AACA,g6BAAg6B","sourcesContent":[".detail-card-list {\r\n  padding: 10px;\r\n  background-color: #e9eff6;\r\n}\r\n\r\n.detail-card-item {\r\n  position: relative;\r\n  padding-left: 110px;\r\n  word-break: break-all;\r\n  word-wrap: break-word;\r\n\r\n  &::before {\r\n    content: attr(data-label);\r\n    position: absolute;\r\n    left: 0;\r\n    font-weight: 600;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
