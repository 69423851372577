// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organization-concept-section {
  padding: 4px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm9yZ2FuaXphdGlvbi1jb25jZXB0cy1zZWxlY3Rpb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0FBQ0YiLCJmaWxlIjoib3JnYW5pemF0aW9uLWNvbmNlcHRzLXNlbGVjdGlvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5vcmdhbml6YXRpb24tY29uY2VwdC1zZWN0aW9uIHtcclxuICBwYWRkaW5nOiA0cHggMDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-backoffice/src/lib/components/organization-concepts-selection/organization-concepts-selection.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACA,4YAA4Y","sourcesContent":[".organization-concept-section {\r\n  padding: 4px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
