// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: inherit;
}

span {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  word-break: break-word;
  color: #394c5a;
}
span::first-letter {
  text-transform: capitalize;
}
span.skip-text-transform::first-letter, span.skip-text-transform.customHeaderLabel {
  text-transform: none !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhc2ljLWhlYWRlci1jZWxsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtBQUNGOztBQUNBO0VBQ0UscUJBQUE7RUFDQSxlQUFBO0VBQ0EsZ0JBQUE7RUFDQSxvQ0FBQTtFQUNBLHNCQUFBO0VBQ0EsY0FBQTtBQUVGO0FBQUU7RUFDRSwwQkFBQTtBQUVKO0FBQ0k7RUFFRSwrQkFBQTtBQUFOIiwiZmlsZSI6ImJhc2ljLWhlYWRlci1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xyXG4gIHdpZHRoOiBpbmhlcml0O1xyXG59XHJcbnNwYW4ge1xyXG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcclxuICBmb250LXNpemU6IDEycHg7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICBmb250LWZhbWlseTogJ09wZW4gU2FucycsIHNhbnMtc2VyaWY7XHJcbiAgd29yZC1icmVhazogYnJlYWstd29yZDtcclxuICBjb2xvcjogIzM5NGM1YTtcclxuXHJcbiAgJjo6Zmlyc3QtbGV0dGVyIHtcclxuICAgIHRleHQtdHJhbnNmb3JtOiBjYXBpdGFsaXplO1xyXG4gIH1cclxuICAmLnNraXAtdGV4dC10cmFuc2Zvcm0ge1xyXG4gICAgJjo6Zmlyc3QtbGV0dGVyLFxyXG4gICAgJi5jdXN0b21IZWFkZXJMYWJlbCB7XHJcbiAgICAgIHRleHQtdHJhbnNmb3JtOiBub25lICFpbXBvcnRhbnQ7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/header-cells/basic-header-cell/basic-header-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AACA;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,sBAAA;EACA,cAAA;AAEF;AAAE;EACE,0BAAA;AAEJ;AACI;EAEE,+BAAA;AAAN;AACA,4+BAA4+B","sourcesContent":[":host {\r\n  width: inherit;\r\n}\r\nspan {\r\n  display: inline-block;\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n  font-family: 'Open Sans', sans-serif;\r\n  word-break: break-word;\r\n  color: #394c5a;\r\n\r\n  &::first-letter {\r\n    text-transform: capitalize;\r\n  }\r\n  &.skip-text-transform {\r\n    &::first-letter,\r\n    &.customHeaderLabel {\r\n      text-transform: none !important;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
