// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  font-size: 14px;
  font-weight: 600;
  height: 10px;
  margin: 3px;
}

.secondary {
  font-size: 12px;
  font-weight: 300;
  height: 10px;
  margin: 3px;
}

.secondary,
.main {
  text-transform: capitalize;
  font-family: "Roboto";
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJyZWFkY3J1bWItaXRlbS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxnQkFBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBRUE7RUFDRSxlQUFBO0VBQ0EsZ0JBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtBQUNGOztBQUVBOztFQUVFLDBCQUFBO0VBQ0EscUJBQUE7QUFDRiIsImZpbGUiOiJicmVhZGNydW1iLWl0ZW0uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubWFpbiB7XHJcbiAgZm9udC1zaXplOiAxNHB4O1xyXG4gIGZvbnQtd2VpZ2h0OiA2MDA7XHJcbiAgaGVpZ2h0OiAxMHB4O1xyXG4gIG1hcmdpbjogM3B4O1xyXG59XHJcblxyXG4uc2Vjb25kYXJ5IHtcclxuICBmb250LXNpemU6IDEycHg7XHJcbiAgZm9udC13ZWlnaHQ6IDMwMDtcclxuICBoZWlnaHQ6IDEwcHg7XHJcbiAgbWFyZ2luOiAzcHg7XHJcbn1cclxuXHJcbi5zZWNvbmRhcnksXHJcbi5tYWluIHtcclxuICB0ZXh0LXRyYW5zZm9ybTogY2FwaXRhbGl6ZTtcclxuICBmb250LWZhbWlseTogJ1JvYm90byc7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/breadcrumb-item/breadcrumb-item.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;;EAEE,0BAAA;EACA,qBAAA;AACF;AACA,4zBAA4zB","sourcesContent":[".main {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n  height: 10px;\r\n  margin: 3px;\r\n}\r\n\r\n.secondary {\r\n  font-size: 12px;\r\n  font-weight: 300;\r\n  height: 10px;\r\n  margin: 3px;\r\n}\r\n\r\n.secondary,\r\n.main {\r\n  text-transform: capitalize;\r\n  font-family: 'Roboto';\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
