// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.translated-cell {
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.translated-cell span {
  display: block;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}
.translated-cell span:first-letter {
  text-transform: uppercase;
}

.withStatusPadding {
  padding: 0 9px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJvb2xlYW4tY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxpQkFBQTtFQUNBLGVBQUE7RUFDQSxvQkFBQTtFQUNBLHFCQUFBO0VBQ0EsNEJBQUE7QUFDRjtBQUNFO0VBQ0UsY0FBQTtFQUNBLGdCQUFBO0VBQ0EscUJBQUE7RUFDQSx1QkFBQTtBQUNKO0FBQ0k7RUFDRSx5QkFBQTtBQUNOOztBQUlBO0VBQ0UseUJBQUE7QUFERiIsImZpbGUiOiJib29sZWFuLWNlbGwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudHJhbnNsYXRlZC1jZWxsIHtcclxuICBoZWlnaHQ6IDM4cHg7XHJcbiAgbGluZS1oZWlnaHQ6IDM4cHg7XHJcbiAgZm9udC1zaXplOiAxMnB4O1xyXG4gIGRpc3BsYXk6IC13ZWJraXQtYm94O1xyXG4gIC13ZWJraXQtbGluZS1jbGFtcDogMTtcclxuICAtd2Via2l0LWJveC1vcmllbnQ6IHZlcnRpY2FsO1xyXG5cclxuICBzcGFuIHtcclxuICAgIGRpc3BsYXk6IGJsb2NrO1xyXG4gICAgb3ZlcmZsb3c6IGhpZGRlbjtcclxuICAgIHdvcmQtYnJlYWs6IGJyZWFrLWFsbDtcclxuICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xyXG5cclxuICAgICY6Zmlyc3QtbGV0dGVyIHtcclxuICAgICAgdGV4dC10cmFuc2Zvcm06IHVwcGVyY2FzZTtcclxuICAgIH1cclxuICB9XHJcbn1cclxuXHJcbi53aXRoU3RhdHVzUGFkZGluZyB7XHJcbiAgcGFkZGluZzogMCA5cHggIWltcG9ydGFudDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/boolean-cell/boolean-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,eAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;AACF;AACE;EACE,cAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;AACJ;AACI;EACE,yBAAA;AACN;;AAIA;EACE,yBAAA;AADF;AACA,4iCAA4iC","sourcesContent":[".translated-cell {\r\n  height: 38px;\r\n  line-height: 38px;\r\n  font-size: 12px;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 1;\r\n  -webkit-box-orient: vertical;\r\n\r\n  span {\r\n    display: block;\r\n    overflow: hidden;\r\n    word-break: break-all;\r\n    text-overflow: ellipsis;\r\n\r\n    &:first-letter {\r\n      text-transform: uppercase;\r\n    }\r\n  }\r\n}\r\n\r\n.withStatusPadding {\r\n  padding: 0 9px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
