// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-title {
  font-size: 14px;
  line-height: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #7e7e7e;
  text-transform: uppercase;
  white-space: nowrap;
}

.business-profile-form {
  font-size: 14px;
  padding: 6px 16px 10px 16px;
  color: #394c5a;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ1c2luZXNzLXByb2ZpbGVzLWRldGFpbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxpQkFBQTtFQUNBLG9DQUFBO0VBQ0EsZ0JBQUE7RUFDQSxjQUFBO0VBQ0EseUJBQUE7RUFDQSxtQkFBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtFQUNBLDJCQUFBO0VBQ0EsY0FBQTtBQUNGIiwiZmlsZSI6ImJ1c2luZXNzLXByb2ZpbGVzLWRldGFpbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5wYW5lbC10aXRsZSB7XHJcbiAgZm9udC1zaXplOiAxNHB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAxMnB4O1xyXG4gIGZvbnQtZmFtaWx5OiAnT3BlbiBTYW5zJywgc2Fucy1zZXJpZjtcclxuICBmb250LXdlaWdodDogNjAwO1xyXG4gIGNvbG9yOiAjN2U3ZTdlO1xyXG4gIHRleHQtdHJhbnNmb3JtOiB1cHBlcmNhc2U7XHJcbiAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcclxufVxyXG5cclxuLmJ1c2luZXNzLXByb2ZpbGUtZm9ybSB7XHJcbiAgZm9udC1zaXplOiAxNHB4O1xyXG4gIHBhZGRpbmc6IDZweCAxNnB4IDEwcHggMTZweDtcclxuICBjb2xvcjogIzM5NGM1YTtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-backoffice/src/lib/features/admin-business-profiles/components/business-profiles-detail/business-profiles-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,2BAAA;EACA,cAAA;AACF;AACA,42BAA42B","sourcesContent":[".panel-title {\r\n  font-size: 14px;\r\n  line-height: 12px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  font-weight: 600;\r\n  color: #7e7e7e;\r\n  text-transform: uppercase;\r\n  white-space: nowrap;\r\n}\r\n\r\n.business-profile-form {\r\n  font-size: 14px;\r\n  padding: 6px 16px 10px 16px;\r\n  color: #394c5a;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
