// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-device-card-row {
  padding: 10px;
  border-radius: 2px;
  background-color: #e9eff6;
}

.detail-device-card-label {
  display: block;
  font-weight: 600;
}
.detail-device-card-label.connector {
  min-width: 50px;
}
.detail-device-card-label.communication {
  min-width: 65px;
}

.detail-device-card-value {
  display: block;
  word-break: break-all;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRldGFpbC1kZXZpY2UtY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQUE7RUFDQSxrQkFBQTtFQUNBLHlCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxjQUFBO0VBQ0EsZ0JBQUE7QUFDRjtBQUNFO0VBQ0UsZUFBQTtBQUNKO0FBRUU7RUFDRSxlQUFBO0FBQUo7O0FBSUE7RUFDRSxjQUFBO0VBQ0EscUJBQUE7QUFERiIsImZpbGUiOiJkZXRhaWwtZGV2aWNlLWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZGV0YWlsLWRldmljZS1jYXJkLXJvdyB7XHJcbiAgcGFkZGluZzogMTBweDtcclxuICBib3JkZXItcmFkaXVzOiAycHg7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogI2U5ZWZmNjtcclxufVxyXG5cclxuLmRldGFpbC1kZXZpY2UtY2FyZC1sYWJlbCB7XHJcbiAgZGlzcGxheTogYmxvY2s7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxuXHJcbiAgJi5jb25uZWN0b3Ige1xyXG4gICAgbWluLXdpZHRoOiA1MHB4O1xyXG4gIH1cclxuXHJcbiAgJi5jb21tdW5pY2F0aW9uIHtcclxuICAgIG1pbi13aWR0aDogNjVweDtcclxuICB9XHJcbn1cclxuXHJcbi5kZXRhaWwtZGV2aWNlLWNhcmQtdmFsdWUge1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIHdvcmQtYnJlYWs6IGJyZWFrLWFsbDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/detail-popup-cards/detail-device-card/detail-device-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,cAAA;EACA,qBAAA;AADF;AACA,g9BAAg9B","sourcesContent":[".detail-device-card-row {\r\n  padding: 10px;\r\n  border-radius: 2px;\r\n  background-color: #e9eff6;\r\n}\r\n\r\n.detail-device-card-label {\r\n  display: block;\r\n  font-weight: 600;\r\n\r\n  &.connector {\r\n    min-width: 50px;\r\n  }\r\n\r\n  &.communication {\r\n    min-width: 65px;\r\n  }\r\n}\r\n\r\n.detail-device-card-value {\r\n  display: block;\r\n  word-break: break-all;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
