// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-cell {
  line-height: 20px;
  opacity: 0.2;
  transition: all 300ms ease 0ms;
  cursor: pointer;
}
.comment-cell:hover {
  opacity: 1;
  color: #8ed0ff;
  text-decoration: underline;
}
.comment-cell.has-comment {
  opacity: 1;
}
.comment-cell.has-comment .comment-total {
  visibility: visible;
}
.comment-cell .comment-total {
  visibility: hidden;
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbW1lbnQtY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGlCQUFBO0VBQ0EsWUFBQTtFQUNBLDhCQUFBO0VBQ0EsZUFBQTtBQUNGO0FBQ0U7RUFDRSxVQUFBO0VBQ0EsY0FBQTtFQUNBLDBCQUFBO0FBQ0o7QUFFRTtFQUNFLFVBQUE7QUFBSjtBQUVJO0VBQ0UsbUJBQUE7QUFBTjtBQUdFO0VBQ0Usa0JBQUE7RUFDQSxlQUFBO0FBREoiLCJmaWxlIjoiY29tbWVudC1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbW1lbnQtY2VsbCB7XHJcbiAgbGluZS1oZWlnaHQ6IDIwcHg7XHJcbiAgb3BhY2l0eTogMC4yO1xyXG4gIHRyYW5zaXRpb246IGFsbCAzMDBtcyBlYXNlIDBtcztcclxuICBjdXJzb3I6IHBvaW50ZXI7XHJcblxyXG4gICY6aG92ZXIge1xyXG4gICAgb3BhY2l0eTogMTtcclxuICAgIGNvbG9yOiAjOGVkMGZmO1xyXG4gICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XHJcbiAgfVxyXG5cclxuICAmLmhhcy1jb21tZW50IHtcclxuICAgIG9wYWNpdHk6IDE7XHJcblxyXG4gICAgLmNvbW1lbnQtdG90YWwge1xyXG4gICAgICB2aXNpYmlsaXR5OiB2aXNpYmxlO1xyXG4gICAgfVxyXG4gIH1cclxuICAuY29tbWVudC10b3RhbCB7XHJcbiAgICB2aXNpYmlsaXR5OiBoaWRkZW47XHJcbiAgICBmb250LXNpemU6IDEycHg7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/comment-cell/comment-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;EACA,8BAAA;EACA,eAAA;AACF;AACE;EACE,UAAA;EACA,cAAA;EACA,0BAAA;AACJ;AAEE;EACE,UAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAGE;EACE,kBAAA;EACA,eAAA;AADJ;AACA,ogCAAogC","sourcesContent":[".comment-cell {\r\n  line-height: 20px;\r\n  opacity: 0.2;\r\n  transition: all 300ms ease 0ms;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    opacity: 1;\r\n    color: #8ed0ff;\r\n    text-decoration: underline;\r\n  }\r\n\r\n  &.has-comment {\r\n    opacity: 1;\r\n\r\n    .comment-total {\r\n      visibility: visible;\r\n    }\r\n  }\r\n  .comment-total {\r\n    visibility: hidden;\r\n    font-size: 12px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
