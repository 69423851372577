import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, CommonApiRequest, CommonApiResponse, CommonCRUDService, CommonIndexedPagination } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/oyan';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SitesService implements CommonCRUDService<Site, CommonIndexedPagination> {
  constructor(
    @Inject('environment') private readonly environment,
    private readonly httpClient: HttpClient
  ) {}

  getAll(request?: CommonApiRequest): Observable<CommonApiResponse<Site, CommonIndexedPagination>> {
    let params: HttpParams = ApiHelpers.getHttpParams(request);
    params = params.set('type', 'customer_site');
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.sites}`, { params })
      .pipe(map((response: CommonApiListResponse<Site>) => ApiHelpers.getHttpResponse<Site>(response)));
  }

  getById(id: string): Observable<Site> {
    return this.httpClient.get<Site>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${id}`);
  }

  addOne(site: Site): Observable<Site> {
    return this.httpClient.post<Site>(`${this.environment.api.url}${this.environment.api.endpoints.sites}`, site);
  }

  updateOne(site: Site): Observable<Site> {
    // These are the fields accepted in the backend side
    return this.httpClient.put<Site>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${site.id}`, {
      displayName: site.displayName,
      checkInThreshold: site.checkInThreshold,
      checkOutThreshold: site.checkOutThreshold,
      timeBeforeNotDetected: site.timeBeforeNotDetected,
      thresholdFull: site.thresholdFull,
      address: site.address,
      description: site.description
    });
  }

  deleteOne(site: Site): Observable<Site> {
    return this.httpClient.delete<Site>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${site.id}`);
  }
}
