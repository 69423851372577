// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.optional-properties__label, .optional-properties__options {
  padding-left: 10%;
}
.optional-properties__label {
  padding-bottom: 1rem;
  font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm9wdGlvbmFsLXByb3BlcnRpZXMtZm9ybS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUVFLGlCQUFBO0FBREo7QUFJRTtFQUNFLG9CQUFBO0VBQ0EsZ0JBQUE7QUFGSiIsImZpbGUiOiJvcHRpb25hbC1wcm9wZXJ0aWVzLWZvcm0uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIub3B0aW9uYWwtcHJvcGVydGllcyB7XHJcbiAgJl9fbGFiZWwsXHJcbiAgJl9fb3B0aW9ucyB7XHJcbiAgICBwYWRkaW5nLWxlZnQ6IDEwJTtcclxuICB9XHJcblxyXG4gICZfX2xhYmVsIHtcclxuICAgIHBhZGRpbmctYm90dG9tOiAxcmVtO1xyXG4gICAgZm9udC13ZWlnaHQ6IDYwMDtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-asset/src/lib/optional-properties-form/optional-properties-form.component.scss"],"names":[],"mappings":"AACE;EAEE,iBAAA;AADJ;AAIE;EACE,oBAAA;EACA,gBAAA;AAFJ;AACA,gkBAAgkB","sourcesContent":[".optional-properties {\r\n  &__label,\r\n  &__options {\r\n    padding-left: 10%;\r\n  }\r\n\r\n  &__label {\r\n    padding-bottom: 1rem;\r\n    font-weight: 600;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
