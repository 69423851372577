// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  width: 100%;
}

.feature-master-view__engine-container {
  position: relative;
}
.feature-master-view__drawer-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.feature-master-view__drawer-container .mat-sidenav {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1019607843);
  margin-top: 1px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hc3Rlci12aWV3LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtFQUNBLFdBQUE7QUFDRjs7QUFHRTtFQUNFLGtCQUFBO0FBQUo7QUFHRTtFQUNFLGtCQUFBO0VBQ0EsTUFBQTtFQUNBLFNBQUE7RUFDQSxPQUFBO0VBQ0EsUUFBQTtBQURKO0FBR0k7RUFDRSxrREFBQTtFQUNBLGVBQUE7QUFETiIsImZpbGUiOiJtYXN0ZXItdmlldy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcclxuICBoZWlnaHQ6IDEwMCU7XHJcbiAgd2lkdGg6IDEwMCU7XHJcbn1cclxuXHJcbi5mZWF0dXJlLW1hc3Rlci12aWV3IHtcclxuICAmX19lbmdpbmUtY29udGFpbmVyIHtcclxuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcclxuICB9XHJcblxyXG4gICZfX2RyYXdlci1jb250YWluZXIge1xyXG4gICAgcG9zaXRpb246IGFic29sdXRlO1xyXG4gICAgdG9wOiAwO1xyXG4gICAgYm90dG9tOiAwO1xyXG4gICAgbGVmdDogMDtcclxuICAgIHJpZ2h0OiAwO1xyXG5cclxuICAgIC5tYXQtc2lkZW5hdiB7XHJcbiAgICAgIGJveC1zaGFkb3c6IDAgNHB4IDI0cHggIzAwMDAwMDFhO1xyXG4gICAgICBtYXJnaW4tdG9wOiAxcHg7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-feature-master-view/src/lib/containers/master-view/master-view.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;;AAGE;EACE,kBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AADJ;AAGI;EACE,kDAAA;EACA,eAAA;AADN;AACA,46BAA46B","sourcesContent":[":host {\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n.feature-master-view {\r\n  &__engine-container {\r\n    position: relative;\r\n  }\r\n\r\n  &__drawer-container {\r\n    position: absolute;\r\n    top: 0;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n\r\n    .mat-sidenav {\r\n      box-shadow: 0 4px 24px #0000001a;\r\n      margin-top: 1px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
