// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-details-widget__label {
  font-weight: 600;
}
.card-details-widget__value a, .card-details-widget__label a {
  text-decoration: none;
  color: #66aef2;
}
.card-details-widget__value a:hover, .card-details-widget__label a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
.card-details-widget__list-items li {
  line-height: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhcmQtZGV0YWlscy1tZXRhZGF0YS13aWRnZXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxnQkFBQTtBQUFKO0FBSUk7RUFDRSxxQkFBQTtFQUNBLGNBQUE7QUFGTjtBQUdNO0VBQ0UscUNBQUE7RUFDQSxlQUFBO0FBRFI7QUFNSTtFQUNFLG1CQUFBO0VBQ0EsdUJBQUE7RUFDQSxtQkFBQTtFQUNBLGdCQUFBO0FBSk4iLCJmaWxlIjoiY2FyZC1kZXRhaWxzLW1ldGFkYXRhLXdpZGdldC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jYXJkLWRldGFpbHMtd2lkZ2V0IHtcclxuICAmX19sYWJlbCB7XHJcbiAgICBmb250LXdlaWdodDogNjAwO1xyXG4gIH1cclxuICAmX192YWx1ZSxcclxuICAmX19sYWJlbCB7XHJcbiAgICBhIHtcclxuICAgICAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xyXG4gICAgICBjb2xvcjogIzY2YWVmMjtcclxuICAgICAgJjpob3ZlciB7XHJcbiAgICAgICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmUgIWltcG9ydGFudDtcclxuICAgICAgICBjdXJzb3I6IHBvaW50ZXI7XHJcbiAgICAgIH1cclxuICAgIH1cclxuICB9XHJcbiAgJl9fbGlzdC1pdGVtcyB7XHJcbiAgICBsaSB7XHJcbiAgICAgIGxpbmUtaGVpZ2h0OiAxLjVyZW07XHJcbiAgICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xyXG4gICAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xyXG4gICAgICBvdmVyZmxvdzogaGlkZGVuO1xyXG4gICAgfVxyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/card-details-widget/card-details-metadata-widget/card-details-metadata-widget.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAII;EACE,qBAAA;EACA,cAAA;AAFN;AAGM;EACE,qCAAA;EACA,eAAA;AADR;AAMI;EACE,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAJN;AACA,okCAAokC","sourcesContent":[".card-details-widget {\r\n  &__label {\r\n    font-weight: 600;\r\n  }\r\n  &__value,\r\n  &__label {\r\n    a {\r\n      text-decoration: none;\r\n      color: #66aef2;\r\n      &:hover {\r\n        text-decoration: underline !important;\r\n        cursor: pointer;\r\n      }\r\n    }\r\n  }\r\n  &__list-items {\r\n    li {\r\n      line-height: 1.5rem;\r\n      text-overflow: ellipsis;\r\n      white-space: nowrap;\r\n      overflow: hidden;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
