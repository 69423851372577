import { Component, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Product } from '@iot-platform/models/oyan';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'oyan-ui-product-edit-form',
  imports: [
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule
  ],
  templateUrl: './product-edit-form.component.html',
  styleUrls: []
})
export class ProductEditFormComponent implements OnInit {
  private initialFormState!: string;
  form: FormGroup = new FormGroup<{ productName: FormControl; displayName: FormControl }>({
    productName: new FormControl<string | null>(null),
    displayName: new FormControl<string | null>(null)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { product: Product },
    private readonly dialogRef: MatDialogRef<ProductEditFormComponent>
  ) {}

  get currentFormState(): string {
    return JSON.stringify(this.form?.getRawValue());
  }

  get formStateChanged(): boolean {
    return this.initialFormState !== this.currentFormState;
  }

  get product(): Product {
    return this.data.product;
  }

  get displayName(): AbstractControl {
    return this.form.get('displayName');
  }

  get productName(): AbstractControl {
    return this.form.get('productName');
  }

  ngOnInit(): void {
    if (this.product) {
      this.productName.setValue(this.product.name);
      this.productName.disable();
      this.displayName.setValue(this.product.displayName);
    }
    this.initialFormState = this.currentFormState;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.form.valid && this.formStateChanged) {
      this.dialogRef.close({
        ...this.product,
        displayName: this.displayName.getRawValue()
      });
    }
  }
}
