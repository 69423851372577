// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-item:hover .call-to-action {
  visibility: visible;
}
.widget-item .call-to-action {
  position: absolute;
  right: 0;
  top: 0.2rem;
  visibility: hidden;
  z-index: 1;
}
.widget-item .call-to-action .menu.active {
  visibility: visible;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndpZGdldC1pdGVtLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0VBQ0UsbUJBQUE7QUFETjtBQUlFO0VBQ0Usa0JBQUE7RUFDQSxRQUFBO0VBQ0EsV0FBQTtFQUNBLGtCQUFBO0VBQ0EsVUFBQTtBQUZKO0FBSUk7RUFDRSxtQkFBQTtBQUZOIiwiZmlsZSI6IndpZGdldC1pdGVtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLndpZGdldC1pdGVtIHtcclxuICAmOmhvdmVyIHtcclxuICAgIC5jYWxsLXRvLWFjdGlvbiB7XHJcbiAgICAgIHZpc2liaWxpdHk6IHZpc2libGU7XHJcbiAgICB9XHJcbiAgfVxyXG4gIC5jYWxsLXRvLWFjdGlvbiB7XHJcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgICByaWdodDogMDtcclxuICAgIHRvcDogMC4ycmVtO1xyXG4gICAgdmlzaWJpbGl0eTogaGlkZGVuO1xyXG4gICAgei1pbmRleDogMTtcclxuXHJcbiAgICAmIC5tZW51LmFjdGl2ZSB7XHJcbiAgICAgIHZpc2liaWxpdHk6IHZpc2libGU7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/widgets/components/widget-item/widget-item.component.scss"],"names":[],"mappings":"AAEI;EACE,mBAAA;AADN;AAIE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;AAFJ;AAII;EACE,mBAAA;AAFN;AACA,4xBAA4xB","sourcesContent":[".widget-item {\r\n  &:hover {\r\n    .call-to-action {\r\n      visibility: visible;\r\n    }\r\n  }\r\n  .call-to-action {\r\n    position: absolute;\r\n    right: 0;\r\n    top: 0.2rem;\r\n    visibility: hidden;\r\n    z-index: 1;\r\n\r\n    & .menu.active {\r\n      visibility: visible;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
