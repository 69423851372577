// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: block;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.basic-click-cell {
  font-size: 12px;
  cursor: pointer;
  transition: all 300ms ease 0ms;
}
.basic-click-cell:hover {
  color: #8ed0ff;
  text-decoration: underline;
}
.basic-click-cell.active {
  color: #8ed0ff;
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFzc2V0LXZhcmlhYmxlLWNlbGwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0VBQ0EsbUJBQUE7RUFDQSxlQUFBO0VBQ0EsZ0JBQUE7RUFDQSxxQkFBQTtFQUNBLHVCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxlQUFBO0VBRUEsZUFBQTtFQUNBLDhCQUFBO0FBQUY7QUFFRTtFQUNFLGNBQUE7RUFDQSwwQkFBQTtBQUFKO0FBR0U7RUFDRSxjQUFBO0VBQ0EsMEJBQUE7QUFESiIsImZpbGUiOiJhc3NldC12YXJpYWJsZS1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLndyYXBwZXIge1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XHJcbiAgY3Vyc29yOiBwb2ludGVyO1xyXG4gIG92ZXJmbG93OiBoaWRkZW47XHJcbiAgd29yZC1icmVhazogYnJlYWstYWxsO1xyXG4gIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xyXG59XHJcblxyXG4uYmFzaWMtY2xpY2stY2VsbCB7XHJcbiAgZm9udC1zaXplOiAxMnB4O1xyXG5cclxuICBjdXJzb3I6IHBvaW50ZXI7XHJcbiAgdHJhbnNpdGlvbjogYWxsIDMwMG1zIGVhc2UgMG1zO1xyXG5cclxuICAmOmhvdmVyIHtcclxuICAgIGNvbG9yOiAjOGVkMGZmO1xyXG4gICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XHJcbiAgfVxyXG5cclxuICAmLmFjdGl2ZSB7XHJcbiAgICBjb2xvcjogIzhlZDBmZjtcclxuICAgIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/asset-variable-cell/asset-variable-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EAEA,eAAA;EACA,8BAAA;AAAF;AAEE;EACE,cAAA;EACA,0BAAA;AAAJ;AAGE;EACE,cAAA;EACA,0BAAA;AADJ;AACA,4kCAA4kC","sourcesContent":[".wrapper {\r\n  display: block;\r\n  align-items: center;\r\n  cursor: pointer;\r\n  overflow: hidden;\r\n  word-break: break-all;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.basic-click-cell {\r\n  font-size: 12px;\r\n\r\n  cursor: pointer;\r\n  transition: all 300ms ease 0ms;\r\n\r\n  &:hover {\r\n    color: #8ed0ff;\r\n    text-decoration: underline;\r\n  }\r\n\r\n  &.active {\r\n    color: #8ed0ff;\r\n    text-decoration: underline;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
