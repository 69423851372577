// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-platform-ui-page-title-card {
  padding: 8px;
}
.iot-platform-ui-page-title-card__label {
  line-height: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhZ2UtdGl0bGUtY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7QUFDRjtBQUFFO0VBQ0UsaUJBQUE7QUFFSiIsImZpbGUiOiJwYWdlLXRpdGxlLWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaW90LXBsYXRmb3JtLXVpLXBhZ2UtdGl0bGUtY2FyZCB7XHJcbiAgcGFkZGluZzogOHB4O1xyXG4gICZfX2xhYmVsIHtcclxuICAgIGxpbmUtaGVpZ2h0OiAxNnB4O1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/cards/page-title-card/page-title-card.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AACA,gcAAgc","sourcesContent":[".iot-platform-ui-page-title-card {\r\n  padding: 8px;\r\n  &__label {\r\n    line-height: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
