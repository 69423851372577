// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .button-cell {
        height: 30px;
        padding: 0 0.5rem;
      }

      .label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        font-size: 0.8rem;
      }
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ1dHRvbi1jZWxsLmNvbXBvbmVudC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO01BQ007UUFDRSxZQUFZO1FBQ1osaUJBQWlCO01BQ25COztNQUVBO1FBQ0UsdUJBQXVCO1FBQ3ZCLG1CQUFtQjtRQUNuQixnQkFBZ0I7UUFDaEIsY0FBYztRQUNkLGlCQUFpQjtNQUNuQiIsImZpbGUiOiJidXR0b24tY2VsbC5jb21wb25lbnQudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICAgIC5idXR0b24tY2VsbCB7XG4gICAgICAgIGhlaWdodDogMzBweDtcbiAgICAgICAgcGFkZGluZzogMCAwLjVyZW07XG4gICAgICB9XG5cbiAgICAgIC5sYWJlbCB7XG4gICAgICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuICAgICAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgICAgICBvdmVyZmxvdzogaGlkZGVuO1xuICAgICAgICBkaXNwbGF5OiBibG9jaztcbiAgICAgICAgZm9udC1zaXplOiAwLjhyZW07XG4gICAgICB9XG4gICAgIl19 */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/button-cell/button-cell.component.ts"],"names":[],"mappings":";MACM;QACE,YAAY;QACZ,iBAAiB;MACnB;;MAEA;QACE,uBAAuB;QACvB,mBAAmB;QACnB,gBAAgB;QAChB,cAAc;QACd,iBAAiB;MACnB;;AAEN,4sBAA4sB","sourcesContent":["\n      .button-cell {\n        height: 30px;\n        padding: 0 0.5rem;\n      }\n\n      .label {\n        text-overflow: ellipsis;\n        white-space: nowrap;\n        overflow: hidden;\n        display: block;\n        font-size: 0.8rem;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
