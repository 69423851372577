// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ccf-icon {
  width: 16px;
  height: 60px;
}

.grid-icon-cell {
  font-size: 16px;
  width: 28px;
  height: 28px;
  line-height: 20px;
}
.grid-icon-cell .grid-icon {
  font-size: 14px;
  width: 18px;
  height: 18px;
  line-height: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN5Y2xpY2FsLWNhbGwtZmF1bHQtY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFdBQUE7RUFDQSxZQUFBO0FBQ0Y7O0FBRUE7RUFDRSxlQUFBO0VBQ0EsV0FBQTtFQUNBLFlBQUE7RUFDQSxpQkFBQTtBQUNGO0FBQ0U7RUFDRSxlQUFBO0VBQ0EsV0FBQTtFQUNBLFlBQUE7RUFDQSxpQkFBQTtBQUNKIiwiZmlsZSI6ImN5Y2xpY2FsLWNhbGwtZmF1bHQtY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jY2YtaWNvbiB7XHJcbiAgd2lkdGg6IDE2cHg7XHJcbiAgaGVpZ2h0OiA2MHB4O1xyXG59XHJcblxyXG4uZ3JpZC1pY29uLWNlbGwge1xyXG4gIGZvbnQtc2l6ZTogMTZweDtcclxuICB3aWR0aDogMjhweDtcclxuICBoZWlnaHQ6IDI4cHg7XHJcbiAgbGluZS1oZWlnaHQ6IDIwcHg7XHJcblxyXG4gIC5ncmlkLWljb24ge1xyXG4gICAgZm9udC1zaXplOiAxNHB4O1xyXG4gICAgd2lkdGg6IDE4cHg7XHJcbiAgICBoZWlnaHQ6IDE4cHg7XHJcbiAgICBsaW5lLWhlaWdodDogMjBweDtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/cyclical-call-fault-cell/cyclical-call-fault-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACF;AACE;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACJ;AACA,g0BAAg0B","sourcesContent":[".ccf-icon {\r\n  width: 16px;\r\n  height: 60px;\r\n}\r\n\r\n.grid-icon-cell {\r\n  font-size: 16px;\r\n  width: 28px;\r\n  height: 28px;\r\n  line-height: 20px;\r\n\r\n  .grid-icon {\r\n    font-size: 14px;\r\n    width: 18px;\r\n    height: 18px;\r\n    line-height: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
