import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseUser } from '@iot-platform/models/common';

@Component({
    selector: 'oyan-ui-users-user-profile-info',
    templateUrl: './user-profile-info.component.html',
    styleUrls: ['./user-profile-info.component.scss'],
    standalone: false
})
export class UserProfileInfoComponent {
  @Input() currentUser: BaseUser;
  @Output() editUserProfile: EventEmitter<void> = new EventEmitter<void>();
}
