// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-cell {
  opacity: 0.2;
  transition: all 300ms ease 0ms;
  cursor: pointer;
}
.comment-cell:hover {
  opacity: 1;
  color: #8ed0ff;
  text-decoration: underline;
}
.comment-cell.has-comment {
  opacity: 1;
}
.comment-cell.has-comment .comment-value {
  visibility: visible;
}
.comment-cell .comment-value {
  visibility: hidden;
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbW1lbnQtY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSw4QkFBQTtFQUNBLGVBQUE7QUFDRjtBQUNFO0VBQ0UsVUFBQTtFQUNBLGNBQUE7RUFDQSwwQkFBQTtBQUNKO0FBRUU7RUFDRSxVQUFBO0FBQUo7QUFFSTtFQUNFLG1CQUFBO0FBQU47QUFHRTtFQUNFLGtCQUFBO0VBQ0EsZUFBQTtBQURKIiwiZmlsZSI6ImNvbW1lbnQtY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb21tZW50LWNlbGwge1xyXG4gIG9wYWNpdHk6IDAuMjtcclxuICB0cmFuc2l0aW9uOiBhbGwgMzAwbXMgZWFzZSAwbXM7XHJcbiAgY3Vyc29yOiBwb2ludGVyO1xyXG5cclxuICAmOmhvdmVyIHtcclxuICAgIG9wYWNpdHk6IDE7XHJcbiAgICBjb2xvcjogIzhlZDBmZjtcclxuICAgIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xyXG4gIH1cclxuXHJcbiAgJi5oYXMtY29tbWVudCB7XHJcbiAgICBvcGFjaXR5OiAxO1xyXG5cclxuICAgIC5jb21tZW50LXZhbHVlIHtcclxuICAgICAgdmlzaWJpbGl0eTogdmlzaWJsZTtcclxuICAgIH1cclxuICB9XHJcbiAgLmNvbW1lbnQtdmFsdWUge1xyXG4gICAgdmlzaWJpbGl0eTogaGlkZGVuO1xyXG4gICAgZm9udC1zaXplOiAxMnB4O1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/comment-cell/comment-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,8BAAA;EACA,eAAA;AACF;AACE;EACE,UAAA;EACA,cAAA;EACA,0BAAA;AACJ;AAEE;EACE,UAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAGE;EACE,kBAAA;EACA,eAAA;AADJ;AACA,o9BAAo9B","sourcesContent":[".comment-cell {\r\n  opacity: 0.2;\r\n  transition: all 300ms ease 0ms;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    opacity: 1;\r\n    color: #8ed0ff;\r\n    text-decoration: underline;\r\n  }\r\n\r\n  &.has-comment {\r\n    opacity: 1;\r\n\r\n    .comment-value {\r\n      visibility: visible;\r\n    }\r\n  }\r\n  .comment-value {\r\n    visibility: hidden;\r\n    font-size: 12px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
