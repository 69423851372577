// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.number-cell-value {
  display: block;
  padding: 2px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
  word-break: break-all;
  color: #394c5a;
}
.number-cell-value.link {
  cursor: pointer;
  transition: all 300ms ease 0ms;
}
.number-cell-value.link:hover {
  color: #8ed0ff;
  text-decoration: underline;
}
.number-cell-value.link.active {
  color: #8ed0ff;
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm51bWJlci1jZWxsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtFQUNBLFlBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxvQ0FBQTtFQUNBLHFCQUFBO0VBQ0EsY0FBQTtBQUNGO0FBQ0U7RUFDRSxlQUFBO0VBQ0EsOEJBQUE7QUFDSjtBQUNJO0VBQ0UsY0FBQTtFQUNBLDBCQUFBO0FBQ047QUFFSTtFQUNFLGNBQUE7RUFDQSwwQkFBQTtBQUFOIiwiZmlsZSI6Im51bWJlci1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm51bWJlci1jZWxsLXZhbHVlIHtcclxuICBkaXNwbGF5OiBibG9jaztcclxuICBwYWRkaW5nOiAycHg7XHJcbiAgZm9udC1zaXplOiAxMnB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAxNnB4O1xyXG4gIGZvbnQtZmFtaWx5OiAnT3BlbiBTYW5zJywgc2Fucy1zZXJpZjtcclxuICB3b3JkLWJyZWFrOiBicmVhay1hbGw7XHJcbiAgY29sb3I6ICMzOTRjNWE7XHJcblxyXG4gICYubGluayB7XHJcbiAgICBjdXJzb3I6IHBvaW50ZXI7XHJcbiAgICB0cmFuc2l0aW9uOiBhbGwgMzAwbXMgZWFzZSAwbXM7XHJcblxyXG4gICAgJjpob3ZlciB7XHJcbiAgICAgIGNvbG9yOiAjOGVkMGZmO1xyXG4gICAgICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTtcclxuICAgIH1cclxuXHJcbiAgICAmLmFjdGl2ZSB7XHJcbiAgICAgIGNvbG9yOiAjOGVkMGZmO1xyXG4gICAgICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTtcclxuICAgIH1cclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/number-cell/number-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,qBAAA;EACA,cAAA;AACF;AACE;EACE,eAAA;EACA,8BAAA;AACJ;AACI;EACE,cAAA;EACA,0BAAA;AACN;AAEI;EACE,cAAA;EACA,0BAAA;AAAN;AACA,olCAAolC","sourcesContent":[".number-cell-value {\r\n  display: block;\r\n  padding: 2px;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  word-break: break-all;\r\n  color: #394c5a;\r\n\r\n  &.link {\r\n    cursor: pointer;\r\n    transition: all 300ms ease 0ms;\r\n\r\n    &:hover {\r\n      color: #8ed0ff;\r\n      text-decoration: underline;\r\n    }\r\n\r\n    &.active {\r\n      color: #8ed0ff;\r\n      text-decoration: underline;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
