// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-icon-content {
  vertical-align: middle;
}
.table-icon-content.icon-active, .table-icon-content.icon-closed, .table-icon-content.icon-acknowledged, .table-icon-content.icon-snoozed {
  height: 20px;
  width: 20px;
  line-height: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImljb24tY2VsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHNCQUFBO0FBQ0Y7QUFDRTtFQUlFLFlBQUE7RUFDQSxXQUFBO0VBQ0EsaUJBQUE7QUFGSiIsImZpbGUiOiJpY29uLWNlbGwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudGFibGUtaWNvbi1jb250ZW50IHtcclxuICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO1xyXG5cclxuICAmLmljb24tYWN0aXZlLFxyXG4gICYuaWNvbi1jbG9zZWQsXHJcbiAgJi5pY29uLWFja25vd2xlZGdlZCxcclxuICAmLmljb24tc25vb3plZCB7XHJcbiAgICBoZWlnaHQ6IDIwcHg7XHJcbiAgICB3aWR0aDogMjBweDtcclxuICAgIGxpbmUtaGVpZ2h0OiAyMHB4O1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/icon-cell/icon-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACE;EAIE,YAAA;EACA,WAAA;EACA,iBAAA;AAFJ;AACA,omBAAomB","sourcesContent":[".table-icon-content {\r\n  vertical-align: middle;\r\n\r\n  &.icon-active,\r\n  &.icon-closed,\r\n  &.icon-acknowledged,\r\n  &.icon-snoozed {\r\n    height: 20px;\r\n    width: 20px;\r\n    line-height: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
