// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin-left {
  100% {
    transform: rotate(-360deg);
  }
}
.site-gateways .app-round-button--rotate {
  animation: spin-left 2s linear infinite;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpdGUtZ2F0ZXdheXMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBa0JBO0VBQ0U7SUFFRSwwQkFBQTtFQVBGO0FBQ0Y7QUFVQTtFQXRCRSx1Q0FBQTtBQWlCRiIsImZpbGUiOiJzaXRlLWdhdGV3YXlzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQG1peGluIHJvdGF0ZUxlZnQoKSB7XHJcbiAgLXdlYmtpdC1hbmltYXRpb246IHNwaW4tbGVmdCAycyBsaW5lYXIgaW5maW5pdGU7XHJcbiAgLW1vei1hbmltYXRpb246IHNwaW4tbGVmdCAycyBsaW5lYXIgaW5maW5pdGU7XHJcbiAgYW5pbWF0aW9uOiBzcGluLWxlZnQgMnMgbGluZWFyIGluZmluaXRlO1xyXG59XHJcblxyXG5ALW1vei1rZXlmcmFtZXMgc3Bpbi1sZWZ0IHtcclxuICAxMDAlIHtcclxuICAgIC1tb3otdHJhbnNmb3JtOiByb3RhdGUoLTM2MGRlZyk7XHJcbiAgfVxyXG59XHJcblxyXG5ALXdlYmtpdC1rZXlmcmFtZXMgc3Bpbi1sZWZ0IHtcclxuICAxMDAlIHtcclxuICAgIC13ZWJraXQtdHJhbnNmb3JtOiByb3RhdGUoLTM2MGRlZyk7XHJcbiAgfVxyXG59XHJcblxyXG5Aa2V5ZnJhbWVzIHNwaW4tbGVmdCB7XHJcbiAgMTAwJSB7XHJcbiAgICAtd2Via2l0LXRyYW5zZm9ybTogcm90YXRlKC0zNjBkZWcpO1xyXG4gICAgdHJhbnNmb3JtOiByb3RhdGUoLTM2MGRlZyk7XHJcbiAgfVxyXG59XHJcblxyXG4uc2l0ZS1nYXRld2F5cyAuYXBwLXJvdW5kLWJ1dHRvbi0tcm90YXRlIHtcclxuICBAaW5jbHVkZSByb3RhdGVMZWZ0KCk7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/oyan-ui/src/lib/features/sites/components/site-gateways/site-gateways.component.scss"],"names":[],"mappings":"AAkBA;EACE;IAEE,0BAAA;EAPF;AACF;AAUA;EAtBE,uCAAA;AAiBF;AAZA,wlCAAwlC","sourcesContent":["@mixin rotateLeft() {\r\n  -webkit-animation: spin-left 2s linear infinite;\r\n  -moz-animation: spin-left 2s linear infinite;\r\n  animation: spin-left 2s linear infinite;\r\n}\r\n\r\n@-moz-keyframes spin-left {\r\n  100% {\r\n    -moz-transform: rotate(-360deg);\r\n  }\r\n}\r\n\r\n@-webkit-keyframes spin-left {\r\n  100% {\r\n    -webkit-transform: rotate(-360deg);\r\n  }\r\n}\r\n\r\n@keyframes spin-left {\r\n  100% {\r\n    -webkit-transform: rotate(-360deg);\r\n    transform: rotate(-360deg);\r\n  }\r\n}\r\n\r\n.site-gateways .app-round-button--rotate {\r\n  @include rotateLeft();\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
