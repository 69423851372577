// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cdk-drag-drop-element-container {
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.cdk-drag-drop-element-container__drag-indicator, .cdk-drag-drop-element-container__drag-meta {
  padding: 0 5px;
}
.cdk-drag-drop-element-container--has-no-drag-indicator {
  cursor: move !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRyYWctZHJvcC1lbGVtZW50LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usa0JBQUE7RUFDQSxhQUFBO0VBQ0EsbUJBQUE7QUFDRjtBQUNFO0VBRUUsY0FBQTtBQUFKO0FBR0U7RUFDRSx1QkFBQTtBQURKIiwiZmlsZSI6ImRyYWctZHJvcC1lbGVtZW50LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNkay1kcmFnLWRyb3AtZWxlbWVudC1jb250YWluZXIge1xyXG4gIGJvcmRlci1yYWRpdXM6IDhweDtcclxuICBkaXNwbGF5OiBmbGV4O1xyXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XHJcblxyXG4gICZfX2RyYWctaW5kaWNhdG9yLFxyXG4gICZfX2RyYWctbWV0YSB7XHJcbiAgICBwYWRkaW5nOiAwIDVweDtcclxuICB9XHJcblxyXG4gICYtLWhhcy1uby1kcmFnLWluZGljYXRvciB7XHJcbiAgICBjdXJzb3I6IG1vdmUgIWltcG9ydGFudDtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/drag-and-drop/components/drag-drop-element/drag-drop-element.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EAEE,cAAA;AAAJ;AAGE;EACE,uBAAA;AADJ;AACA,4sBAA4sB","sourcesContent":[".cdk-drag-drop-element-container {\r\n  border-radius: 8px;\r\n  display: flex;\r\n  align-items: center;\r\n\r\n  &__drag-indicator,\r\n  &__drag-meta {\r\n    padding: 0 5px;\r\n  }\r\n\r\n  &--has-no-drag-indicator {\r\n    cursor: move !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
