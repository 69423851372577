import { ChangeDetectionStrategy, Component, inject, Inject, OnInit } from '@angular/core';
import { AuthFacade, fromAuth } from '@iot-platform/auth';
import { LocalStorageKeys } from '@iot-platform/core';
import { HashUtils } from '@iot-platform/iot-platform-utils';
import { BusinessProfile } from '@iot-platform/models/common';
import { AbstractAppShellComponent } from '@iot-platform/shared/components';
import { UserPreferencesService } from '@iot-platform/users';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AuthorizationService } from '../../features/auth/services/authorization.service';
import { NavigationsFacade } from '../../features/navigations/state/facades/navigations.facade';
import { OyanUiRoutingConstants } from '../../oyan-ui.router.constants';
import { AppConfig } from '../../services/config/app.config';
import { APP_CONFIG } from '../../services/config/config.module';

@Component({
    selector: 'oyan-ui-app-shell',
    templateUrl: './app-shell.component.html',
    styleUrls: ['./app-shell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppShellComponent extends AbstractAppShellComponent implements OnInit {
  eligibleBusinessProfiles$: Observable<BusinessProfile[]> = this.authFacade.eligibleBusinessProfiles$;
  sidenavState = {
    isOpen: this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY) ? this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY) === 'true' : false
  };
  appConfig: AppConfig;
  authorizationService: AuthorizationService = inject(AuthorizationService);

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly authFacade: AuthFacade,
    private readonly navigationsFacade: NavigationsFacade
  ) {
    super();
    this.appConfig = {
      ...this.config,
      front: { ...this.config.front, version: `v-${this.config.front.version}` },
      back: { ...this.config.back, version: `v-${this.config.back.version}` }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.authFacade.isBusinessProfileChanged$
      .pipe(
        filter((changed: boolean) => changed),
        takeUntil(this.destroyed$)
      )
      .subscribe((changed: boolean) => {
        this.navigationsFacade.onBusinessProfileChanged(changed);
      });
  }

  returnHome(): void {
    this.router.navigate(['/']);
  }

  onChangeBusinessProfile(selected: BusinessProfile): void {
    this.authFacade.selectBusinessProfile(selected, false);
  }

  openMyProfile(): void {
    this.router.navigate([`${OyanUiRoutingConstants.ADMIN}/${OyanUiRoutingConstants.PROFILE}`]);
  }

  closePreferences(): void {
    this.userPreferencesService.closePreferences();
  }

  setNavigationTree(): void {
    combineLatest([this.navigationTreeService.getNavigationTree(), this.store.select(fromAuth.selectPrivileges)])
      .pipe(
        map(([navTree, _]) =>
          navTree.map((block) => {
            block.items.forEach((item) => {
              item.level = 0;
              item.submenu = [];
            });
            return { ...block };
          })
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((navTree) => {
        navTree = navTree.map((i) => ({ ...i, id: HashUtils.simpleHashFromObj(i) }));
        this.navTree = this.authorizationService.getNavigationTree(navTree);
      });
  }
}
