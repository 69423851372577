import { BaseState, BusinessProfile, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminBusinessProfilesActions } from '../actions';

export const featureKey = 'adminBusinessProfiles';

export interface State extends BaseState<BusinessProfile, CommonIndexedPagination, Filter> {
  selectedBusinessProfile: BusinessProfile;
}

export const adapter: EntityAdapter<BusinessProfile> = createEntityAdapter<BusinessProfile>({
  selectId: (entity: BusinessProfile) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  selectedBusinessProfile: null,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,

  on(AdminBusinessProfilesActions.setUserBusinessProfiles, (state: State, { businessProfiles }): State => adapter.setAll(businessProfiles, state)),

  on(AdminBusinessProfilesActions.linkBusinessProfileToUserSuccess, (state: State, { businessProfile }): State => adapter.addOne(businessProfile, state)),

  on(
    AdminBusinessProfilesActions.unlinkBusinessProfileFromUserSuccess,
    (state: State, { businessProfile }): State => adapter.removeOne(businessProfile.id, state)
  ),

  on(
    AdminBusinessProfilesActions.linkBusinessProfileToUserFailure,
    AdminBusinessProfilesActions.unlinkBusinessProfileFromUserFailure,
    (state: State, { error }): State => ({
      ...state,
      error
    })
  ),

  on(
    AdminBusinessProfilesActions.setSelectedBusinessProfile,
    (state: State, { businessProfile }): State => ({
      ...state,
      selectedBusinessProfile: businessProfile
    })
  )
);
