// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  padding: 12px 16px 6px 16px;
  line-height: 15px;
  font-size: 10px;
  letter-spacing: 0.25px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  gap: 6px;
}

.flex-1 {
  flex: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpZGVuYXYtYmxvY2suY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSwyQkFBQTtFQUNBLGlCQUFBO0VBQ0EsZUFBQTtFQUNBLHNCQUFBO0VBQ0EsbUJBQUE7RUFDQSxzQkFBQTtFQUNBLGFBQUE7RUFDQSxnQ0FBQTtFQUNBLG1CQUFBO0VBQ0EsUUFBQTtBQUNGOztBQUVBO0VBQ0UsT0FBQTtBQUNGIiwiZmlsZSI6InNpZGVuYXYtYmxvY2suY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJoZWFkZXIge1xyXG4gIHBhZGRpbmc6IDEycHggMTZweCA2cHggMTZweDtcclxuICBsaW5lLWhlaWdodDogMTVweDtcclxuICBmb250LXNpemU6IDEwcHg7XHJcbiAgbGV0dGVyLXNwYWNpbmc6IDAuMjVweDtcclxuICBmbGV4LWRpcmVjdGlvbjogcm93O1xyXG4gIGJveC1zaXppbmc6IGJvcmRlci1ib3g7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBwbGFjZS1jb250ZW50OiBjZW50ZXIgZmxleC1zdGFydDtcclxuICBhbGlnbi1pdGVtczogY2VudGVyO1xyXG4gIGdhcDogNnB4O1xyXG59XHJcblxyXG4uZmxleC0xIHtcclxuICBmbGV4OiAxO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/sidenav/sidenav-block/sidenav-block.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,iBAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,gCAAA;EACA,mBAAA;EACA,QAAA;AACF;;AAEA;EACE,OAAA;AACF;AACA,g1BAAg1B","sourcesContent":["header {\r\n  padding: 12px 16px 6px 16px;\r\n  line-height: 15px;\r\n  font-size: 10px;\r\n  letter-spacing: 0.25px;\r\n  flex-direction: row;\r\n  box-sizing: border-box;\r\n  display: flex;\r\n  place-content: center flex-start;\r\n  align-items: center;\r\n  gap: 6px;\r\n}\r\n\r\n.flex-1 {\r\n  flex: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
