import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Message } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MessagesActions } from '../actions';

export const featureKey = 'messagesFeatureKey';

export type State = BaseState<Message, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  selectId: (entity: Message) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,

  on(
    MessagesActions.setFilters,
    (state: State, { filters }): State => ({
      ...state,
      filters: [...filters]
    })
  ),

  on(
    MessagesActions.loadMessages,
    (state: State, { request }): State => ({
      ...state,
      entity: null,
      filters: GetUtils.get(request, 'filters', [])
    })
  ),
  on(MessagesActions.loadMessagesSuccess, (state: State, { response }) => adapter.setAll(response.data, { ...state, pagination: response.pagination })),

  on(
    MessagesActions.loadMessages,
    (state: State): State => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(
    MessagesActions.loadMessagesSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    MessagesActions.loadMessagesFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
