import { Component, Input } from '@angular/core';
import { BaseCardComponent } from '@iot-platform/iot-platform-ui';
import { CardEventType, Contact } from '@iot-platform/models/common';

@Component({
    selector: 'oyan-ui-contact-card',
    templateUrl: './contact-card.component.html',
    styleUrls: ['./contact-card.component.scss'],
    standalone: false
})
export class ContactCardComponent extends BaseCardComponent<Contact> {
  @Input() canUpdate: boolean;
  @Input() canDelete: boolean;
  @Input() canUpdateSite: boolean;

  onEdit(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.UPDATE_CONTACT,
      data: this.element()
    });
  }

  onDelete(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.REMOVE_CONTACT,
      data: this.element()
    });
  }
}
