// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.threshold-form .asset-variable-threshold-position {
  padding-left: 10px;
  text-align: center;
}
.threshold-form__error {
  margin: 0 1rem;
  color: #bf5f69;
  text-transform: capitalize;
}

.threshold-color-round {
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.threshold-type-button-toggle {
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZhcmlhYmxlLXRocmVzaG9sZHMtZm9ybS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGtCQUFBO0VBQ0Esa0JBQUE7QUFBSjtBQUdFO0VBQ0UsY0FBQTtFQUNBLGNBQUE7RUFDQSwwQkFBQTtBQURKOztBQUtBO0VBQ0UsV0FBQTtFQUNBLFlBQUE7RUFDQSxrQkFBQTtBQUZGOztBQUtBO0VBQ0UsZUFBQTtBQUZGIiwiZmlsZSI6InZhcmlhYmxlLXRocmVzaG9sZHMtZm9ybS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi50aHJlc2hvbGQtZm9ybSB7XHJcbiAgLmFzc2V0LXZhcmlhYmxlLXRocmVzaG9sZC1wb3NpdGlvbiB7XHJcbiAgICBwYWRkaW5nLWxlZnQ6IDEwcHg7XHJcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbiAgfVxyXG5cclxuICAmX19lcnJvciB7XHJcbiAgICBtYXJnaW46IDAgMXJlbTtcclxuICAgIGNvbG9yOiAjYmY1ZjY5O1xyXG4gICAgdGV4dC10cmFuc2Zvcm06IGNhcGl0YWxpemU7XHJcbiAgfVxyXG59XHJcblxyXG4udGhyZXNob2xkLWNvbG9yLXJvdW5kIHtcclxuICB3aWR0aDogMTZweDtcclxuICBoZWlnaHQ6IDE2cHg7XHJcbiAgYm9yZGVyLXJhZGl1czogOHB4O1xyXG59XHJcblxyXG4udGhyZXNob2xkLXR5cGUtYnV0dG9uLXRvZ2dsZSB7XHJcbiAgZm9udC1zaXplOiAxMnB4O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-asset/src/lib/variable-thresholds-form/variable-thresholds-form.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,cAAA;EACA,cAAA;EACA,0BAAA;AADJ;;AAKA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAFF;;AAKA;EACE,eAAA;AAFF;AACA,49BAA49B","sourcesContent":[".threshold-form {\r\n  .asset-variable-threshold-position {\r\n    padding-left: 10px;\r\n    text-align: center;\r\n  }\r\n\r\n  &__error {\r\n    margin: 0 1rem;\r\n    color: #bf5f69;\r\n    text-transform: capitalize;\r\n  }\r\n}\r\n\r\n.threshold-color-round {\r\n  width: 16px;\r\n  height: 16px;\r\n  border-radius: 8px;\r\n}\r\n\r\n.threshold-type-button-toggle {\r\n  font-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
