import { Filter } from '@iot-platform/models/common';
import { createReducer, on } from '@ngrx/store';
import { AdminUsersApiActions, AdminUsersPageActions } from '../actions';

export const adminUsersPageFeatureKey = 'adminUsersPage';

export interface State {
  error: unknown;
  pending: boolean;
  currentFilters: Filter[];
}

export const initialState: State = {
  error: null,
  pending: false,
  currentFilters: []
};

export const reducer = createReducer(
  initialState,
  on(AdminUsersPageActions.listUsers, (state: State, { request }) => ({
    ...state,
    error: null,
    pending: true,
    currentFilters: request.filters ? request.filters : []
  })),
  on(AdminUsersApiActions.listUsersSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(AdminUsersApiActions.listUsersFailure, (state: State, { error }) => ({ ...state, error, pending: false, currentFilters: [] })),

  on(AdminUsersPageActions.updateUser, (state: State) => ({ ...state, pending: true })),
  on(AdminUsersApiActions.updateUserSuccess, (state: State) => ({ ...state, pending: false })),
  on(AdminUsersApiActions.updateUserFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(AdminUsersPageActions.getBusinessProfilesByUserId, (state: State) => ({ ...state, error: null, pending: true })),
  on(AdminUsersApiActions.getBusinessProfilesByUserIdSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(AdminUsersApiActions.getBusinessProfilesByUserIdFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(AdminUsersPageActions.deleteUser, (state: State) => ({ ...state, error: null, pending: true })),
  on(AdminUsersApiActions.deleteUserSuccess, (state: State) => ({ ...state, pending: false })),
  on(AdminUsersApiActions.deleteUserFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(AdminUsersPageActions.saveUsersFilters, (state: State, { filters }) => ({ ...state, currentFilters: filters }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getCurrentFilters = (state: State) => state.currentFilters;
