import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';

import { SharedModule } from '@iot-platform/shared';
import { MasterViewEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ReceiversShellComponent } from './containers/receivers-shell/receivers-shell.component';
import { ReceiversRoutingModule } from './receivers-routing.module';
import { ReceiversEffects } from './state/effects/receivers.effects';
import * as fromReceivers from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformUiModule,
    MasterViewEngineModule,
    ReceiversRoutingModule,
    StoreModule.forFeature(fromReceivers.featureKey, fromReceivers.reducers),
    EffectsModule.forFeature([ReceiversEffects])
  ],
  declarations: [ReceiversShellComponent]
})
export class ReceiversModule {}
