import { NgModule } from '@angular/core';
import {
  CardsContainerComponent,
  ContactCardComponent as BaseContactCardComponent,
  DefaultCardComponent,
  SimpleCardComponent
} from '@iot-platform/iot-platform-ui';
import { InfoDisplayPipe } from '@iot-platform/pipes';

import { SharedModule } from '@iot-platform/shared';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { GatewayCardComponent } from './components/gateway-card/gateway-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ThresholdCardComponent } from './components/threshold-card/threshold-card.component';

const COMPONENTS = [ProductCardComponent, ThresholdCardComponent, GatewayCardComponent, ContactCardComponent];

@NgModule({
  imports: [SharedModule, DefaultCardComponent, BaseContactCardComponent, CardsContainerComponent, SimpleCardComponent, InfoDisplayPipe],
  declarations: COMPONENTS,
  exports: [DefaultCardComponent, ...COMPONENTS, CardsContainerComponent, SimpleCardComponent]
})
export class CardsModule {}
