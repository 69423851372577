// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.count-cell-value {
  display: block;
  padding: 2px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
  word-break: break-all;
}
.count-cell-value.link {
  cursor: pointer;
  transition: all 300ms ease 0ms;
}
.count-cell-value.link:hover {
  color: #8ed0ff;
  text-decoration: underline;
}
.count-cell-value.link.active {
  color: #8ed0ff;
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvdW50LWNlbGwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0VBQ0EsWUFBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtFQUNBLG9DQUFBO0VBQ0EscUJBQUE7QUFDRjtBQUNFO0VBQ0UsZUFBQTtFQUNBLDhCQUFBO0FBQ0o7QUFDSTtFQUNFLGNBQUE7RUFDQSwwQkFBQTtBQUNOO0FBRUk7RUFDRSxjQUFBO0VBQ0EsMEJBQUE7QUFBTiIsImZpbGUiOiJjb3VudC1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvdW50LWNlbGwtdmFsdWUge1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIHBhZGRpbmc6IDJweDtcclxuICBmb250LXNpemU6IDEycHg7XHJcbiAgbGluZS1oZWlnaHQ6IDE2cHg7XHJcbiAgZm9udC1mYW1pbHk6ICdPcGVuIFNhbnMnLCBzYW5zLXNlcmlmO1xyXG4gIHdvcmQtYnJlYWs6IGJyZWFrLWFsbDtcclxuXHJcbiAgJi5saW5rIHtcclxuICAgIGN1cnNvcjogcG9pbnRlcjtcclxuICAgIHRyYW5zaXRpb246IGFsbCAzMDBtcyBlYXNlIDBtcztcclxuXHJcbiAgICAmOmhvdmVyIHtcclxuICAgICAgY29sb3I6ICM4ZWQwZmY7XHJcbiAgICAgIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xyXG4gICAgfVxyXG5cclxuICAgICYuYWN0aXZlIHtcclxuICAgICAgY29sb3I6ICM4ZWQwZmY7XHJcbiAgICAgIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xyXG4gICAgfVxyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/count-cell/count-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,qBAAA;AACF;AACE;EACE,eAAA;EACA,8BAAA;AACJ;AACI;EACE,cAAA;EACA,0BAAA;AACN;AAEI;EACE,cAAA;EACA,0BAAA;AAAN;AACA,wiCAAwiC","sourcesContent":[".count-cell-value {\r\n  display: block;\r\n  padding: 2px;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  word-break: break-all;\r\n\r\n  &.link {\r\n    cursor: pointer;\r\n    transition: all 300ms ease 0ms;\r\n\r\n    &:hover {\r\n      color: #8ed0ff;\r\n      text-decoration: underline;\r\n    }\r\n\r\n    &.active {\r\n      color: #8ed0ff;\r\n      text-decoration: underline;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
