// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-password-rules-container {
  margin: 10px 0;
  font-size: 12px;
  line-height: 20px;
  color: #b0b0b0;
}

.login-password-rules-rule {
  display: flex;
  align-items: center;
}
.login-password-rules-rule.checked {
  color: #a5d564;
}

.login-password-rules-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  font-size: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvZ2luLXBhc3N3b3JkLXJ1bGVzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtFQUNBLGNBQUE7QUFDRjs7QUFFQTtFQUNFLGFBQUE7RUFDQSxtQkFBQTtBQUNGO0FBQ0U7RUFDRSxjQUFBO0FBQ0o7O0FBR0E7RUFDRSxXQUFBO0VBQ0EsWUFBQTtFQUNBLGlCQUFBO0VBQ0EsZUFBQTtBQUFGIiwiZmlsZSI6ImxvZ2luLXBhc3N3b3JkLXJ1bGVzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmxvZ2luLXBhc3N3b3JkLXJ1bGVzLWNvbnRhaW5lciB7XHJcbiAgbWFyZ2luOiAxMHB4IDA7XHJcbiAgZm9udC1zaXplOiAxMnB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAyMHB4O1xyXG4gIGNvbG9yOiAjYjBiMGIwO1xyXG59XHJcblxyXG4ubG9naW4tcGFzc3dvcmQtcnVsZXMtcnVsZSB7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBhbGlnbi1pdGVtczogY2VudGVyO1xyXG5cclxuICAmLmNoZWNrZWQge1xyXG4gICAgY29sb3I6ICNhNWQ1NjQ7XHJcbiAgfVxyXG59XHJcblxyXG4ubG9naW4tcGFzc3dvcmQtcnVsZXMtaWNvbiB7XHJcbiAgd2lkdGg6IDIwcHg7XHJcbiAgaGVpZ2h0OiAyMHB4O1xyXG4gIG1hcmdpbi1yaWdodDogNHB4O1xyXG4gIGZvbnQtc2l6ZTogMjBweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/auth/src/lib/components/login-password-rules/login-password-rules.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;AACJ;;AAGA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AAAF;AACA,49BAA49B","sourcesContent":[".login-password-rules-container {\r\n  margin: 10px 0;\r\n  font-size: 12px;\r\n  line-height: 20px;\r\n  color: #b0b0b0;\r\n}\r\n\r\n.login-password-rules-rule {\r\n  display: flex;\r\n  align-items: center;\r\n\r\n  &.checked {\r\n    color: #a5d564;\r\n  }\r\n}\r\n\r\n.login-password-rules-icon {\r\n  width: 20px;\r\n  height: 20px;\r\n  margin-right: 4px;\r\n  font-size: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
