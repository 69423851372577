import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService as I4bAuthorizationService, fromAuth } from '@iot-platform/auth';
import { GridExportService } from '@iot-platform/grid-engine';
import {
  ADD_BUTTON_CONFIG,
  CLEAR_FILTERS_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  PopupComponent,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import {
  BaseUser,
  BusinessProfile,
  Environment,
  FavoriteView,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  Pagination,
  PlatformRequest,
  PlatformResponse,
  ToolbarSize,
  UserAccount
} from '@iot-platform/models/common';
import { User } from '@iot-platform/models/oyan';
import { fromUserPreferences, UserDetailDialogComponent, UserPreferencesService } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { BehaviorSubject, combineLatest, map, Observable, of, Subject, Subscription, withLatestFrom } from 'rxjs';
import { AuthorizationService } from '../../../auth/services/authorization.service';
import { OyanAuthorizationConcept, OyanAuthorizationType } from '../../../auth/types/authorization.types';
import { UserFormDialogComponent } from '../../components/user-form-dialog/user-form-dialog.component';
import { UserLinkBusinessProfileDialogComponent } from '../../components/user-link-business-profile-dialog/user-link-business-profile-dialog.component';
import { AdminBusinessProfilesActions, AdminUsersPageActions } from '../../state/actions';
import { AdminBusinessProfilesFacade } from '../../state/facades/admin-business-profiles.facade';
import { AdminUsersFacade } from '../../state/facades/admin-users.facade';
import * as fromUsers from '../../state/reducers';

@Component({
    selector: 'oyan-ui-admin-users',
    templateUrl: './admin-users.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AdminUsersComponent implements OnInit, OnDestroy {
  adminUsersButtonList!: IotToolbarDefaultButton[];
  users$: Observable<PlatformResponse> = of(null);
  totalUsers$: Observable<number> = of(0);
  businessProfiles$: Observable<BusinessProfile[]>;
  pagination$: Observable<Pagination>;
  pagination: Pagination;
  usersPending$: Observable<boolean>;

  currentFilters: Filter[] = [];
  currentFilters$: Observable<Filter[]>;
  currentFavoriteView$: Observable<FavoriteView> = of(null);
  filterEngineOpened = false;

  canUpdateUserStatus: boolean;
  canCreateUser: boolean;
  canUpdateUser: boolean;
  canDeleteUser: boolean;

  userPermissions;
  mvSettings$: Observable<unknown>;
  name$ = this.translateService.get('ADMIN.USERS.TOOLBAR_TITLE');

  user!: UserAccount;
  toolbarSize: string = ToolbarSize.SMALL;
  subscriptions: Subscription[] = [];

  canDeleteBusinessProfile: boolean;
  canCreateBusinessProfile: boolean;
  userPermissions$: BehaviorSubject<
    {
      key: string;
      value: boolean;
    }[]
  > = new BehaviorSubject([]);
  clearAppliedFilters$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected userPrefService: UserPreferencesService,
    protected translateService: TranslateService,
    protected store: Store,
    protected dialog: MatDialog,
    protected i4bAuthorizationService: I4bAuthorizationService,
    protected authorizationService: AuthorizationService,
    protected readonly adminBusinessProfilesFacade: AdminBusinessProfilesFacade,
    protected readonly adminUsersFacade: AdminUsersFacade,
    protected readonly gridExportService: GridExportService,
    @Inject('environment') protected readonly environment: Environment
  ) {
    this.mvSettings$ = this.userPrefService.loadSpecificDefaultSettingsWithDefaultColumnsOnly('users').pipe(
      map((setting) => ({
        ...setting,
        metadata: {
          url: '/users'
        }
      }))
    );

    this.initUserPermissions();
    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.users$ = this.store.select(fromUsers.getUsersAsPlatformResponse).pipe(map((response) => ({ ...response, maxPage: response.maxPage - 1 })));
    this.businessProfiles$ = this.store.select(fromUsers.getBusinessProfiles);
    this.totalUsers$ = this.store.select(fromUsers.getTotal);
    this.currentFilters$ = this.store.select(fromUsers.getCurrentFilters);
    this.pagination$ = this.store.select(fromUsers.getPagination);
    this.totalUsers$ = this.pagination$.pipe(map((pagination) => pagination.total));
    this.usersPending$ = this.store.select(fromUsers.getUsersPagePending);

    this.subscriptions.push(
      combineLatest([this.currentFilters$, this.store.select(fromAuth.selectAccount)])
        .pipe(withLatestFrom(this.pagination$))
        .subscribe(([[filters, userAccount], pagination]) => {
          if (userAccount) {
            this.currentFilters = filters ?? [];
            this.store.dispatch(AdminUsersPageActions.listUsers({ request: { page: 0, limit: pagination.limit, filters: this.currentFilters } }));
            this.store.dispatch(AdminUsersPageActions.getVisibleEntityIds());

            this.adminUsersButtonList.map((button) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
              if (button.dispatchAction.type === IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA) {
                button.disabled = !this.currentFilters.length;
              }
              return button;
            });
          }
        }),
      this.usersPending$.subscribe((pending) => {
        this.adminUsersButtonList.map((button) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          if (button.dispatchAction.type === IotToolbarDispatchActionType.REFRESH_PAGE) {
            button.disabled = pending;
          }
          return button;
        });
      }),
      this.store.select(fromUserPreferences.getCurrentUser).subscribe((user) => {
        if (user && user.id) {
          this.user = user;
          this.filterEngineOpened = get(user, 'preferences.filterEngineOpenByDefault', false);
        }
      })
    );
  }

  initToolbarButtonList(): void {
    this.adminUsersButtonList = [
      new IotToolbarDefaultButton({ ...ADD_BUTTON_CONFIG, displayButton: this.canCreateUser }, 0),
      new IotToolbarDefaultButton(
        {
          ...CLEAR_FILTERS_BUTTON_CONFIG,
          tooltip: 'MV_TOOLBAR.TOOLTIP.CLEAR_APPLIED_FILTERS'
        },
        1
      ),
      new IotToolbarDefaultButton(
        {
          ...TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
          tooltip: 'MV_TOOLBAR.TOOLTIP.SHOW_FILTER_ENGINE'
        },
        2
      ),
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.REFRESH_MV' }, 3)
    ];
  }

  initUserPermissions(): void {
    this.canUpdateUserStatus = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.USER_STATUS, OyanAuthorizationType.UPDATE);
    this.canUpdateUser = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.USER, OyanAuthorizationType.UPDATE);
    this.canCreateUser = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.USER, OyanAuthorizationType.CREATE);
    this.canDeleteUser = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.USER, OyanAuthorizationType.DELETE);
    this.canCreateBusinessProfile = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.BUSINESS_PROFILE, OyanAuthorizationType.CREATE);
    this.canDeleteBusinessProfile = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.BUSINESS_PROFILE, OyanAuthorizationType.DELETE);
    this.userPermissions$.next([
      { key: 'canUpdateUserStatus', value: this.canUpdateUserStatus },
      { key: 'canCreateUser', value: this.canCreateUser },
      { key: 'canDeleteUser', value: this.canDeleteUser },
      { key: 'canUpdateUser', value: this.canUpdateUser },
      { key: 'canCreateBusinessProfile', value: this.canCreateBusinessProfile },
      { key: 'canDeleteBusinessProfile', value: this.canDeleteBusinessProfile }
    ]);
  }

  onDispatchMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'open':
        this.store.dispatch(AdminUsersPageActions.selectUser({ selectedUserId: event.rawData.id }));
        this.store.dispatch(AdminUsersPageActions.getBusinessProfilesByUserId({ userId: event.rawData.id }));
        this.openUserDetail(event.rawData);
        break;
      case 'navigate':
        this.store.dispatch(AdminUsersPageActions.navigateToUser({ selectedUserId: event.rawData.id }));
        break;
      case 'enableUser':
        this.onChangeUserStatus(event.options.element);
        break;
      case 'disableUser':
        this.onChangeUserStatus(event.options.element);
        break;
      case 'resetUser':
        this.onResetUser(event.options.element);
        break;
      case 'deleteUser':
        this.onDeleteUser(event.options.element);
        break;
      case 'configureUser':
        this.store.dispatch(AdminUsersPageActions.navigateToUser({ selectedUserId: event.rawData.id }));
        break;
      case 'linkBusinessProfile':
        this.adminBusinessProfilesFacade.getAll();
        this.adminUsersFacade.getBusinessProfilesByUserId(event.options.element.id);
        this.linkBusinessProfiles(event.options.element);
        break;
      case 'updateUser':
        this.editUser(event.options.element);
        break;
      default:
        break;
    }
  }

  openUserDetail(user: BaseUser): void {
    this.dialog
      .open(UserDetailDialogComponent, {
        width: '700px',
        disableClose: false,
        data: { user }
      })
      .afterClosed()
      .subscribe((result: UserAccount) => {
        if (result) {
          this.store.dispatch(AdminUsersPageActions.navigateToUser({ selectedUserId: result.id }));
        }
      });
  }

  onDeleteUser(user: BaseUser) {
    this.subscriptions.push(
      this.dialog
        .open(PopupComponent, {
          width: '550px',
          disableClose: true,
          data: { type: 'delete', value: user.firstname + ' ' + user.lastname }
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.store.dispatch(AdminUsersPageActions.deleteUser({ user }));
          }
        })
    );
  }

  onChangeUserStatus(user: BaseUser): void {
    if (user.status.toLowerCase() === 'active') {
      this.store.dispatch(AdminUsersPageActions.disableUser({ user }));
    }

    if (user.status.toLowerCase() === 'disabled') {
      this.store.dispatch(AdminUsersPageActions.activateUser({ user }));
    }

    if (user.status.toLowerCase() === 'pending') {
      this.store.dispatch(AdminUsersPageActions.disableUser({ user }));
    }
  }

  onResetUser(user: BaseUser) {
    this.store.dispatch(AdminUsersPageActions.resetUser({ user }));
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.ADD_ELEMENT.toString():
        this.addUser();
        break;
      case IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA.toString():
        this.onClearAppliedFiltersClicked();
        break;
      case IotToolbarDispatchActionType.REFRESH_PAGE.toString():
        this.onRefreshClicked();
        break;
      case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE.toString():
        this.onShowFilter();
        break;
      default:
        break;
    }
  }

  onRefreshClicked(): void {
    this.reLoadMasterView();
  }

  onClearAppliedFiltersClicked(): void {
    this.clearAppliedFilters$.next(true);
    this.currentFilters = [];
    this.reLoadMasterView();
  }

  linkBusinessProfiles(user: BaseUser): void {
    this.store.dispatch(AdminBusinessProfilesActions.setUserBusinessProfiles({ businessProfiles: [] }));
    this.dialog.open(UserLinkBusinessProfileDialogComponent, {
      width: '1000px',
      disableClose: true,
      data: {
        user,
        canCreateBusinessProfile: this.canCreateBusinessProfile,
        canDeleteBusinessProfile: this.canDeleteBusinessProfile
      }
    });
  }

  addUser(): void {
    this.subscriptions.push(
      this.dialog
        .open(UserFormDialogComponent, {
          width: '800px',
          disableClose: true
        })
        .afterClosed()
        .subscribe((newUser) => {
          if (newUser) {
            this.adminUsersFacade.addUser(newUser);
          }
        })
    );
  }

  editUser(user: User): void {
    this.subscriptions.push(
      this.dialog
        .open(UserFormDialogComponent, {
          width: '800px',
          disableClose: true,
          data: {
            user
          }
        })
        .afterClosed()
        .subscribe((userToUpdate: User) => {
          if (userToUpdate) {
            this.adminUsersFacade.updateUser({
              ...user,
              ...userToUpdate
            });
          }
        })
    );
  }

  onApplyFilters(filters: Filter[]) {
    this.currentFilters = filters;
    this.store.dispatch(AdminUsersPageActions.saveUsersFilters({ filters }));
  }

  onExportData(): void {
    this.gridExportService.export({ filters: this.currentFilters });
  }

  onShowFilter(): void {
    this.filterEngineOpened = !this.filterEngineOpened;
  }

  loadMoreUsers(pagination: Pagination) {
    const request: PlatformRequest = {
      page: pagination.currentPage,
      limit: pagination.limit,
      filters: this.currentFilters
    };
    this.store.dispatch(AdminUsersPageActions.listUsers({ request }));
  }

  reLoadMasterView(): void {
    const request: PlatformRequest = {
      page: 0,
      limit: 100,
      filters: this.currentFilters
    };
    this.store.dispatch(AdminUsersPageActions.listUsers({ request }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s && s.unsubscribe());
  }
}
