// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-platform-ui-infos-display-card--has-type {
  padding: 14px;
  border-radius: 8px;
}
.iot-platform-ui-infos-display-card--active {
  border: 1px solid;
  border-left: 8px solid;
}
.iot-platform-ui-infos-display-card__label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZm9zLWRpc3BsYXktY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGFBQUE7RUFDQSxrQkFBQTtBQUFKO0FBR0U7RUFDRSxpQkFBQTtFQUNBLHNCQUFBO0FBREo7QUFJRTtFQUNFLGFBQUE7RUFDQSxtQkFBQTtFQUNBLHVCQUFBO0VBQ0Esa0JBQUE7QUFGSiIsImZpbGUiOiJpbmZvcy1kaXNwbGF5LWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaW90LXBsYXRmb3JtLXVpLWluZm9zLWRpc3BsYXktY2FyZCB7XHJcbiAgJi0taGFzLXR5cGUge1xyXG4gICAgcGFkZGluZzogMTRweDtcclxuICAgIGJvcmRlci1yYWRpdXM6IDhweDtcclxuICB9XHJcblxyXG4gICYtLWFjdGl2ZSB7XHJcbiAgICBib3JkZXI6IDFweCBzb2xpZDtcclxuICAgIGJvcmRlci1sZWZ0OiA4cHggc29saWQ7XHJcbiAgfVxyXG5cclxuICAmX19sYWJlbCB7XHJcbiAgICBkaXNwbGF5OiBmbGV4O1xyXG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcclxuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xyXG4gICAgYm9yZGVyLXJhZGl1czogNHB4O1xyXG4gIH1cclxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/cards/infos-display-card/infos-display-card.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,kBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,sBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAFJ;AACA,41BAA41B","sourcesContent":[".iot-platform-ui-infos-display-card {\r\n  &--has-type {\r\n    padding: 14px;\r\n    border-radius: 8px;\r\n  }\r\n\r\n  &--active {\r\n    border: 1px solid;\r\n    border-left: 8px solid;\r\n  }\r\n\r\n  &__label {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border-radius: 4px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
