import { Action, combineReducers } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as fromNavigations from './navigations.reducer';

export const featureKey = 'oyanNavigations';

export interface NavigationsState {
  [fromNavigations.featureKey]: fromNavigations.State;
}

export interface State {
  [featureKey]: NavigationsState;
}

export function reducers(state: NavigationsState, action: Action): ActionReducer<NavigationsState> {
  return combineReducers({
    [fromNavigations.featureKey]: fromNavigations.reducer
  })(state, action) as unknown as ActionReducer<NavigationsState>;
}
