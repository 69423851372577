import { Injectable } from '@angular/core';
import { User } from '@iot-platform/models/oyan';
import { Store } from '@ngrx/store';
import { AdminUsersPageActions } from '../actions';
import { UsersService } from '../../services/users.service';
import { Observable } from 'rxjs';
import { BusinessProfile, UserAccount } from '@iot-platform/models/common';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersFacade {
  constructor(
    private readonly store: Store,
    private readonly usersService: UsersService
  ) {}

  getBusinessProfilesByUserId(userId: string): void {
    this.store.dispatch(AdminUsersPageActions.getBusinessProfilesByUserId({ userId }));
  }

  getBusinessProfilesById(id: string): Observable<BusinessProfile[]> {
    return this.usersService.getBusinessProfilesByUserId(id);
  }

  getUserAccountById(id: string): Observable<UserAccount> {
    return this.usersService.getById(id);
  }

  addUser(user: User): void {
    this.store.dispatch(AdminUsersPageActions.addUser({ newUser: user }));
  }

  updateUser(user: User): void {
    this.store.dispatch(AdminUsersPageActions.updateUser({ updatedUser: user }));
  }
}
