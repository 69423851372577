import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OyanUiRoutingConstants } from '../../oyan-ui.router.constants';
import { AdminUsersComponent } from './containers/admin-users/admin-users.component';
import { UserProfileShellComponent } from './containers/user-profile-shell/user-profile-shell.component';

const routes: Routes = [
  { path: '', redirectTo: OyanUiRoutingConstants.USERS, pathMatch: 'full' },
  {
    path: OyanUiRoutingConstants.USERS,
    component: AdminUsersComponent
  },
  {
    path: OyanUiRoutingConstants.PROFILE,
    component: UserProfileShellComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRouterModule {}
