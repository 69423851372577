import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BuildInfoService } from '@iot-platform/iot-platform-ui';
import { BuildInfo, Environment } from '@iot-platform/models/common';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppConfig } from './app.config';
import { APP_CONFIG } from './config.module';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(
    @Inject('environment') private readonly environment: Environment,
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private readonly http: HttpClient,
    private readonly buildInfoService: BuildInfoService
  ) {}

  init(): Observable<AppConfig> {
    return forkJoin([this.buildInfoService.getBuildInfo(), this.getBackendVersion()]).pipe(
      map(([front, back]) => ({ front, back })),
      tap(({ front, back }) => {
        this.config.front = front;
        this.config.back = back;
      })
    );
  }

  getBackendVersion(): Observable<BuildInfo> {
    return this.http.get<BuildInfo>(`${this.environment.api.url}${this.environment.api.endpoints.backendVersion}`).pipe(catchError(() => of(this.config.back)));
  }
}
