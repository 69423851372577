// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.release-version {
  font-weight: bold;
  font-size: 20px;
  margin-right: 20px;
}

.release-feature {
  font-weight: bold;
}

.release-content {
  max-height: 340px;
  overflow-y: auto;
  padding-top: 20px;
  font-size: 12px;
}
.release-content:first-child {
  padding-top: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlbGVhc2Utbm90ZXMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxpQkFBQTtFQUNBLGVBQUE7RUFDQSxrQkFBQTtBQUNGOztBQUVBO0VBQ0UsaUJBQUE7QUFDRjs7QUFFQTtFQUNFLGlCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxpQkFBQTtFQUNBLGVBQUE7QUFDRjtBQUNFO0VBQ0UsY0FBQTtBQUNKIiwiZmlsZSI6InJlbGVhc2Utbm90ZXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucmVsZWFzZS12ZXJzaW9uIHtcclxuICBmb250LXdlaWdodDogYm9sZDtcclxuICBmb250LXNpemU6IDIwcHg7XHJcbiAgbWFyZ2luLXJpZ2h0OiAyMHB4O1xyXG59XHJcblxyXG4ucmVsZWFzZS1mZWF0dXJlIHtcclxuICBmb250LXdlaWdodDogYm9sZDtcclxufVxyXG5cclxuLnJlbGVhc2UtY29udGVudCB7XHJcbiAgbWF4LWhlaWdodDogMzQwcHg7XHJcbiAgb3ZlcmZsb3cteTogYXV0bztcclxuICBwYWRkaW5nLXRvcDogMjBweDtcclxuICBmb250LXNpemU6IDEycHg7XHJcblxyXG4gICY6Zmlyc3QtY2hpbGQge1xyXG4gICAgcGFkZGluZy10b3A6IDA7XHJcbiAgfVxyXG59Il19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/release-notes/release-notes.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AACF;AACE;EACE,cAAA;AACJ;AACA,41BAA41B","sourcesContent":[".release-version {\r\n  font-weight: bold;\r\n  font-size: 20px;\r\n  margin-right: 20px;\r\n}\r\n\r\n.release-feature {\r\n  font-weight: bold;\r\n}\r\n\r\n.release-content {\r\n  max-height: 340px;\r\n  overflow-y: auto;\r\n  padding-top: 20px;\r\n  font-size: 12px;\r\n\r\n  &:first-child {\r\n    padding-top: 0;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
