// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-info-container {
  overflow-y: auto;
  display: block;
  height: calc(100% - 48px);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItcHJvZmlsZS1zaGVsbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGdCQUFBO0VBQ0EsY0FBQTtFQUNBLHlCQUFBO0FBQ0YiLCJmaWxlIjoidXNlci1wcm9maWxlLXNoZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnVzZXItcHJvZmlsZS1pbmZvLWNvbnRhaW5lciB7XHJcbiAgb3ZlcmZsb3cteTogYXV0bztcclxuICBkaXNwbGF5OiBibG9jaztcclxuICBoZWlnaHQ6IGNhbGMoMTAwJSAtIDQ4cHgpO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/users/src/lib/features/profile/containers/user-profile-shell/user-profile-shell.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;EACA,yBAAA;AACF;AACA,gdAAgd","sourcesContent":[".user-profile-info-container {\r\n  overflow-y: auto;\r\n  display: block;\r\n  height: calc(100% - 48px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
