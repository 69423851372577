// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .translation-toolbar-export-button,
:host ::ng-deep .translation-toolbar-delete-language-button {
  margin-right: 0px !important;
}

.translation-toolbar-height {
  height: 48px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRyYW5zbGF0aW9uLXRvb2xiYXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7O0VBRUUsNEJBQUE7QUFBSjs7QUFJQTtFQUNFLFlBQUE7QUFERiIsImZpbGUiOiJ0cmFuc2xhdGlvbi10b29sYmFyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3QgOjpuZy1kZWVwIHtcclxuICAudHJhbnNsYXRpb24tdG9vbGJhci1leHBvcnQtYnV0dG9uLFxyXG4gIC50cmFuc2xhdGlvbi10b29sYmFyLWRlbGV0ZS1sYW5ndWFnZS1idXR0b24ge1xyXG4gICAgbWFyZ2luLXJpZ2h0OiAwcHggIWltcG9ydGFudDtcclxuICB9XHJcbn1cclxuXHJcbi50cmFuc2xhdGlvbi10b29sYmFyLWhlaWdodCB7XHJcbiAgaGVpZ2h0OiA0OHB4O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/i18n/src/lib/features/translation-management/components/translation-toolbar/translation-toolbar.component.scss"],"names":[],"mappings":"AACE;;EAEE,4BAAA;AAAJ;;AAIA;EACE,YAAA;AADF;AACA,omBAAomB","sourcesContent":[":host ::ng-deep {\r\n  .translation-toolbar-export-button,\r\n  .translation-toolbar-delete-language-button {\r\n    margin-right: 0px !important;\r\n  }\r\n}\r\n\r\n.translation-toolbar-height {\r\n  height: 48px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
