// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dlcs-container {
  margin-top: 5px;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
}

.dlcs-icon {
  width: 20px;
  height: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRldmljZS1sYXRlc3QtY29tbWFuZC1zdGF0dXMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsWUFBQTtFQUNBLGtCQUFBO0VBQ0EsZUFBQTtBQUNGOztBQUVBO0VBQ0UsV0FBQTtFQUNBLFlBQUE7QUFDRiIsImZpbGUiOiJkZXZpY2UtbGF0ZXN0LWNvbW1hbmQtc3RhdHVzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmRsY3MtY29udGFpbmVyIHtcclxuICBtYXJnaW4tdG9wOiA1cHg7XHJcbiAgcGFkZGluZzogOHB4O1xyXG4gIGJvcmRlci1yYWRpdXM6IDhweDtcclxuICBmb250LXNpemU6IDEycHg7XHJcbn1cclxuXHJcbi5kbGNzLWljb24ge1xyXG4gIHdpZHRoOiAyMHB4O1xyXG4gIGhlaWdodDogMjBweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/device-details/device-latest-command-status/device-latest-command-status.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;AACA,gnBAAgnB","sourcesContent":[".dlcs-container {\r\n  margin-top: 5px;\r\n  padding: 8px;\r\n  border-radius: 8px;\r\n  font-size: 12px;\r\n}\r\n\r\n.dlcs-icon {\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
