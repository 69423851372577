// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline__header {
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  transform: translateX(-50%);
  padding-bottom: 10px;
}
.timeline__header_label, .timeline__header_content {
  display: block;
  font-size: 12px;
  line-height: 26px;
  text-transform: uppercase;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpbWVsaW5lLWhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLHFCQUFBO0VBQ0Esc0JBQUE7RUFDQSxrQkFBQTtFQUNBLDJCQUFBO0VBQ0Esb0JBQUE7QUFBSjtBQUVJO0VBRUUsY0FBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtFQUNBLHlCQUFBO0FBRE4iLCJmaWxlIjoidGltZWxpbmUtaGVhZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRpbWVsaW5lIHtcclxuICAmX19oZWFkZXIge1xyXG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xyXG4gICAgdmVydGljYWwtYWxpZ246IGJvdHRvbTtcclxuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcclxuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgtNTAlKTtcclxuICAgIHBhZGRpbmctYm90dG9tOiAxMHB4O1xyXG5cclxuICAgICZfbGFiZWwsXHJcbiAgICAmX2NvbnRlbnQge1xyXG4gICAgICBkaXNwbGF5OiBibG9jaztcclxuICAgICAgZm9udC1zaXplOiAxMnB4O1xyXG4gICAgICBsaW5lLWhlaWdodDogMjZweDtcclxuICAgICAgdGV4dC10cmFuc2Zvcm06IHVwcGVyY2FzZTtcclxuICAgIH1cclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/timeline/timeline-header/timeline-header.component.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,sBAAA;EACA,kBAAA;EACA,2BAAA;EACA,oBAAA;AAAJ;AAEI;EAEE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AADN;AACA,g3BAAg3B","sourcesContent":[".timeline {\r\n  &__header {\r\n    display: inline-block;\r\n    vertical-align: bottom;\r\n    text-align: center;\r\n    transform: translateX(-50%);\r\n    padding-bottom: 10px;\r\n\r\n    &_label,\r\n    &_content {\r\n      display: block;\r\n      font-size: 12px;\r\n      line-height: 26px;\r\n      text-transform: uppercase;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
