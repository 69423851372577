import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveElement } from '../../features/navigations/models/active-element.model';
import { NavigationsFacade } from '../../features/navigations/state/facades/navigations.facade';

@Component({
    selector: 'oyan-ui-info-display',
    templateUrl: './info-display.component.html',
    styleUrls: ['./info-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InfoDisplayComponent {
  activeElement$: Observable<ActiveElement> = this.navigationsFacade.activeElement$;
  siteLoading$: Observable<boolean> = this.navigationsFacade.siteLoading$;

  constructor(private navigationsFacade: NavigationsFacade) {}

  onBack(): void {
    this.navigationsFacade.returnToOrigin();
  }
}
