// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-button {
  font-size: 14px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItcHJvZmlsZS1hcGkta2V5cy1pbmZvLXBvcHVwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBQTtBQUNGIiwiZmlsZSI6InVzZXItcHJvZmlsZS1hcGkta2V5cy1pbmZvLXBvcHVwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvcHktYnV0dG9uIHtcclxuICBmb250LXNpemU6IDE0cHg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/users/src/lib/features/profile/components/user-profile-api-keys-info-popup/user-profile-api-keys-info-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACA,wXAAwX","sourcesContent":[".copy-button {\r\n  font-size: 14px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
