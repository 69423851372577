// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  height: 38px;
}

.black {
  color: #394c5a;
}

.yellow {
  background-color: rgba(245, 195, 68, 0.4);
  color: #394c5a;
}

.orange {
  background-color: rgba(255, 140, 0, 0.4);
  color: #394c5a;
}

.red {
  background-color: rgba(178, 34, 34, 0.4);
  color: #394c5a;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImV2ZW50LWR1cmF0aW9uLWNlbGwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxZQUFBO0FBQ0Y7O0FBRUE7RUFDRSxjQUFBO0FBQ0Y7O0FBRUE7RUFDRSx5Q0FBQTtFQUNBLGNBQUE7QUFDRjs7QUFFQTtFQUNFLHdDQUFBO0VBQ0EsY0FBQTtBQUNGOztBQUVBO0VBQ0Usd0NBQUE7RUFDQSxjQUFBO0FBQ0YiLCJmaWxlIjoiZXZlbnQtZHVyYXRpb24tY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImRpdiB7XHJcbiAgaGVpZ2h0OiAzOHB4O1xyXG59XHJcblxyXG4uYmxhY2sge1xyXG4gIGNvbG9yOiAjMzk0YzVhO1xyXG59XHJcblxyXG4ueWVsbG93IHtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKCNmNWMzNDQsIDAuNCk7XHJcbiAgY29sb3I6ICMzOTRjNWE7XHJcbn1cclxuXHJcbi5vcmFuZ2Uge1xyXG4gIGJhY2tncm91bmQtY29sb3I6IHJnYmEoI2ZmOGMwMCwgMC40KTtcclxuICBjb2xvcjogIzM5NGM1YTtcclxufVxyXG5cclxuLnJlZCB7XHJcbiAgYmFja2dyb3VuZC1jb2xvcjogcmdiYSgjYjIyMjIyLCAwLjQpO1xyXG4gIGNvbG9yOiAjMzk0YzVhO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/cells/event-duration-cell/event-duration-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,yCAAA;EACA,cAAA;AACF;;AAEA;EACE,wCAAA;EACA,cAAA;AACF;;AAEA;EACE,wCAAA;EACA,cAAA;AACF;AACA,g3BAAg3B","sourcesContent":["div {\r\n  height: 38px;\r\n}\r\n\r\n.black {\r\n  color: #394c5a;\r\n}\r\n\r\n.yellow {\r\n  background-color: rgba(#f5c344, 0.4);\r\n  color: #394c5a;\r\n}\r\n\r\n.orange {\r\n  background-color: rgba(#ff8c00, 0.4);\r\n  color: #394c5a;\r\n}\r\n\r\n.red {\r\n  background-color: rgba(#b22222, 0.4);\r\n  color: #394c5a;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
