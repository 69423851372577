// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-list-item:hover .role-list-call-to-action,
.mat-mdc-list-item:hover .role-list-call-to-action {
  visibility: visible;
}

.role-list-call-to-action {
  visibility: hidden;
}

mat-list-item *[aria-expanded=true],
.mat-mdc-list-item *[aria-expanded=true] {
  visibility: visible;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJvbGVzLWxpc3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0k7O0VBQ0UsbUJBQUE7QUFETjs7QUFNQTtFQUNFLGtCQUFBO0FBSEY7O0FBUUU7O0VBQ0UsbUJBQUE7QUFKSiIsImZpbGUiOiJyb2xlcy1saXN0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsibWF0LWxpc3QtaXRlbSxcclxuLm1hdC1tZGMtbGlzdC1pdGVtIHtcclxuICAmOmhvdmVyIHtcclxuICAgIC5yb2xlLWxpc3QtY2FsbC10by1hY3Rpb24ge1xyXG4gICAgICB2aXNpYmlsaXR5OiB2aXNpYmxlO1xyXG4gICAgfVxyXG4gIH1cclxufVxyXG5cclxuLnJvbGUtbGlzdC1jYWxsLXRvLWFjdGlvbiB7XHJcbiAgdmlzaWJpbGl0eTogaGlkZGVuO1xyXG59XHJcblxyXG5tYXQtbGlzdC1pdGVtLFxyXG4ubWF0LW1kYy1saXN0LWl0ZW0ge1xyXG4gICpbYXJpYS1leHBhbmRlZD0ndHJ1ZSddIHtcclxuICAgIHZpc2liaWxpdHk6IHZpc2libGU7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot4bos/iot4bos-ui-backoffice/src/lib/components/roles-list/roles-list.component.scss"],"names":[],"mappings":"AAGI;;EACE,mBAAA;AADN;;AAMA;EACE,kBAAA;AAHF;;AAQE;;EACE,mBAAA;AAJJ;AACA,ovBAAovB","sourcesContent":["mat-list-item,\r\n.mat-mdc-list-item {\r\n  &:hover {\r\n    .role-list-call-to-action {\r\n      visibility: visible;\r\n    }\r\n  }\r\n}\r\n\r\n.role-list-call-to-action {\r\n  visibility: hidden;\r\n}\r\n\r\nmat-list-item,\r\n.mat-mdc-list-item {\r\n  *[aria-expanded='true'] {\r\n    visibility: visible;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
