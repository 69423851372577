// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .mat-mdc-form-field-flex {
  align-items: flex-end;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.login-change-temporary-password-form-suffix {
  cursor: pointer;
}
.login-change-temporary-password-form-suffix:active {
  animation: rotate 200ms ease 0ms 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvZ2luLWNoYW5nZS10ZW1wb3JhcnktcGFzc3dvcmQtZm9ybS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHFCQUFBO0FBQ0Y7O0FBRUE7RUFDRTtJQUNFLHVCQUFBO0VBQ0Y7RUFDQTtJQUNFLDBCQUFBO0VBQ0Y7QUFDRjtBQUVBO0VBQ0UsZUFBQTtBQUFGO0FBRUU7RUFDRSxrQ0FBQTtBQUFKIiwiZmlsZSI6ImxvZ2luLWNoYW5nZS10ZW1wb3JhcnktcGFzc3dvcmQtZm9ybS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0OjpuZy1kZWVwIC5tYXQtbWRjLWZvcm0tZmllbGQtZmxleCB7XHJcbiAgYWxpZ24taXRlbXM6IGZsZXgtZW5kO1xyXG59XHJcblxyXG5Aa2V5ZnJhbWVzIHJvdGF0ZSB7XHJcbiAgZnJvbSB7XHJcbiAgICB0cmFuc2Zvcm06IHJvdGF0ZSgwZGVnKTtcclxuICB9XHJcbiAgdG8ge1xyXG4gICAgdHJhbnNmb3JtOiByb3RhdGUoLTM2MGRlZyk7XHJcbiAgfVxyXG59XHJcblxyXG4ubG9naW4tY2hhbmdlLXRlbXBvcmFyeS1wYXNzd29yZC1mb3JtLXN1ZmZpeCB7XHJcbiAgY3Vyc29yOiBwb2ludGVyO1xyXG5cclxuICAmOmFjdGl2ZSB7XHJcbiAgICBhbmltYXRpb246IHJvdGF0ZSAyMDBtcyBlYXNlIDBtcyAxO1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/auth/src/lib/components/login-change-temporary-password-form/login-change-temporary-password-form.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,0BAAA;EACF;AACF;AAEA;EACE,eAAA;AAAF;AAEE;EACE,kCAAA;AAAJ;AACA,46BAA46B","sourcesContent":[":host::ng-deep .mat-mdc-form-field-flex {\r\n  align-items: flex-end;\r\n}\r\n\r\n@keyframes rotate {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n  to {\r\n    transform: rotate(-360deg);\r\n  }\r\n}\r\n\r\n.login-change-temporary-password-form-suffix {\r\n  cursor: pointer;\r\n\r\n  &:active {\r\n    animation: rotate 200ms ease 0ms 1;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
