/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { ContactsActions } from '@iot-platform/data-access/contacts';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Gateway, Receiver, Site, Ward } from '@iot-platform/models/oyan';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { transformApiRequest, transformApiResponse } from '../../../../helpers/apis-helpers';
import { GatewaysService } from '../../../gateways/services/gateways.service';
import { ProductsService } from '../../../products/services/products.service';
import { SitesService } from '../../services/sites.service';
import { WardsService } from '../../services/wards.service';
import { SiteActions, SiteGatewaysActions, SiteProductsActions, SiteWardsActions } from '../actions';

@Injectable()
export class SitesEffects {
  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.loadSites),
      switchMap(({ request }) =>
        this.sitesService.getAll(transformApiRequest(request)).pipe(
          map((response: CommonApiResponse<Site, CommonIndexedPagination>) => SiteActions.loadSitesSuccess({ response: transformApiResponse<Site>(response) })),
          catchError((error) => of(SiteActions.loadSitesFailure({ error })))
        )
      )
    )
  );

  loadSiteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.loadSiteById),
      switchMap((action) =>
        this.sitesService.getById(action.id).pipe(
          map((response: Site) => SiteActions.loadSiteByIdSuccess({ response })),
          catchError((error) => of(SiteActions.loadSiteByIdFailure({ error })))
        )
      )
    )
  );

  loadSiteByIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.loadSiteByIdSuccess),
      map(({ response }) => SiteActions.setActiveSite({ site: response }))
    )
  );

  setActiveSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.setActiveSite),
      map(({ site }) => ContactsActions.loadContactsSuccess({ contacts: GetUtils.get(site, 'contacts', []) }))
    )
  );

  addSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.addSite),
      concatMap((action) =>
        this.sitesService.addOne(action.site).pipe(
          map((response: Site) => SiteActions.addSiteSuccess({ response })),
          catchError((error) => of(SiteActions.addSiteFailure({ error })))
        )
      )
    )
  );

  updateSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.updateSite),
      concatMap((action) =>
        this.sitesService.updateOne(action.site).pipe(
          map((response: Site) => SiteActions.updateSiteSuccess({ response })),
          catchError((error) => of(SiteActions.updateSiteFailure({ error })))
        )
      )
    )
  );

  deleteSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.deleteSite),
      concatMap((action) =>
        this.sitesService.deleteOne(action.site).pipe(
          map((response) => SiteActions.deleteSiteSuccess({ response })),
          catchError((error) => of(SiteActions.deleteSiteFailure({ error })))
        )
      )
    )
  );

  deleteSiteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.deleteSiteSuccess),
        tap(() => {
          history.go(-1);
        })
      ),
    { dispatch: false }
  );

  loadGatewaysBySite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteGatewaysActions.loadGatewaysBySite),
      switchMap(({ request }) =>
        this.gatewaysService.getAll(transformApiRequest(request)).pipe(
          map((response: CommonApiResponse<Gateway, CommonIndexedPagination>) =>
            SiteGatewaysActions.loadGatewaysBySiteSuccess({ response: transformApiResponse<Gateway>(response) })
          ),
          catchError((error) => of(SiteGatewaysActions.loadGatewaysBySiteFailure({ error })))
        )
      )
    )
  );

  loadWardsBySite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteWardsActions.loadWardsBySite),
      switchMap(({ request }) =>
        this.wardsService.getAll(transformApiRequest(request)).pipe(
          map((response: CommonApiResponse<Ward, CommonIndexedPagination>) =>
            SiteWardsActions.loadWardsBySiteSuccess({ response: transformApiResponse<Ward>(response) })
          ),
          catchError((error) => of(SiteWardsActions.loadWardsBySiteFailure({ error })))
        )
      )
    )
  );

  addReceiverToWard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteWardsActions.addReceiverToWard),
      concatMap(({ receiver, ward, site }) =>
        this.wardsService.addReceiverToWard(receiver, ward, site).pipe(
          map((response: Receiver) => SiteWardsActions.addReceiverToWardSuccess({ ward, receiver: response })),
          catchError((error) => of(SiteWardsActions.addReceiverToWardFailure({ error })))
        )
      )
    )
  );

  removeReceiverFromWard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteWardsActions.removeReceiverFromWard),
      concatMap(({ receiver, ward, site }) =>
        this.wardsService.removeReceiverFromWard(receiver, ward, site).pipe(
          map(() => SiteWardsActions.removeReceiverFromWardSuccess({ ward, receiver })),
          catchError((error) => of(SiteWardsActions.removeReceiverFromWardFailure({ error })))
        )
      )
    )
  );

  addProductToSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteProductsActions.addProductToSite),
      concatMap(({ product, site }) =>
        this.productsService.addProductToSite(product, site).pipe(
          map(() => SiteProductsActions.addProductToSiteSuccess({ product, site })),
          catchError((error) => of(SiteProductsActions.addProductToSiteFailure({ error })))
        )
      )
    )
  );

  removeProductFromSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteProductsActions.removeProductFromSite),
      concatMap(({ product, site }) =>
        this.productsService.removeProductFromSite(product, site).pipe(
          map(() => SiteProductsActions.removeProductFromSiteSuccess({ product, site })),
          catchError((error) => of(SiteProductsActions.removeProductFromSiteFailure({ error })))
        )
      )
    )
  );

  addGatewayToSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteGatewaysActions.addGatewayToSite),
      concatMap(({ gateway, site }) =>
        this.gatewaysService.addGatewayToSite(gateway, site).pipe(
          map(() => SiteGatewaysActions.addGatewayToSiteSuccess({ gateway, site })),
          catchError((error) => of(SiteGatewaysActions.addGatewayToSiteFailure({ error })))
        )
      )
    )
  );

  removeGatewayFromSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteGatewaysActions.removeGatewayFromSite),
      concatMap(({ gateway, site }) =>
        this.gatewaysService.removeGatewayFromSite(gateway, site).pipe(
          map(() => SiteGatewaysActions.removeGatewayFromSiteSuccess({ gateway, site })),
          catchError((error) => of(SiteGatewaysActions.removeGatewayFromSiteFailure({ error })))
        )
      )
    )
  );

  synchronizeGatewaysBySite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteGatewaysActions.synchronizeGatewaysBySite),
      switchMap(({ site }) =>
        this.gatewaysService.synchronize().pipe(
          map((synchronized: boolean) => {
            if (synchronized) {
              return SiteGatewaysActions.synchronizeGatewaysBySiteSuccess({ site });
            } else {
              return SiteGatewaysActions.synchronizeGatewaysBySiteFailure({ error: new Error('Synchronize Gateways Failed') });
            }
          }),
          catchError((error) => of(SiteGatewaysActions.synchronizeGatewaysBySiteFailure({ error })))
        )
      )
    )
  );

  synchronizeGatewaysBySiteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteGatewaysActions.synchronizeGatewaysBySiteSuccess),
      map(({ site }) => {
        const request: CommonApiRequest = {
          filters: [
            { criteriaKey: 'siteId', value: site.id },
            { criteriaKey: 'entityId', value: site.entity.id }
          ]
        };
        return SiteGatewaysActions.loadGatewaysBySite({ request });
      })
    )
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SiteActions.loadSitesFailure,
          SiteActions.loadSiteByIdFailure,
          SiteActions.addSiteFailure,
          SiteActions.updateSiteFailure,
          SiteActions.deleteSiteFailure,
          SiteGatewaysActions.loadGatewaysBySiteFailure,
          SiteWardsActions.loadWardsBySiteFailure,
          SiteWardsActions.addReceiverToWardFailure,
          SiteWardsActions.removeReceiverFromWardFailure,
          SiteProductsActions.addProductToSiteFailure,
          SiteGatewaysActions.addGatewayToSiteFailure,
          SiteProductsActions.removeProductFromSiteFailure,
          SiteGatewaysActions.removeGatewayFromSiteFailure
        ),
        tap((action) => this.notificationService.displayError(action.type))
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SiteActions.addSiteSuccess,
          SiteActions.updateSiteSuccess,
          SiteActions.deleteSiteSuccess,
          SiteWardsActions.addReceiverToWardSuccess,
          SiteWardsActions.removeReceiverFromWardSuccess,
          SiteProductsActions.addProductToSiteSuccess,
          SiteProductsActions.removeProductFromSiteSuccess,
          SiteGatewaysActions.addGatewayToSiteSuccess,
          SiteGatewaysActions.removeGatewayFromSiteSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SiteActions.loadSites,
          SiteActions.loadSiteById,
          SiteActions.addSite,
          SiteActions.updateSite,
          SiteActions.deleteSite,
          SiteGatewaysActions.loadGatewaysBySite,
          SiteWardsActions.loadWardsBySite,
          SiteWardsActions.addReceiverToWard,
          SiteWardsActions.removeReceiverFromWard,
          SiteProductsActions.addProductToSite,
          SiteGatewaysActions.addGatewayToSite,
          SiteProductsActions.removeProductFromSite,
          SiteGatewaysActions.removeGatewayFromSite
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SiteActions.loadSitesSuccess,
          SiteActions.loadSiteByIdSuccess,
          SiteActions.addSiteSuccess,
          SiteActions.updateSiteSuccess,
          SiteActions.deleteSiteSuccess,
          SiteGatewaysActions.loadGatewaysBySiteSuccess,
          SiteWardsActions.loadWardsBySiteSuccess,
          SiteWardsActions.addReceiverToWardSuccess,
          SiteWardsActions.removeReceiverFromWardSuccess,
          SiteProductsActions.addProductToSiteSuccess,
          SiteGatewaysActions.addGatewayToSiteSuccess,
          SiteProductsActions.removeProductFromSiteSuccess,
          SiteGatewaysActions.removeGatewayFromSiteSuccess,
          SiteActions.loadSitesFailure,
          SiteActions.loadSiteByIdFailure,
          SiteActions.addSiteFailure,
          SiteActions.updateSiteFailure,
          SiteActions.deleteSiteFailure,
          SiteGatewaysActions.loadGatewaysBySiteFailure,
          SiteWardsActions.loadWardsBySiteFailure,
          SiteWardsActions.addReceiverToWardFailure,
          SiteWardsActions.removeReceiverFromWardFailure,
          SiteProductsActions.addProductToSiteFailure,
          SiteGatewaysActions.addGatewayToSiteFailure,
          SiteProductsActions.removeProductFromSiteFailure,
          SiteGatewaysActions.removeGatewayFromSiteFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly sitesService: SitesService,
    private readonly productsService: ProductsService,
    private readonly gatewaysService: GatewaysService,
    private readonly wardsService: WardsService
  ) {}
}
