// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.threshold-cell-value {
  display: block;
  padding: 2px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
  word-break: break-all;
  color: #394c5a;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFzc2V0LXZhcmlhYmxlLXRocmVzaG9sZC1jZWxsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtFQUNBLFlBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxvQ0FBQTtFQUNBLHFCQUFBO0VBQ0EsY0FBQTtBQUNGIiwiZmlsZSI6ImFzc2V0LXZhcmlhYmxlLXRocmVzaG9sZC1jZWxsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRocmVzaG9sZC1jZWxsLXZhbHVlIHtcclxuICBkaXNwbGF5OiBibG9jaztcclxuICBwYWRkaW5nOiAycHg7XHJcbiAgZm9udC1zaXplOiAxMnB4O1xyXG4gIGxpbmUtaGVpZ2h0OiAxNnB4O1xyXG4gIGZvbnQtZmFtaWx5OiAnT3BlbiBTYW5zJywgc2Fucy1zZXJpZjtcclxuICB3b3JkLWJyZWFrOiBicmVhay1hbGw7XHJcbiAgY29sb3I6ICMzOTRjNWE7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/asset-variable-threshold-cell/asset-variable-threshold-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,qBAAA;EACA,cAAA;AACF;AACA,gqBAAgqB","sourcesContent":[".threshold-cell-value {\r\n  display: block;\r\n  padding: 2px;\r\n  font-size: 12px;\r\n  line-height: 16px;\r\n  font-family: 'Open Sans', sans-serif;\r\n  word-break: break-all;\r\n  color: #394c5a;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
