// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .translation-preview .mat-mdc-card-content.dialog-card-content {
  overflow-y: auto;
  max-height: 60vh;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRyYW5zbGF0aW9uLXByZXZpZXctY2hhbmdlcy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGdCQUFBO0VBQ0EsZ0JBQUE7QUFBSiIsImZpbGUiOiJ0cmFuc2xhdGlvbi1wcmV2aWV3LWNoYW5nZXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCA6Om5nLWRlZXAgLnRyYW5zbGF0aW9uLXByZXZpZXcge1xyXG4gIC5tYXQtbWRjLWNhcmQtY29udGVudC5kaWFsb2ctY2FyZC1jb250ZW50IHtcclxuICAgIG92ZXJmbG93LXk6IGF1dG87XHJcbiAgICBtYXgtaGVpZ2h0OiA2MHZoO1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/i18n/src/lib/features/translation-management/components/translation-preview-changes/translation-preview-changes.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,gBAAA;AAAJ;AACA,4gBAA4gB","sourcesContent":[":host ::ng-deep .translation-preview {\r\n  .mat-mdc-card-content.dialog-card-content {\r\n    overflow-y: auto;\r\n    max-height: 60vh;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
