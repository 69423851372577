// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-platform-ui-infos-display-card-property {
  flex-grow: 0 !important;
  margin: 3px 0;
}
.iot-platform-ui-infos-display-card-property__label {
  min-width: 3rem;
}
.iot-platform-ui-infos-display-card-property__value {
  border-radius: 4px;
  padding: 4px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZm9zLWRpc3BsYXktY2FyZC1wcm9wZXJ0eS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHVCQUFBO0VBQ0EsYUFBQTtBQUNGO0FBQ0U7RUFDRSxlQUFBO0FBQ0o7QUFFRTtFQUNFLGtCQUFBO0VBQ0EsWUFBQTtBQUFKIiwiZmlsZSI6ImluZm9zLWRpc3BsYXktY2FyZC1wcm9wZXJ0eS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5pb3QtcGxhdGZvcm0tdWktaW5mb3MtZGlzcGxheS1jYXJkLXByb3BlcnR5IHtcclxuICBmbGV4LWdyb3c6IDAgIWltcG9ydGFudDtcclxuICBtYXJnaW46IDNweCAwO1xyXG5cclxuICAmX19sYWJlbCB7XHJcbiAgICBtaW4td2lkdGg6IDNyZW07XHJcbiAgfVxyXG5cclxuICAmX192YWx1ZSB7XHJcbiAgICBib3JkZXItcmFkaXVzOiA0cHg7XHJcbiAgICBwYWRkaW5nOiA0cHg7XHJcbiAgfVxyXG59Il19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/cards/infos-display-card/infos-display-card-property/infos-display-card-property.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,kBAAA;EACA,YAAA;AAAJ;AACA,grBAAgrB","sourcesContent":[".iot-platform-ui-infos-display-card-property {\r\n  flex-grow: 0 !important;\r\n  margin: 3px 0;\r\n\r\n  &__label {\r\n    min-width: 3rem;\r\n  }\r\n\r\n  &__value {\r\n    border-radius: 4px;\r\n    padding: 4px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
