// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*[aria-expanded=true] {
  visibility: visible;
}

.mat-mdc-icon-button {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px;
}

.mat-icon {
  font-size: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbGwtdG8tYWN0aW9uLWNlbGwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxtQkFBQTtBQUNGOztBQUVBO0VBQ0Usc0JBQUE7RUFDQSx1QkFBQTtFQUNBLGlCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxlQUFBO0FBQ0YiLCJmaWxlIjoiY2FsbC10by1hY3Rpb24tY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIipbYXJpYS1leHBhbmRlZD0ndHJ1ZSddIHtcclxuICB2aXNpYmlsaXR5OiB2aXNpYmxlO1xyXG59XHJcblxyXG4ubWF0LW1kYy1pY29uLWJ1dHRvbiB7XHJcbiAgd2lkdGg6IDMwcHggIWltcG9ydGFudDtcclxuICBoZWlnaHQ6IDMwcHggIWltcG9ydGFudDtcclxuICBsaW5lLWhlaWdodDogMzBweDtcclxufVxyXG5cclxuLm1hdC1pY29uIHtcclxuICBmb250LXNpemU6IDIwcHg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/table-engine/src/lib/components/table/cells/call-to-action-cell/call-to-action-cell.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,sBAAA;EACA,uBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;AACF;AACA,4pBAA4pB","sourcesContent":["*[aria-expanded='true'] {\r\n  visibility: visible;\r\n}\r\n\r\n.mat-mdc-icon-button {\r\n  width: 30px !important;\r\n  height: 30px !important;\r\n  line-height: 30px;\r\n}\r\n\r\n.mat-icon {\r\n  font-size: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
