import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';

import { SharedModule } from '@iot-platform/shared';
import { MasterViewEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ProductsCatalogComponent } from './containers/products-catalog/products-catalog.component';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsEffects } from './state/effects/products.effects';
import * as fromProducts from './state/reducers';

@NgModule({
  declarations: [ProductsCatalogComponent],
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformUiModule,
    MasterViewEngineModule,
    ProductsRoutingModule,
    StoreModule.forFeature(fromProducts.featureKey, fromProducts.reducers),
    EffectsModule.forFeature([ProductsEffects])
  ]
})
export class ProductsModule {}
