import { Component, input } from '@angular/core';
import { BaseCardComponent } from '@iot-platform/iot-platform-ui';
import { CardAction, CardEventType } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';

@Component({
    selector: 'oyan-ui-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    standalone: false
})
export class ProductCardComponent extends BaseCardComponent<Product> {
  actions = input<CardAction[]>([
    {
      label: 'SITES.CARD.DELETE',
      enabled: true,
      eventType: CardEventType.REMOVE_PRODUCT
    }
  ]);

  onDispatchEvent(action: CardAction): void {
    this.dispatchEvent.emit({
      eventType: action.eventType,
      data: this.element()
    });
  }
}
