import { Component, input } from '@angular/core';
import { BaseCardComponent } from '@iot-platform/iot-platform-ui';
import { CardAction, CardEventType } from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';

@Component({
    selector: 'oyan-ui-gateway-card',
    templateUrl: './gateway-card.component.html',
    styleUrls: ['./gateway-card.component.scss'],
    standalone: false
})
export class GatewayCardComponent extends BaseCardComponent<Gateway> {
  actions = input<CardAction[]>([
    {
      label: 'SITES.CARD.DELETE',
      enabled: true,
      eventType: CardEventType.REMOVE_GATEWAY
    }
  ]);

  onDispatchEvent(action: CardAction): void {
    this.dispatchEvent.emit({
      eventType: action.eventType,
      data: this.element()
    });
  }
}
