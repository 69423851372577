// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-row-group-inner-renderer-cell__group {
  display: inline;
  background: var(--ag-chip-background-color);
  border-radius: calc(var(--ag-grid-size) * 4);
  padding: 4px calc(var(--ag-grid-size) * 1.5);
  border: 1px solid var(--ag-chip-border-color);
}
.ag-row-group-inner-renderer-cell__group_leaf {
  display: flex !important;
}
.ag-row-group-inner-renderer-cell__link:hover {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJvdy1ncm91cC1pbm5lci1yZW5kZXJlci1jZWxsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsZUFBQTtFQUNBLDJDQUFBO0VBQ0EsNENBQUE7RUFDQSw0Q0FBQTtFQUNBLDZDQUFBO0FBQUo7QUFDSTtFQUNFLHdCQUFBO0FBQ047QUFHSTtFQUNFLGVBQUE7QUFETiIsImZpbGUiOiJyb3ctZ3JvdXAtaW5uZXItcmVuZGVyZXItY2VsbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5hZy1yb3ctZ3JvdXAtaW5uZXItcmVuZGVyZXItY2VsbCB7XHJcbiAgJl9fZ3JvdXAge1xyXG4gICAgZGlzcGxheTogaW5saW5lO1xyXG4gICAgYmFja2dyb3VuZDogdmFyKC0tYWctY2hpcC1iYWNrZ3JvdW5kLWNvbG9yKTtcclxuICAgIGJvcmRlci1yYWRpdXM6IGNhbGModmFyKC0tYWctZ3JpZC1zaXplKSAqIDQpO1xyXG4gICAgcGFkZGluZzogNHB4IGNhbGModmFyKC0tYWctZ3JpZC1zaXplKSAqIDEuNSk7XHJcbiAgICBib3JkZXI6IDFweCBzb2xpZCB2YXIoLS1hZy1jaGlwLWJvcmRlci1jb2xvcik7XHJcbiAgICAmX2xlYWYge1xyXG4gICAgICBkaXNwbGF5OiBmbGV4ICFpbXBvcnRhbnQ7XHJcbiAgICB9XHJcbiAgfVxyXG4gICZfX2xpbmsge1xyXG4gICAgJjpob3ZlciB7XHJcbiAgICAgIGN1cnNvcjogcG9pbnRlcjtcclxuICAgIH1cclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/grid-engine/src/lib/components/row-group-cells/row-group-inner-renderer-cell/row-group-inner-renderer-cell.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,2CAAA;EACA,4CAAA;EACA,4CAAA;EACA,6CAAA;AAAJ;AACI;EACE,wBAAA;AACN;AAGI;EACE,eAAA;AADN;AACA,4+BAA4+B","sourcesContent":[".ag-row-group-inner-renderer-cell {\r\n  &__group {\r\n    display: inline;\r\n    background: var(--ag-chip-background-color);\r\n    border-radius: calc(var(--ag-grid-size) * 4);\r\n    padding: 4px calc(var(--ag-grid-size) * 1.5);\r\n    border: 1px solid var(--ag-chip-border-color);\r\n    &_leaf {\r\n      display: flex !important;\r\n    }\r\n  }\r\n  &__link {\r\n    &:hover {\r\n      cursor: pointer;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
