// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-platform-ui-section-header__title {
  font-size: 14px;
  font-weight: 700;
}
.iot-platform-ui-section-header__title:first-letter {
  text-transform: capitalize;
}
.iot-platform-ui-section-header__title--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.iot-platform-ui-section-header__action {
  width: 30px;
  height: 30px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlY3Rpb24taGVhZGVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsZUFBQTtFQUNBLGdCQUFBO0FBQUo7QUFFSTtFQUNFLDBCQUFBO0FBQU47QUFHSTtFQUNFLG1CQUFBO0VBQ0EsZ0JBQUE7RUFDQSx1QkFBQTtBQUROO0FBS0U7RUFDRSxXQUFBO0VBQ0EsWUFBQTtBQUhKIiwiZmlsZSI6InNlY3Rpb24taGVhZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmlvdC1wbGF0Zm9ybS11aS1zZWN0aW9uLWhlYWRlciB7XHJcbiAgJl9fdGl0bGUge1xyXG4gICAgZm9udC1zaXplOiAxNHB4O1xyXG4gICAgZm9udC13ZWlnaHQ6IDcwMDtcclxuXHJcbiAgICAmOmZpcnN0LWxldHRlciB7XHJcbiAgICAgIHRleHQtdHJhbnNmb3JtOiBjYXBpdGFsaXplO1xyXG4gICAgfVxyXG5cclxuICAgICYtLWVsbGlwc2lzIHtcclxuICAgICAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcclxuICAgICAgb3ZlcmZsb3c6IGhpZGRlbjtcclxuICAgICAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7XHJcbiAgICB9XHJcbiAgfVxyXG5cclxuICAmX19hY3Rpb24ge1xyXG4gICAgd2lkdGg6IDMwcHg7XHJcbiAgICBoZWlnaHQ6IDMwcHg7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/section-header/section-header.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;AAAJ;AAEI;EACE,0BAAA;AAAN;AAGI;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AADN;AAKE;EACE,WAAA;EACA,YAAA;AAHJ;AACA,w5BAAw5B","sourcesContent":[".iot-platform-ui-section-header {\r\n  &__title {\r\n    font-size: 14px;\r\n    font-weight: 700;\r\n\r\n    &:first-letter {\r\n      text-transform: capitalize;\r\n    }\r\n\r\n    &--ellipsis {\r\n      white-space: nowrap;\r\n      overflow: hidden;\r\n      text-overflow: ellipsis;\r\n    }\r\n  }\r\n\r\n  &__action {\r\n    width: 30px;\r\n    height: 30px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
