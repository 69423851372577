// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (max-width: 959px) {
  .input-field {
    min-width: auto;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LWZpZWxkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBREY7SUFFSSxlQUFBO0VBQ0Y7QUFDRiIsImZpbGUiOiJpbnB1dC1maWVsZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5pbnB1dC1maWVsZCB7XHJcbiAgQG1lZGlhIGFsbCBhbmQgKG1heC13aWR0aDogOTU5cHgpIHtcclxuICAgIG1pbi13aWR0aDogYXV0bztcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/iot-platform/iot-platform-ui/src/lib/components/filter-engine/fields/input-field/input-field.component.scss"],"names":[],"mappings":"AACE;EADF;IAEI,eAAA;EACF;AACF;AACA,gZAAgZ","sourcesContent":[".input-field {\r\n  @media all and (max-width: 959px) {\r\n    min-width: auto;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
