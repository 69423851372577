import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { AbstractMasterViewComponent } from '@iot-platform/feature/master-view';
import {
  CLEAR_FILTERS_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import {
  CommonApiRequest,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  PlatformResponse
} from '@iot-platform/models/common';
import { Receiver, Ward } from '@iot-platform/models/oyan';
import { OyanUiRoutingConstants } from '../../../../oyan-ui.router.constants';
import { NavigationsFacade } from '../../../navigations/state/facades/navigations.facade';
import { ReceiversFacade } from '../../state/facades/receivers.facade';

/**
 * Business rules

 * The inactive items will be displayed first on the page
 * Status “Inactive” will be displayed in RED.
 */
@Component({
    selector: 'oyan-ui-receivers-shell',
    templateUrl: './receivers-shell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReceiversShellComponent extends AbstractMasterViewComponent<Receiver> {
  protected authFacade: AuthFacade = inject(AuthFacade);
  protected facade: ReceiversFacade = inject(ReceiversFacade);
  protected navigationsFacade: NavigationsFacade = inject(NavigationsFacade);

  constructor() {
    super();
    this.toolbarButtonList = signal([
      new IotToolbarDefaultButton(
        {
          ...CLEAR_FILTERS_BUTTON_CONFIG,
          tooltip: 'MV_TOOLBAR.TOOLTIP.CLEAR_APPLIED_FILTERS'
        },
        1
      ),
      new IotToolbarDefaultButton(
        {
          ...TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
          tooltip: 'MV_TOOLBAR.TOOLTIP.SHOW_FILTER_ENGINE'
        },
        2
      ),
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.REFRESH_MV' }, 3)
    ]);
  }

  reLoadMasterView(page: number = this.pagination().currentPage): void {
    const filters: Filter[] = this.currentFilters();
    if (!filters.find((f) => f.criteriaKey === 'entityId' && f.value === this.businessProfile()?.entityId)) {
      // This filter should be excluded to prevent displayed in the filter engine
      filters.push({ criteriaKey: 'entityId', value: this.businessProfile().entityId, isHidden: true });
    }
    const request: CommonApiRequest = {
      page,
      limit: this.pagination().limit,
      filters
    };
    this.facade.getAll(request);
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'onSiteClickEvent':
        this.navigationsFacade.goToSiteOverview(event.rawData.site, OyanUiRoutingConstants.RECEIVERS);
        break;
      case 'onLastMessageClickEvent':
        this.navigationsFacade.goToCommunicationViewFromReceivers(event.rawData as Receiver);
        break;
      case 'onAssetsClickEvent': {
        this.navigationsFacade.goToAssetsFromReceivers(event.rawData.ward as Ward);
        break;
      }
      default:
        break;
    }
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    super.onToolbarEvent(event);
    if (event.type === IotToolbarDispatchActionType.FILTER_TEXT_CHANGE.toString()) {
      this.onFilterTextChange(event.options);
    }
  }

  onKerlinkClick(): void {
    this.navigationsFacade.openKerlink();
  }

  sortData(platformResponse: PlatformResponse): PlatformResponse {
    return {
      ...platformResponse,
      data: platformResponse.data.sort(SortUtil.sortByProperty('connected'))
    };
  }

  onFilterTextChange(searchString: string): void {
    const receivers = this.facade.all();
    let filteredList: Receiver[] = receivers ? [...receivers] : [];
    if (searchString !== null) {
      filteredList = filteredList.filter(
        (receiver: Receiver) =>
          `${receiver.devEui}`.toLowerCase().includes(searchString.toLowerCase()) ||
          (receiver.site &&
            (`${receiver.site.displayName}`.toLowerCase().includes(searchString.toLowerCase()) ||
              `${receiver.site.name}`.toLowerCase().includes(searchString.toLowerCase())))
      );
    }
    this.platformResponse.update((platformResponse) =>
      this.sortData({
        ...platformResponse,
        data: filteredList
      })
    );
    this.totalItems.set(filteredList.length);
  }
}
