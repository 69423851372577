import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {
  LoginChangeTemporaryPasswordFormComponent,
  LoginFormComponent,
  LoginResetPasswordFormComponent,
  LoginSelectProfileComponent,
  LoginSendCodeFormComponent,
  LoginShellComponent
} from '@iot-platform/auth';
import { BusinessProfile } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [
        FlexLayoutModule,
        TranslateModule,
        MatIconModule,
        NgClass,
        MatCardModule,
        LoginFormComponent,
        LoginChangeTemporaryPasswordFormComponent,
        LoginSendCodeFormComponent,
        LoginResetPasswordFormComponent,
        LoginSelectProfileComponent
    ],
    selector: 'oyan-ui-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends LoginShellComponent {
  authPending: Signal<boolean> = this.authFacade.authPending;
  eligibleBusinessProfiles: Signal<BusinessProfile[]> = this.authFacade.eligibleBusinessProfiles;
}
